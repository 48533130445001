import React from 'react'
import useTimedBoolean from '../hooks/useTimedBoolean'

const NothingHere = () => {
  const showTravolta = useTimedBoolean({ startDelay: 1000, duration: -1, numberOfOccurences: -1 })
  return (
    <div>
      <h1>Nothing here...</h1>
      {showTravolta && Math.floor(Math.random() * 8) === 7 && (
        <img src={process.env.PUBLIC_URL + '/travolta.gif'} alt="travolta" />
      )}
    </div>
  )
}

export default NothingHere
