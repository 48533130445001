import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Form, Button, Loader } from 'semantic-ui-react'

import { Label } from '../sharedComponents'
import { FormInlineError } from '../FormInlineError'
import { UploadContainer } from './styledComponents'
import { useAssetUpload } from './useAssetUpload'
import { AssetRepresentation } from './AssetRepresentation'
import { fileTooLargeString } from './helpers'

const mimeTypesAssets = ['image/svg+xml', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif']
export const maxAssetSize = 10000000 // 10mib

const AssetUploader = props => {
  const {
    maxSize = maxAssetSize,
    name,
    endpoint,
    validation = {},
    label,
    register,
    unregister,
    errors,
    setValue,
    setError,
    clearError,
    getDefault,
    asText,
    description,
    componentIndex,
    componentName,
    originName,
  } = props
  const { required } = validation
  const defaultValue = getDefault(name)
  const { assetUrl, loading, error, uploadAsset, onDelete } = useAssetUpload(endpoint, defaultValue || '', validation.asset)

  // Register the uploader to react-hook-form
  useEffect(() => {
    register({ name }, { ...validation })
    if (defaultValue) {
      setValue(name, defaultValue)
    }

    // Unregister from react-hook-form when the uploader is unmounted.
    // This also clears its validation requirements.
    return () => {
      unregister(name)
    }
  }, [])

  // Update the error message when the uploader throws an error
  useEffect(() => {
    if (error) {
      setError(name, error)
    } else {
      clearError(name)
    }
  }, [error])

  /* Validating the uploader component. It is valid, if:
   * - A file has been uploaded and thus the assetUrl is !== ""
   * - The upload is not required
   */
  useEffect(() => {
    if (assetUrl !== '' || !required) {
      clearError(name)
      setValue(name, assetUrl)
    } else {
      setValue(name, null)
    }
  }, [assetUrl])

  // This function automatically gets a list of accepted and rejected files
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      // Checking if the file is too large else the filetype is wrong
      if (file.size > maxSize) {
        setError(name, fileTooLargeString(file.size, maxSize))
      } else {
        setError(name, 'This file type is not allowed.')
      }
      return
    }

    const file = acceptedFiles[0]
    uploadAsset(file)
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: mimeTypesAssets,
    noClick: true,
    maxSize,
  })

  if (loading) {
    return (
      <UploadContainer>
        <Loader active inline="centered" />
        <div style={{ textAlign: 'center' }}>Uploading...</div>
      </UploadContainer>
    )
  }

  return (
    <Form.Field error={!!errors[name]} id={`${componentIndex}-${componentName}-${originName}-asset`}>
      <Label required={required} text={label} description={description} />
      {!asText && (
        <UploadContainer
          className="upload-container"
          {...getRootProps({ noClick: true })}
          style={isDragActive ? { border: '3px solid #c3eae4' } : {}}
        >
          {!errors[name] && <AssetRepresentation assetUrl={assetUrl} onDelete={onDelete} />}
          {(!assetUrl || errors[name]) && (
            <React.Fragment>
              <input {...getInputProps({ multiple: false })} />
              <div style={{ float: 'right' }}>
                <Button onClick={open}>Select a file</Button>
              </div>
              <div style={{ paddingTop: '5px', fontWeight: 'bold' }}>
                Drag drop a file here
                <br />
                (svg, png, jpg, jpeg, gif)
              </div>
            </React.Fragment>
          )}
        </UploadContainer>
      )}

      {asText && !errors[name] && <AssetRepresentation assetUrl={assetUrl} asText={true} />}
      {!asText && errors[name] && (
        <FormInlineError>
          {errors[name].type.split('\n').map((item, key) => {
            return (
              <React.Fragment key={key}>
                - {item}
                <br />
              </React.Fragment>
            )
          })}
        </FormInlineError>
      )}
    </Form.Field>
  )
}

export default AssetUploader
