import React from 'react'
import styled from 'styled-components'
import { Button, Popup } from 'semantic-ui-react'
import useTimedBoolean from '../../../hooks/useTimedBoolean'

const ButtonWrapper = styled.div`
  position: absolute;
  top: 3.5rem;
  right: 1.3rem;
  button.basic.ui.button {
    box-shadow: initial;
    border: 0;
  }
`

export default function BreakpointToggleButton({ isMobile, toggleIsMobile }) {
  const showHint = useTimedBoolean({ id: 'breakpointToggle', numberOfOccurences: 7 })
  return (
    <ButtonWrapper>
      <Popup
        position="bottom right"
        open={showHint}
        trigger={
          <Button basic size="massive" icon={isMobile ? 'mobile alternate' : 'desktop'} onClick={toggleIsMobile} />
        }
        content="Click here to toggle between desktop and mobile preview of the page."
        inverted
      />
    </ButtonWrapper>
  )
}
