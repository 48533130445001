import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Form, Input } from 'semantic-ui-react'
import Layout from '../../Layout'
import { usePost } from '../../../hooks/restHooks'

import HeaderHr from '../../../components/semantic/HeaderHr'

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .hidden {
    visibility: hidden;
  }
`

export default function Undelete(props) {
  const { trigger: update } = usePost('briefing/undelete')
  const [briefingId, setbriefingId] = useState('')

  const handleBriefingId = event => {
    setbriefingId(event.target.value)
  }

  const undeleteBriefing = async () => {
    const res = await update({
      briefingId,
    })

    if (res.ok === true) {
      alert('Briefing is now restored')
    }
    console.log(res)
  }

  return (
    <Layout showHr={false} style={{ paddingBottom: '1rem' }}>
      <Container>
        <HeaderHr style={{ marginBottom: '1rem', marginTop: '1rem' }} title={props.title} icon={'recycle'} />
        <Form>
          <Input value={briefingId} onChange={handleBriefingId} placeholder="Enter briefing ID" />
          <Button style={{ marginLeft: '1rem' }} primary onClick={undeleteBriefing}>
            Undelete Briefing
          </Button>
        </Form>
      </Container>
    </Layout>
  )
}
