import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import { FormInlineError } from './FormInlineError'
import 'react-datepicker/dist/react-datepicker.css'
import { Label } from './sharedComponents'

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
`

const addDays = (date, days) => {
  date.setDate(date.getDate() + days)
  return date
}

export default function DatePicker({
  name,
  validation = {},
  errorMessage,
  label,
  initialValue = addDays(new Date(), 1),
  register,
  unregister,
  errors,
  setValue,
  clearError,
  getDefault,
  asText,
  description,
  componentIndex = 0,
  componentName = 'form',
  originName = name,
  showTimeSelect = false,
  dateFormat = 'dd.MM.yyyy',
  disabled = false,
}) {
  const { required } = validation
  // Getting edit-mode default values from hook-form lib,
  // falling back to initialValue for create-mode if not existant
  let defaultValue
  if (getDefault(name)) {
    defaultValue = new Date(getDefault(name))
  } else {
    defaultValue = initialValue
  }

  const [value, setInternalValue] = useState(defaultValue)

  const onDateChanged = date => {
    clearError(name)
    setInternalValue(date)
    setValue(name, date)
  }

  useEffect(() => {
    register({ name }, { ...validation })
    onDateChanged(defaultValue)

    return () => {
      unregister(name)
    }
  }, [])

  return (
    <Form.Field error={!!errors[name]} id={`${componentIndex}-${componentName}-${originName}-datepicker`}>
      <Label required={required} text={label} description={description} />
      {asText && <p>{new Date(defaultValue).toDateString()}</p>}
      {!asText && (
        <DatePickerWrapper>
          <ReactDatePicker
            showTimeSelect={showTimeSelect}
            minDate={addDays(new Date(), 0)}
            dateFormat={dateFormat}
            selected={value}
            onChange={onDateChanged}
            style={{ width: '100%' }}
            disabled={disabled}
          />
        </DatePickerWrapper>
      )}

      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}
