import React from 'react'
import { Table } from 'semantic-ui-react'
import PublishToHybris from '../../../components/PublishToHybris'

export default function PublishItem(props) {
  const { element } = props

  return (
    <Table.Row key={element.id}>
      <Table.Cell style={{ textAlign: 'right' }}>{element.identifier}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>{element.country}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>{element.title}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>{element.type}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>{element.status}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>
        <PublishToHybris briefing={element} />
      </Table.Cell>
    </Table.Row>
  )
}
