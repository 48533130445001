import React from 'react'
import { isBaas15Active } from '../helpers/baasHelper'
import { deactivatedComponents } from '../Screens/Briefings/Overview/helpers'

function ContentModule({ mobile, assets, small, isDragging, name }) {
  const assetS = isBaas15Active ? assets.S.replace('S.', 'S15.') : assets.S
  const assetL = isBaas15Active ? assets.L.replace('L.', 'L15.') : assets.L
  let imgStyle = { maxWidth: '978px' }
  if (small) {
    imgStyle = { width: '100%' }
  }
  if (mobile) {
    imgStyle = { ...imgStyle, maxWidth: '320px' }
  }
  if (isDragging && !small) {
    imgStyle = { ...imgStyle, width: '500px' }
  }

  // pop1new special: Deactivate in selection, but still be editable
  const pop1NewComponentName = 'pop1new'
  if (isBaas15Active) {
    deactivatedComponents.push(pop1NewComponentName)
  }
  const deactivatedAndInBaas15 = deactivatedComponents.includes(name)
  const isPop1new = name === pop1NewComponentName
  if (deactivatedAndInBaas15 && !isPop1new) {
    imgStyle = { ...imgStyle, opacity: '0.45' }
  }

  return (
    <div className="contentModule" style={{ position: 'relative' }}>
      {deactivatedAndInBaas15 && !isPop1new && (
        <b style={{ display: 'block', color: 'red', position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
          not available in BaaS 1.5
        </b>
      )}
      {mobile && (
        <img
          style={imgStyle}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = `${process.env.REACT_APP_BACKEND_URL}public/${assets.S}`
          }}
          src={`${process.env.REACT_APP_BACKEND_URL}public/${assetS}`}
          alt="s-view"
        />
      )}
      {!mobile && (
        <img
          style={imgStyle}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = `${process.env.REACT_APP_BACKEND_URL}public/${assets.L}`
          }}
          src={`${process.env.REACT_APP_BACKEND_URL}public/${assetL}`}
          alt="l-view"
        />
      )}
    </div>
  )
}

export default ContentModule
