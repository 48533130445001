import React, { useEffect, useState } from 'react'
import { Loader, Dimmer, Message } from 'semantic-ui-react'
import { isNewShop } from '@douglas/baas-shared'
import { useGet } from '../../../hooks/restHooks'
import { useBriefing } from '../../../hooks/useBriefing'
import { getWrapperStyle, appendScriptTag, loadScripts, getCPGEnvironment, CssPreviewFixes } from './environments/environments'
import { NewshopSVGs } from './environments/NewshopSVGs'

const proxyPath = process.env.REACT_APP_BACKEND_URL + 'proxy'

const FrameContent = props => {
  const { briefingId, renderIncomplete, qa = false } = props
  const [briefing, briefingLoading] = useBriefing(briefingId)
  const [pageRenderLoader, setPageRenderLoading] = useState(true)
  let renderIncompleteParam = ''
  let briefingApi = qa ? 'qa' : 'briefing'
  if (renderIncomplete) {
    renderIncompleteParam = '?renderIncomplete=true'
  }
  const { data, refetch: trigger } = useGet(`${briefingApi}/${briefingId}/code${renderIncompleteParam}`, false)

  window.onPageRendered = () => {
    setTimeout(() => {
      setPageRenderLoading(false)
    }, 1000)
  }

  useEffect(() => {
    if (briefing && (renderIncomplete || briefing.validatedSuccessfully)) {
      loadScripts(briefing.type)
      trigger()
    }
  }, [briefing])

  const replaceSrcSet = html => {
    // eslint-disable-next-line no-useless-escape
    const pattern = /<img[^\>]*[^\>\S]+srcset=['"]((?:[^"'\s,]+\s*(?:\s+\d+[wx])(?:,\s*)?)+)["']/g

    const result = preg_match_all(pattern, html)
    if (result[1]) {
      result[1].forEach(element => {
        let srcset = element.replaceAll('http:', '/api/proxy/http:')
        html = html.replace(element, srcset)
      })
    }
    return html
  }

  function preg_match_all(regex, str) {
    return [...str.matchAll(new RegExp(regex, 'g'))].reduce((acc, group) => {
      group
        .filter(element => typeof element === 'string')
        .forEach((element, i) => {
          if (!acc[i]) acc[i] = []
          acc[i].push(element)
        })
      return acc
    }, [])
  }

  useEffect(() => {
    if (!data || !briefing) return
    let jsContent = data.jsContent
    if (!jsContent) {
      // Split HTML and script into two different variables for old contentpages
      const findScriptTag = new RegExp(/<script>([\d\D]*)<\/script>/gi)
      const match = findScriptTag.exec(data.htmlContent)
      if (match) {
        data.htmlContent = data.htmlContent.replace(findScriptTag, '')
        jsContent = match[1]
      }
    }
    if (!jsContent) return
    appendScriptTag(getCPGEnvironment(briefing.country, briefing.language || ''))
    appendScriptTag(jsContent, 500)
    appendScriptTag('$(document).ready(window.onPageRendered)', 700)
  }, [data, briefing])

  if (!briefingLoading && !briefing.validatedSuccessfully && !renderIncomplete) {
    return <h1>This briefing is not validated, yet. Please finish it before triggering a preview.</h1>
  }

  let proxiedHTML =
    data &&
    data.htmlContent
      .replace(/(<img\s.*?src=")([^"]+)"/g, `$1${proxyPath}/$2"`)
      .replaceAll('background-image: url(http:', 'background-image: url(/api/proxy/http:')

  // add proxy for srcset urls
  proxiedHTML = proxiedHTML && replaceSrcSet(proxiedHTML)
  return (
    <>
      {pageRenderLoader && (
        <Dimmer active>
          <Loader size="big">Loading</Loader>
        </Dimmer>
      )}
      {data && !data.htmlContent && (
        <Message negative>
          <Message.Header>Can not load preview</Message.Header>
          <p>The build of this page was not successful. Please talk to Webdev.</p>
        </Message>
      )}

      {!props.hideMetaTags && <meta name="viewport" content="width=device-width, initial-scale=1.0" />}
      <link rel="stylesheet" type="text/css" href={`${process.env.PUBLIC_URL}/previewenv/style-patternlab.css`} />

      {data && briefing && data.htmlContent && (
        <CssPreviewFixes pagetype={briefing.type}>
          <div style={getWrapperStyle(briefing.type, briefing.navigation)}>
            <div dangerouslySetInnerHTML={{ __html: proxiedHTML }} />
          </div>
        </CssPreviewFixes>
      )}

      {briefing && isNewShop(briefing.country) && <NewshopSVGs />}
    </>
  )
}

export default FrameContent
