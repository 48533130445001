import React, { createContext, useState, useEffect, useContext } from 'react'
import { Dimmer, Icon, Header } from 'semantic-ui-react'
import { Error } from '../components/Error'

export const ErrorOverlayContext = createContext()

export default function ErrorOverlayContextProvider({ children }) {
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!window.hooks) {
      window.hooks = {}
    }
    window.hooks.setError = setError
  }, [setError])

  const close = () => setError(null)

  return (
    <ErrorOverlayContext.Provider value={{ error, setError, close }}>
      <Dimmer active={!!error} onClickOutside={close} page>
        <Header as="h2" icon inverted>
          <Icon name="warning sign" color="red" />
        </Header>
        <Error code={error} close={close} />
      </Dimmer>
      {children}
    </ErrorOverlayContext.Provider>
  )
}

export const useErrorOverlayContext = () => {
  const context = useContext(ErrorOverlayContext)
  if (!context) throw new Error('ErrorOverlayContext must be used with AppProvider!')
  return context
}
