import React, { memo, useContext } from 'react'
import Emoji from 'node-emoji'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import { Checkbox } from 'semantic-ui-react'
import { UserContext } from '../contexts/User'
import { toggleBaaS15, canSeeBaas15Toggle, isBaas15Active } from '../helpers/baasHelper'

const FooterWrapper = styled.div`
  text-align: right;
  user-select: none;
  margin: 30px 15px 15px;
  opacity: 0.35;
  span {
    user-select: all;
  }
`

const LogoutLink = styled.div`
  &:hover {
    text-decoration: underline;
  }
`

function Footer() {
  const { logout, fingerprint, email } = useContext(UserContext)

  let fireClickedCounter = 0

  const fireButtonClick = () => {
    return
    fireClickedCounter++
    if (fireClickedCounter === 7) {
      toggleBaaS15()
      fireClickedCounter = 0
      window.location.reload()
    }
  }

  const FireButton = () => <span onClick={fireButtonClick}>{Emoji.get('heart')}</span>

  const toggleBaasEnvironment = () => {
    toggleBaaS15()
    window.location.reload()
  }

  return ReactDOM.createPortal(
    <FooterWrapper>
      <a
        href="https://www.douglas.de/de/cp/help-pages-subnavigation-international/help-main-navigation/help-imprint/helpimprint/help-imprint"
        rel="noopener noreferrer"
        target="_blank"
      >
        Impressum
      </a>
      {' | '}
      <a
        href="https://www.douglas.de/de/cp/help-pages-subnavigation-international/help-main-navigation/help-dataprotection/helpdataprotection/help-dataprotection"
        rel="noopener noreferrer"
        target="_blank"
      >
        Datenschutz
      </a>
      <br />
      Made with <FireButton /> by Douglas
      {canSeeBaas15Toggle(email) && (
        <>
          <br />
          <div style={{ display: 'inline-flex', fontWeight: 'bold' }}>
            Baas{' '}
            <div style={{ margin: '0 10px' }}>
              <Checkbox toggle checked={isBaas15Active} onClick={() => toggleBaasEnvironment()} />
            </div>
            Bass 1.5
          </div>
        </>
      )}
      <br />
      Fingerprint: <span>{fingerprint}</span>
      <br />
      {email && (
        <>
          Email: <span>{email}</span>
          <br />
          <LogoutLink onClick={logout}>Logout</LogoutLink>
        </>
      )}
    </FooterWrapper>,
    document.getElementById('footer')
  )
}

export default memo(Footer)
