import React from 'react'
import styled from 'styled-components'
import { Message, List, Icon } from 'semantic-ui-react'

const Container = styled.div`
  h2 {
    font-size: 30px;
    margin-bottom: 1em !important;
  }
  .ui.message {
    background: #fff;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #9bdcd2;
  }
  .ui.list .list > .item > i.icon,
  .ui.list > .item > i.icon {
    display: inline-block;
    padding-right: 40px;
    font-size: 1.1em;
    color: #8a8a8a;
  }
  .ui.list .list > .item,
  .ui.list > .item,
  ol.ui.list li,
  ul.ui.list li {
    padding-bottom: 15px;
  }
  strong {
    color: #74c4b8;
  }
`

export default function BrifingsInfo() {
  return (
    <Container>
      <Message>
        <Message.Header>
          <h2>When will old briefings be archived?</h2>
        </Message.Header>
        <List>
          <List.Item>
            <Icon name="envelope open outline" />
            Briefings with status <strong>"Open"</strong> will be archived after <strong>21</strong> days.
          </List.Item>
          <List.Item>
            <Icon name="edit" />
            Briefings with status <strong>"Rework"</strong> will be archived after <strong>16</strong> days.
          </List.Item>
          <List.Item>
            <Icon name="circle outline" />
            Briefings with status <strong>"Ready"</strong> will be archived after <strong>14</strong> days.
          </List.Item>
          <List.Item>
            <Icon name="redo alternate" />
            Briefings with status <strong>"In review"</strong> will be never archived.
          </List.Item>
        </List>
      </Message>
    </Container>
  )
}
