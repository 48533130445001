/* eslint-disable import/no-cycle */
import React from 'react'
import { Form, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import { startCase } from 'lodash'
import { createField } from './functions'

const ContainerWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 1.5rem;
  margin-bottom: 1rem;
  .upload-container {
    border: 3px solid #cacbcd;
  }
`

export default function Container(props) {
  let containerPrefix = `${props.prefix}.${props.label}_BaasTemplevel_`
  if (props.keep) {
    // Using the name here for our development naming to seperate it from what the user sees
    containerPrefix = `${props.prefix}.${props.name}`
  }

  const Fields = (
    <Form.Field>
      {props.fields.map(childField => {
        return createField(
          childField,
          { ...props, keep: false, ...childField },
          containerPrefix,
          props.briefingId,
          props.asText,
          props.componentName,
          props.componentIndex
        )
      })}
    </Form.Field>
  )

  const withWrapper = Boolean(props.label) || props.showWrapper
  if (!withWrapper) {
    return <> {Fields} </>
  }
  return (
    <ContainerWrapper>
      <Header dividing>{startCase(props.label)}</Header>
      {Fields}
    </ContainerWrapper>
  )
}
