import React from 'react'
import Actionbar from '../ActionbarView'
import { useBriefing } from '../../../hooks/useBriefing'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import FrameWrapper from './FrameWrapper'
import FrameWrapper15 from './FrameWrapper15'
import { isBaas15Active } from '../../../helpers/baasHelper'

export default function View({ match }) {
  const briefingId = match.params.id
  const [briefing, loading] = useBriefing(briefingId)

  if (loading || !briefing) {
    return <DelayedSpinner />
  }

  return (
    <div>
      <Actionbar active="preview" match={match} briefing={briefing} loading={loading} _id={briefingId} />
      {isBaas15Active && <FrameWrapper15 briefingId={briefingId} briefing={briefing} />}
      {!isBaas15Active && <FrameWrapper briefingId={briefingId} briefing={briefing} />}
    </div>
  )
}
