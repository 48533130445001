import React, { memo } from 'react'
import { Table, Icon, Flag } from 'semantic-ui-react'
import { ROLE_PROPS } from '@douglas/baas-shared'

const UserRow = ({ user, isEditMode = false, editUser }) => {
  return (
    <Table.Row className={isEditMode ? 'disabled' : ''} key={user.userId + user.email}>
      <Table.Cell className={user.isLocked ? 'locked' : ''} title="Locked">
        {user.email} {user.isLocked && <Icon className={isEditMode ? 'hidden' : ''} name="lock" />}
      </Table.Cell>
      <Table.Cell>
        <Flag name={user.country.toLowerCase().replace('_new', '')} /> ({user.country})
      </Table.Cell>
      <Table.Cell>{ROLE_PROPS[user.role].label}</Table.Cell>
      <Table.Cell collapsing className="edit">
        <Icon
          className={isEditMode ? 'hidden' : ''}
          name="edit"
          onClick={() => {
            editUser(user)
          }}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default memo(UserRow)
