import React from 'react'
import { Popup, Image } from 'semantic-ui-react'
import overlay from '../../../images/tooltips/Overlay.gif'

export default function Overlay() {
  return (
    <div style={{ width: '600px' }}>
      <Popup.Header>Overlay example</Popup.Header>
      <Popup.Content>
        <Image src={overlay} className="tooltip" />
      </Popup.Content>
    </div>
  )
}
