import React, { useState } from 'react'
import { Menu, Button, Icon, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import BriefingInformation from '../../Overview/BriefingInformation'
import { ListButtonPreviewNew } from '../../Overview/ListButton'
import { isBaas15Active } from '../../../../helpers/baasHelper'

const warningPopupStyle = {
  fontSize: '18px',
}

export default function Actionbar(props) {
  const [showValidationWarning, setShowValidationWarning] = useState(false)
  const { onSaveAndProceed, onSaveForLater, onSaveForIncompletePreview, briefingId, briefing } = props
  const [loadingState, setLoadingState] = useState({ save: false, proceed: false })
  const onSaveForLaterClicked = async () => {
    if (loadingState.save || loadingState.proceed) return
    setLoadingState({ save: true })
    await onSaveForLater()
    setLoadingState({ save: false })
  }

  const onSaveAndProceedClicked = async () => {
    if (loadingState.save || loadingState.proceed) return
    setLoadingState({ proceed: true })
    await onSaveAndProceed()

    /**
     * Reaching this block of code means, that the form is invalid
     * and the redirect to the next checkout step (in onSaveAndProceed()) hasn't been triggered.
     * The first setTimeout is there to let the child component rerender first for a redirect since
     * we do not have control over the return value depending on if the validation worked or not.
     **/
    setLoadingState({ proceed: false })
    setTimeout(() => {
      setShowValidationWarning(true)
      setTimeout(() => {
        setShowValidationWarning(false)
      }, 10000)
    }, 200)
  }

  const onIncompletePreviewClicked = async () => {
    if (loadingState.save || loadingState.proceed) return
    setLoadingState({ save: true })
    await onSaveForIncompletePreview()
    setLoadingState({ save: false })
  }

  return (
    <Menu inverted style={{ height: '2em', minHeight: 'auto', margin: '0' }} className="action-bar-sticky">
      <Menu.Menu style={{ flexGrow: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }} />
      <Menu.Menu position="right">
        {/* tobedeleted */}
        {!isBaas15Active ? (
          <Menu.Item style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}>
            <Button
              style={{ paddingTop: '0', paddingBottom: '0' }}
              secondary
              icon
              onClick={onIncompletePreviewClicked}
              labelPosition="left"
              as={Link}
              to={`/incompletePreview/${briefingId}`}
              target="_blank"
              id="preview-button"
            >
              <Icon name="eye" />
              Preview
            </Button>
          </Menu.Item>
        ) : (
          <Menu.Item style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}>
            <ListButtonPreviewNew {...briefing} asLink onClick={onIncompletePreviewClicked} label={'Save & Preview'} />
          </Menu.Item>
        )}

        <Menu.Item style={{ borderRight: '1px solid white' }}>
          <Button
            style={{ height: '2em', paddingTop: '0', paddingBottom: '0' }}
            secondary
            icon
            onClick={onSaveForLaterClicked}
            loading={loadingState.save}
            disabled={loadingState.proceed}
            labelPosition="left"
            id="save-later-button"
          >
            <Icon name="save" />
            Save for later
          </Button>
        </Menu.Item>
        <Menu.Menu style={{ flexGrow: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          {briefingId && <BriefingInformation briefingId={briefingId} />}
        </Menu.Menu>
        <Menu.Item style={{ paddingLeft: '0', paddingRight: '0' }}>
          <Popup
            position="bottom right"
            open={showValidationWarning}
            style={warningPopupStyle}
            trigger={
              <Button
                style={{ height: '2em', paddingTop: '0', paddingBottom: '0', borderRadius: '0', borderRight: '1px solid white' }}
                primary
                icon
                onClick={onSaveAndProceedClicked}
                loading={loadingState.proceed}
                disabled={loadingState.proceed}
                labelPosition="left"
                id="save-proceed-button"
              >
                <Icon name="angle double right" />
                Save & proceed to review
              </Button>
            }
          >
            <Icon color="red" name="warning sign" /> You have validation errors in the form. Please scroll and correct them.
          </Popup>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}
