import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (module.hot) {
  module.hot.accept()
}

const history = createBrowserHistory()

fetch(`${process.env.REACT_APP_BACKEND_URL}public/component.json?cache=${Date.now()}`)
  .then(async res => {
    if(res.status !== 200){
      throw new Error("Backend is offline.")
    }

    let result
    try{
      result = await res.json()
    }catch(error){
      throw new Error("This is either because the generated component.json file is an invalid JSON (based on the options.yml files from the components), or the backend is not reachable. "+error)
    }

    return result
  })
  .catch(error => alert("Error occured. "+error))
  .then(components => {
    if(!components) return
    ReactDOM.render(
      <Router history={history} key={Math.random()}>
        <App history={history} componentContext={components} />
      </Router>,
      document.getElementById('root')
    )
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
