import React from 'react'
import styled from 'styled-components'
import { Popup, Label, Icon } from 'semantic-ui-react'
import { hasConfigRestriction } from '../../../helpers/baasHelper'
// eslint-disable-next-line import/no-cycle
import { deactivatedComponents } from './helpers'

const Wrapper = styled.div`
  display: inline;
  float: right;
  padding-left: 5px;
`
const colorGreen = '#5fc33c'
const colorRed = '#e6373c'

const Iconwrapper = styled.span`
  opacity: 0.5;
  margin-left: 5px;
`
function BriefingModulesOverview(props) {
  if (!props.briefing.elements) {
    return null
  }
  const elements = props.briefing.elementsOrder
    .map(elementId => ({ ...props.briefing.elements[elementId] }))
    .filter(element => Object.keys(element).length)

  const elementCount = (elements && Object.keys(elements).length) || 0

  const elementNames = []
  const rawElementNames = []
  if (elements) {
    Object.keys(elements).forEach(key => {
      const listElement = {
        name: elements[key].name,
        hasRestriction: hasConfigRestriction(elements[key].config),
      }
      elementNames.push(listElement)
      rawElementNames.push(elements[key].name)
    })
  }

  //const componentCountString = elementCount === 1 ? '1 module' : elementCount + ' modules'

  function getCommonElements(array1, array2) {
    return array1.filter(element => array2.includes(element))
  }
  const oldBaasComponents = getCommonElements(rawElementNames, deactivatedComponents)

  const displayElementName = name => {
    if (getCommonElements([name], deactivatedComponents).length) {
      return <span style={{ color: colorRed, fontWeight: 'bold' }}>{name}</span>
    }
    return <span>{name}</span>
  }
  return (
    <Popup
      position="left center"
      hoverable
      trigger={
        <Wrapper>
          <Label
            circular
            style={{
              float: 'right',
              cursor: 'help',
              backgroundColor: oldBaasComponents.length ? colorRed : colorGreen,
              color: oldBaasComponents.length ? 'white' : 'black',
            }}
          >
            {elementCount}
          </Label>
        </Wrapper>
      }
    >
      <Popup.Content>
        {elementNames.map((elementName, index) => (
          <div key={index}>
            {elementName.hasRestriction ? (
              <span>
                {elementName.name}
                <Iconwrapper>
                  <Icon name="hide" />
                </Iconwrapper>
              </span>
            ) : (
              displayElementName(elementName.name)
            )}
          </div>
        ))}
      </Popup.Content>
    </Popup>
  )
}

export default BriefingModulesOverview
