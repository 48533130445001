import useFormRaw from 'react-hook-form'

/*
    Extending the react-hook-form by a get-function to expose default values.
    This is needed for providing default values (edit-mode) for controlled form components (Select, DatePicker, ColorPicker)
*/
export const useForm = (initialValues = {}) => {
  const useFormControls = useFormRaw({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    submitFocusError: true,
  })

  const getDefault = (name, defaultValue = null) => {
    if (typeof initialValues !== 'object' || !initialValues.hasOwnProperty(name) || typeof initialValues[name] === 'undefined') {
      return defaultValue
    }
    return initialValues[name]
  }

  return { ...useFormControls, getDefault }
}
