import InputComponent from './Input'
import CheckboxComponent from './Checkbox'
import TextAreaComponent from './Textarea'
import SelectComponent from './Select'
import ColorPickerComponent from './ColorPicker'
import DatePickerComponent from './DatePicker'
import VideoUploaderComponent from './uploader/Video'
import AssetUploaderComponent from './uploader/Asset'
import WysiwygComponent from './Wysiwyg'
import WysiComponent from './Wysi'

export const Input = InputComponent
export const Checkbox = CheckboxComponent
export const Textarea = TextAreaComponent
export const Select = SelectComponent
export const ColorPicker = ColorPickerComponent
export const AssetUploader = AssetUploaderComponent
export const DatePicker = DatePickerComponent
export const VideoUploader = VideoUploaderComponent
export const Wysiwyg = WysiwygComponent
export const Wysi = WysiComponent
