import React, { memo } from 'react'
import { Modal, Button, Icon } from 'semantic-ui-react'
import { fetchBriefing, patchBriefing } from '../../../../core/briefingCache'

function RequestReviewOverlay({ closeOverlay, match, history }) {
  const briefingId = match.params.id

  const onRequestReviewClicked = async () => {
    await patchBriefing(briefingId, { status: 'review' })

    fetchBriefing(briefingId)
    history.push('/briefings/review')
  }

  return (
    <React.Fragment>
      <Modal.Content>
        <span>
          A supervisor will review your briefing. If there are any further inquiries, the briefing will be moved to the
          "Needs rework" filter, and you will be contacted via slack or email. When everything is fine, the page will
          appear in the "Ready for go-live" filter. You can not edit the briefing while its waiting for a review.
        </span>
      </Modal.Content>
      <Modal.Actions>
        <Button primary icon labelPosition="left" onClick={onRequestReviewClicked}>
          <Icon name="send" />
          Request review now
        </Button>
        <Button secondary icon labelPosition="right" onClick={closeOverlay}>
          <Icon name="close" />
          Cancel
        </Button>
      </Modal.Actions>
    </React.Fragment>
  )
}

export default memo(RequestReviewOverlay)
