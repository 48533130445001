import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Card, Icon } from 'semantic-ui-react'

const Container = styled.div`
  h1 {
    font-size: 50px;
    border-bottom: solid 2px #979797;
    margin-bottom: 1em;
    margin-top: 0 !important;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
  }

  .ui.card .header:not(#a) {
    margin-right: 0.25em;
    font-size: 70px !important;
    font-weight: normal !important;
    float: left;
  }

  .ui.card {
    width: auto;
    margin-bottom: 1.5em;
  }

  .ui.card .header:not(#a),
  .ui.card .description:not(#a) {
    display: inline-block;
    color: #000;
  }

  .ui.card .description:not(#a) {
    font-size: 24px;
    line-height: 36px;
    max-width: 75%;
    margin-top: 0;
  }

  a {
    color: #74c4b8;
    text-decoration: underline;
  }

  span {
    display: inline-block;
    line-height: 22px;
  }

  .content i.icon {
    font-size: 5em;
    float: right;
    opacity: 0.1;
    padding-top: 35px;
  }

  span strong {
    display: block;
  }

  span em {
    font-size: 0.7em;
    font-style: normal;
    opacity: 0.7;
  }
`
export default function GettingStarted() {
  return (
    <Container>
      <h1>Getting Started: 5 Simple Steps</h1>
      <h2>How to build your new content page, brandpage, pdp, pop or promo?</h2>
      <Card>
        <Card.Content>
          <Icon name="eye" />
          <Card.Header>1</Card.Header>
          <Card.Description>
            <span>
              <strong>Get Inspired</strong>
            </span>
            <br />
            Please take a look at all modules that are available to <Link to="/guide/">get inspired</Link>.<br />
            After that you are ready to <Link to="/briefings/new/">create a new briefing</Link>.
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Icon name="info" />
          <Card.Header>2</Card.Header>
          <Card.Description>
            <span>
              <strong>Information</strong> <em>(Enter important briefing details)</em>
            </span>
            <br />
            Define a title, description, primary and, if needed, a secondary colour. All the modules you select will adapt to this
            colour.
            <br />
            Please add dates for the final implementation and go live date. If possible add the page ID for the HMC.
            <br />
            If you have files you would like to attach (excel-list, PSD, PDF, etc.) you can upload them here as well.
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Icon name="puzzle" />
          <Card.Header>3</Card.Header>
          <Card.Description>
            <span>
              <strong>Modules</strong> <em>(Select content modules)</em>
            </span>
            <br />
            Create a Concept of your brand page, by using the modules. Select a module from the list and just drag and drop it to
            the right side.
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Icon name="configure" />
          <Card.Header>4</Card.Header>
          <Card.Description>
            <span>
              <strong>Configuration</strong> <em>(Provide all texts and assets)</em>
            </span>
            <br />
            Insert all text and assets for each module.
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Icon name="cart" />
          <Card.Header>5</Card.Header>
          <Card.Description>
            <span>
              <strong>Order</strong> <em>(Review and place your order)</em>
            </span>
            <br />
            Check your briefing and place your order. You can always see all of your briefings <Link to="/briefings">here</Link>.
          </Card.Description>
        </Card.Content>
      </Card>
    </Container>
  )
}
