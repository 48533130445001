export const sortUsers = (a, b) => {
  /* Sort users by country
  if (a.country === b.country) {
    return b.role - a.role
  }

  return b.country > a.country ? -1 : 1
   */

  // Sort users by email
  return a.email.localeCompare(b.email)
}
