const _trunc = (string, maxLength = '500') => {
  if (typeof string !== 'string') return ''
  return string.length > maxLength ? string.substr(0, maxLength - 1) + ' ...' : string
}

export const getTextFromHtml = HTML => {
  if (!HTML) return ''
  const el = document.createElement('p')
  el.innerHTML = HTML.replace(/<br>/g, ' ')
  return _trunc(el.innerText)
}
