import { useState } from 'react'
import { myUpload } from '../../../../hooks/restHooks'
import { validateImage } from '../../../../helpers/validateImage'

export const useAssetUpload = (endpoint, defaultFileUrl, assetValidation) => {
  const [assetUrl, setAssetUrl] = useState(defaultFileUrl)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const uploadAsset = async file => {
    setLoading(true)
    setError(null)
    try {
      if (assetValidation) {
        await validateImage(file, assetValidation)
      }
      const formData = new FormData()
      formData.append('file', file)
      const response = await myUpload(endpoint, formData)
      if (response.ok) {
        const result = await response.json()
        setAssetUrl(result.path)
      } else {
        setError('Upload failed.')
      }
    } catch (e) {
      setError(e.error)
    }
    setLoading(false)
  }

  const onDelete = () => {
    setAssetUrl('')
  }

  return { assetUrl, loading, error, uploadAsset, onDelete }
}
