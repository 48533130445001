import React, { useState, useEffect } from 'react'
import Iframe from 'react-iframe'
import { Popup, Button, Select, Icon, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import html2canvas from 'html2canvas'
import { get } from 'lodash'
import { usePost } from '../../../hooks/restHooks'

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 3rem;
  button.basic.ui.button {
    box-shadow: initial;
    border: 0;
  }
`

const IframeWrapper = styled.div`
  margin: 0 auto;
  max-width: ${props => props.maxWidth};
`

const breakpointOptions = [
  { key: 's', value: 's', text: 'S-View (iPhone 8, 375px width)' },
  { key: 'sp', value: 'sp', text: 'S-View+ (iPhone S Max, 414px width)' },
  { key: 'm', value: 'm', text: 'M-View (iPad, 768px width)' },
  { key: 'l', value: 'l', text: 'L-View (Desktop device)' },
]

const deviceMapping = {
  s: { width: '375px', height: '667px', icon: 'mobile alternate', label: 'iPhone 8' },
  sp: { width: '414px', height: '896px', icon: 'mobile alternate', label: 'iPhone S Max' },
  m: { width: '768px', height: '1024px', icon: 'tablet alternate', label: 'iPad' },
  l: { width: '100%', height: '800px', icon: 'desktop', label: 'Desktop' },
}

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const FrameWrapper = ({ briefingId, renderIncomplete }) => {
  let previewUrl = renderIncomplete ? `/incompletePreview/${briefingId}/frame` : `/preview/${briefingId}/frame`
  const [breakpoint, setBreakpoint] = useState('s')
  const [previewReloaded, setPreviewReloaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { trigger: triggerFetch } = usePost('briefing/recompile')

  const setIframeHeightMax = iframe => {
    iframe.height = iframe.contentWindow.document.body.scrollHeight + 200 + 'px'
  }

  const applyDeviceIframeSize = async () => {
    const iframe = document.getElementById('preview-iframe')
    if (!iframe) return
    iframe.width = deviceMapping[breakpoint].width
    iframe.height = deviceMapping[breakpoint].height

    const contentBody = get(iframe, 'contentWindow.document.body')
    if (contentBody && breakpoint === 'l') {
      setIframeHeightMax(iframe)
    }
  }

  useEffect(() => {
    applyDeviceIframeSize()
  }, [breakpoint])

  const adjustIframeSize = () => {
    setTimeout(() => {
      const iframe = document.getElementById('preview-iframe')
      if (!iframe) return
      setIframeHeightMax(iframe)
    }, 2000)
  }

  const triggerScreenshotDownload = async () => {
    const iframe = document.getElementById('preview-iframe')
    const pageWrapper = iframe.contentWindow.document.body

    if (pageWrapper) {
      setLoading(true)
      setIframeHeightMax(iframe) // Set iframe's height to 100% of content to enable full content screenshot
      await sleep(1000) // Allow the iframe's dom to react to the height changes
      html2canvas(pageWrapper, { useCORS: true }).then(canvas => {
        const image = canvas.toDataURL('image/jpeg', 0.9)
        var fileName = `Screenshot ${deviceMapping[breakpoint].label}.jpg`

        // Trigger browser download by creating an a-tag and simulating a click
        var link = document.createElement('a')
        link.setAttribute('href', image)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        applyDeviceIframeSize() // Restore correct iframe size
        setLoading(false)
      })
    }
  }

  const triggerScreenshotDownloadNew = async () => {
    const iframe = document.getElementById('preview-iframe')
    const pageWrapper = iframe.contentWindow.document.body
    // remove src-set
    const images = pageWrapper.getElementsByTagName('img')
    for (const element of images) {
      element.removeAttribute('srcset')
    }
    window.dispatchEvent(new Event('resize'))
    if (pageWrapper) {
      setLoading(true)
      setIframeHeightMax(iframe) // Set iframe's height to 100% of content to enable full content screenshot
      await sleep(2000) // Allow the iframe's dom to react to the height changes
      pageWrapper.dispatchEvent(new Event('resize'))
      await sleep(2000) // Allow the iframe's dom to react to the height changes
      html2canvas(pageWrapper, { useCORS: false, logging: true, imageTimeout: 0, proxy: '/api/canvasproxy/' }).then(canvas => {
        const image = canvas.toDataURL('image/jpeg', 0.9)
        var fileName = `Screenshot ${deviceMapping[breakpoint].label}.jpg`

        // Trigger browser download by creating an a-tag and simulating a click
        var link = document.createElement('a')
        link.setAttribute('href', image)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        applyDeviceIframeSize() // Restore correct iframe size
        setLoading(false)
      })
    }
  }

  const regeneratePreview = async () => {
    triggerFetch({ briefingId })
    setPreviewReloaded(true)
    const iframe = document.getElementById('preview-iframe')
    iframe.src = iframe.src + ''
  }

  return (
    <div style={{ position: 'relative' }}>
      <ButtonWrapper>
        <Popup
          trigger={<Button basic size="massive" icon={deviceMapping[breakpoint].icon} />}
          flowing
          hoverable
          position="bottom right"
        >
          <Select
            placeholder="Select breakpoint to show"
            options={breakpointOptions}
            value={breakpoint}
            style={{ width: '300px' }}
            onChange={(_, element) => {
              setBreakpoint(element.value)
            }}
          />
          <br />
          <Button icon labelPosition="left" style={{ marginTop: '1em' }} onClick={triggerScreenshotDownload} loading={loading}>
            <Icon name="photo" />
            Download a screenshot
          </Button>
          <br />
          <Button icon labelPosition="left" style={{ marginTop: '1em' }} onClick={triggerScreenshotDownloadNew} loading={loading}>
            <Icon name="photo" />
            Download a screenshot (new)
          </Button>
        </Popup>
        <br />
        <Message>
          <Message.Header>Preview not working?</Message.Header>
          <Message.Content>
            <Button
              icon
              labelPosition="left"
              style={{ marginTop: '1em' }}
              onClick={regeneratePreview}
              loading={loading}
              disabled={previewReloaded}
            >
              <Icon name="refresh" />
              Reload Preview
            </Button>
          </Message.Content>
        </Message>
      </ButtonWrapper>
      <IframeWrapper maxWidth={deviceMapping[breakpoint].width}>
        <Iframe
          url={previewUrl}
          height="800px"
          width="100%"
          id="preview-iframe"
          onLoad={adjustIframeSize}
          overflow="hidden"
          frameBorder="0"
        />
      </IframeWrapper>
    </div>
  )
}

export default FrameWrapper
