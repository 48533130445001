import { formatAsset } from './baasHelper'

// Helper to calculate integer:integer aspect ratios
// https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio-i-need-an-output-like-43-169
function getAspectRatio(a, b) {
  return b === 0 ? a : getAspectRatio(b, a % b)
}

const checkForErrors = (resolve, reject, image, validation) => {
  let { width, height, minHeight, maxHeight } = formatAsset(validation)

  const errorLines = []

  if (!image.height || !image.width) {
    reject(['The uploaded image file is broken.'])
  }

  // check if image is bigger than min, and smaller than min*maxScale
  if (image.height < height) {
    // eslint-disable-next-line prettier/prettier
    errorLines.push(`The image is too small in height. Expected min ${height || minHeight}px height, but received ${image.height}px.`) 
  }

  if (image.width < width) {
    // eslint-disable-next-line prettier/prettier
    errorLines.push(`The image is too small in width. Expected min ${width}px width, but received ${image.width}px.`)
  }

  const maxScale = 2 // Because we want to allow 2x resolution but not more than that
  if (image.height > height * maxScale) {
    // eslint-disable-next-line prettier/prettier
    errorLines.push(`The image is too big in height. Expected max ${height * maxScale}px height, but received ${image.height}px.`)
  }

  if (image.width > width * maxScale) {
    // eslint-disable-next-line prettier/prettier
    errorLines.push(`The image is too big in width. Expected max ${width * maxScale}px width, but received ${image.width}px.`)
  }

  if (minHeight && image.height < minHeight) {
    // eslint-disable-next-line prettier/prettier
    errorLines.push(`The image is too small. Expected min height ${minHeight}px, but received ${image.height}px.`)
  }

  if (maxHeight && image.height > maxHeight) {
    // eslint-disable-next-line prettier/prettier
    errorLines.push(`The image is too tall. Expected max height ${maxHeight}px, but received ${image.height}px.`)
  }

  if (width && height) {
    // check if image has the correct aspect ratio, or an aspect ratio very close to the expected one (maxAspectRatioDeviation)
    const aspectRatio = image.width / image.height
    const expectedAspectRatio = width / height
    const maxAspectRatioDeviation = 0.1 // 10% deviation from width and height is allowed
    if (
      expectedAspectRatio + maxAspectRatioDeviation < aspectRatio ||
      expectedAspectRatio - maxAspectRatioDeviation > aspectRatio
    ) {
      const expectedRatio = getAspectRatio(width, height)
      const receivedRatio = getAspectRatio(image.width, image.height)
      const expectedAspectRatioPrint = `${width / expectedRatio}:${height / expectedRatio}`
      const receivedAspectRatioPrint = `${image.width / receivedRatio}:${image.height / receivedRatio}`
      // eslint-disable-next-line prettier/prettier
      errorLines.push(`The image's aspect ratio is wrong. Expected ${expectedAspectRatioPrint} but received ${receivedAspectRatioPrint}.`)
    }
  }

  if (errorLines.length) reject({ error: errorLines.join('\n') })
  else resolve()
}

export const validateImage = (imageFile, assetValidation) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const image = new Image()
      image.src = fileReader.result
      image.onload = () => {
        checkForErrors(resolve, reject, image, assetValidation)
      }
    }
    fileReader.readAsDataURL(imageFile)
  })
