import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Table, Icon, Button, Form, Input, Dropdown, Label } from 'semantic-ui-react'
import { ROLE, ROLE_PROPS, countryOptions } from '@douglas/baas-shared'
import Layout from '../../Layout'
import { useGet } from '../../../hooks/restHooks'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import HeaderHr from '../../../components/semantic/HeaderHr'
import AddUserForm from './AddUserForm'
import EditUserButtons from './EditUserButtons'
import UserRow from './UserRow'
import { sortUsers } from './sortUsers'

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
  }

  td.locked {
    opacity: 0.4;
  }

  tr.locked td.edit {
    opacity: 1;
  }

  .hidden {
    visibility: hidden;
  }
  .input,
  .dropdown {
    margin: 0px 5px;
  }
`

export default function User(props) {
  const { data, loading } = useGet(`admin/user/all`)
  const [userEdit, setUserEdit] = useState(false)
  const [showAddUserForm, setShowAddUserForm] = useState(false)
  const [users, setUsers] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')

  const CountrySelection = () => (
    <Dropdown
      placeholder={selectedCountry ? selectedCountry : 'Select Country'}
      value={selectedCountry}
      selection
      clearable
      search
      options={countryOptions}
      key={countryOptions.value}
      onChange={handleSelectCountry}
    />
  )

  const UserRoleSelection = () => (
    <Dropdown
      placeholder={selectedRole ? selectedRole : 'Select User Role'}
      value={selectedRole}
      selection
      clearable
      search
      options={USER_ROLES}
      key={USER_ROLES}
      onChange={handleSelectRole}
    />
  )

  const USER_ROLES = Object.keys(ROLE).map(role => ({
    key: ROLE[role],
    value: ROLE[role],
    text: ROLE_PROPS[ROLE[role]].label,
  }))

  const orderedUsers = users ? users.sort(sortUsers) : []

  const handleFilters = (selectedRole, selectedCountry, searchText) => {
    setFilteredUsers(
      orderedUsers
        .filter(user => user.country === selectedCountry || selectedCountry === '')
        .filter(user => user.role === selectedRole || selectedRole === '')
        .filter(user => user.email.includes(searchText) || searchText === '')
    )
  }

  useEffect(() => {
    setUsers(data)
  }, [data])

  useEffect(() => {
    setFilteredUsers(orderedUsers)
  }, [users])

  useEffect(() => {
    handleFilters(selectedRole, selectedCountry, searchText)
  }, [selectedRole, selectedCountry, searchText])

  if (loading) {
    return <DelayedSpinner />
  }

  const onLock = result => {
    setUsers(
      orderedUsers.map(user => {
        if (user.userId === result.userId) {
          return result
        }
        return user
      })
    )
  }

  const onSaveUser = result => {
    setUsers([...orderedUsers, result])

    if (result.ok) {
      setShowAddUserForm(false)
      return null
    }

    serverError()
  }

  const onUpdateUser = result => {
    setUsers(
      orderedUsers.map(user => {
        if (user.userId === result.userId) {
          return result
        }
        return user
      })
    )

    if (result.ok) {
      leaveEditMode()
      return null
    }

    serverError()
  }

  const onDelete = result => {
    setUsers(orderedUsers.filter(user => user.userId !== result.userId))
    leaveEditMode()
  }

  const handleSelectCountry = (e, Country) => {
    setSelectedCountry(Country.value)
  }

  const handleSelectRole = (e, { value }) => {
    setSelectedRole(value)
  }

  const handleSearch = event => {
    setSearchText(event.target.value)
  }

  const editUser = user => setUserEdit(user)
  const leaveEditMode = () => setUserEdit(false)
  const addUserForm = () => setShowAddUserForm(!showAddUserForm)
  const serverError = () => window.alert('An unknown error has occurred. Please try again later.')

  const renderUserList = orderedUsers => {
    if (typeof orderedUsers === 'undefined' || !orderedUsers.length) {
      return (
        <Table.Row active>
          <Table.Cell colSpan="4" style={{ textAlign: 'center' }}>
            Empty
          </Table.Cell>
        </Table.Row>
      )
    }

    return orderedUsers.map(user => {
      if (user.userId === userEdit.userId) {
        return (
          <React.Fragment key={user.userId}>
            {<AddUserForm userEdit={userEdit} onCreate={onSaveUser} onUpdate={onUpdateUser} />}
            <EditUserButtons user={user} onLock={onLock} onDelete={onDelete} onLeave={leaveEditMode} />
          </React.Fragment>
        )
      }

      return <UserRow user={user} isEditMode={userEdit.userId} editUser={editUser} key={user.userId + user.email} />
    })
  }

  return (
    <Layout showHr={false} style={{ paddingBottom: '1rem' }}>
      <Container>
        <HeaderHr style={{ marginBottom: '1rem', marginTop: '1rem' }} title={props.title} icon={'user'} />
        <Form>
          <Input focus value={searchText} onChange={handleSearch} placeholder="Search email..." />
          <CountrySelection />
          <UserRoleSelection />
          <Label>
            <Icon name="user" /> {filteredUsers.length}
          </Label>
          <Button
            style={{ marginBottom: '1rem' }}
            primary={!showAddUserForm}
            icon={showAddUserForm}
            labelPosition={showAddUserForm ? 'left' : null}
            floated="right"
            onClick={addUserForm}
            disabled={!!userEdit.userId}
          >
            <Icon name={showAddUserForm ? 'close' : 'user add'} />
            {showAddUserForm ? 'Close form' : 'Create user'}
          </Button>

          <Table celled striped style={{ marginBottom: '3rem' }}>
            <Table.Body>
              {showAddUserForm && !userEdit.userId && (
                <AddUserForm userEdit={userEdit} onCreate={onSaveUser} onUpdate={onUpdateUser} />
              )}
              {renderUserList(filteredUsers)}
            </Table.Body>
          </Table>
        </Form>
      </Container>
    </Layout>
  )
}
