const germanDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
const germanDateTimeOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const getDate = rawDate => {
  const date = new Date(rawDate)
  return date.toLocaleDateString('en-US', germanDateOptions)
}

export const getDateAndTime = rawDate => {
  const date = new Date(rawDate)
  return date.toLocaleString('en-US', germanDateTimeOptions)
}
