import React, { createContext, useEffect, useContext } from 'react'
import { constants, ROLE } from '@douglas/baas-shared'
import { useLocalStorage } from '../hooks/useLocaleStorage'
import { generateHash } from '../helpers/generateHash'
import { setAuthHeaders, myFetch } from '../hooks/restHooks'
import { clearBriefingCache } from '../core/briefingCache'

const initialUserObj = {
  token: null,
  userId: null,
  role: null,
  email: null,
  country: null,
}

export const UserContext = createContext()

export default function UserContextProvider({ children }) {
  const [user, setUser] = useLocalStorage('baas-user', {
    fingerprint: null,
    ...initialUserObj,
  })
  const [, setTempEmail] = useLocalStorage('baas-login-email')

  const { fingerprint, userId, token } = user
  setAuthHeaders({ [constants.headers.userId]: userId, [constants.headers.token]: token })

  const login = userObj => {
    setTempEmail('')
    setUser({ ...user, ...userObj })
  }

  const logout = () => {
    clearBriefingCache()
    setTempEmail('')
    setUser({ ...user, ...initialUserObj })
  }

  const checkSession = async () => {
    console.log('Check session validity')
    const res = await myFetch('session/check')
    const result = await res.json()
    if (result.expired) {
      console.log('Session expired. Logging out.')
      logout()
    } else if (result.role !== user.role) {
      console.log('User role has changed. Updating.')
      setUser({ ...user, role: result.role })
    }
  }

  useEffect(() => {
    if (!fingerprint) {
      setUser({ ...user, fingerprint: generateHash(12) })
    }

    if (!userId) return
    checkSession() // Checking the session once when the app loads
  }, [])

  // Checking the validity of the session once every 10 min
  useEffect(() => {
    if (!userId) return
    const interval = setInterval(checkSession, 10 * 60 * 1000) // Check session every 10 min

    return () => {
      clearInterval(interval)
    }
  }, [userId, token])

  const isAdmin = user.role === ROLE.admin

  return <UserContext.Provider value={{ ...user, login, logout, isAdmin }}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) throw new Error('UserContext must be used with AppProvider!')
  return context
}
