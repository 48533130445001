import React from 'react'
import { constants } from '@douglas/baas-shared'
import Screens from './Screens'
import './App.css'
import './lib/semantic/semantic.min.css'
import './lib/semantic/semantic.overrides.css'
import ComponentContextProvider from './contexts/Component'
import UserContextProvider from './contexts/User'
import { setAuthHeaders, setBackendUrl } from './hooks/restHooks'
import ErrorToasterContextProvider from './contexts/ErrorOverlay'

// Restore session with already logged-in user from locale storage
const userJson = window.localStorage.getItem('baas-user')
if (userJson) {
  try {
    const user = JSON.parse(userJson)
    if (user.token && user.userId) {
      setAuthHeaders({ [constants.headers.userId]: user.userId, [constants.headers.token]: user.token })
    }
  } catch (e) {
    console.error(e)
  }
}

setBackendUrl(process.env.REACT_APP_BACKEND_URL)
function App(props) {
  return (
    <ErrorToasterContextProvider>
      <UserContextProvider>
        <ComponentContextProvider components={props.componentContext}>
          <Screens {...props} />
        </ComponentContextProvider>
      </UserContextProvider>
    </ErrorToasterContextProvider>
  )
}

export default App
