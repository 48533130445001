import React from 'react'
import styled from 'styled-components'
import { Flag } from 'semantic-ui-react'
import { useBriefing } from '../../../hooks/useBriefing'

const ThickP = styled.p`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 1.2em;
  color: white;
  i.flag:not(.icon) {
    margin-right: 0.2em;
    line-height: 0.8;
    + i.flag:not(.icon) {
      margin-left: 0;
    }
  }
`

const BriefingInformation = props => {
  const [briefing] = useBriefing(props.briefingId)
  if (!briefing) return <React.Fragment />
  return (
    <ThickP>
      {briefing.title} (
      {briefing.country.split(',').map(c => (
        <div key={c}>
          <Flag name={c.toLowerCase().replace('_new', '')} />
          {c.toLowerCase().indexOf('_new') > 0 && 'NEW'}
        </div>
      ))}
      )
    </ThickP>
  )
}

export default BriefingInformation
