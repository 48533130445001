import React, { useState } from 'react'
import { Table, Button } from 'semantic-ui-react'
import { usePost } from '../../../hooks/restHooks'

export default function CopyItem(props) {
  const { element, refetch, setCopiing, copyMe } = props
  let { copyStatus, _id } = element
  if (!element.copied) {
    copyStatus = 'not-copied'
  } else {
    if (element.publishedToHybris) {
      copyStatus = 'published'
    } else {
      copyStatus = 'copied'
    }
  }

  const [isLoadingInternal, setIsLoadingInternal] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)
  const { trigger: triggerCopy } = usePost('admin/copyid')

  const onCopyClicked = async () => {
    setIsLoadingInternal(true)
    setCopiing(true)
    await triggerCopy({
      id: _id,
    })
    setIsLoadingInternal(false)
    setCopiing(false)
    //refetch()
    setCopySuccess(true)
  }

  if (copyStatus === 'compiling') {
    setCopiing(true)
    setTimeout(function() {
      setCopiing(false)
      refetch()
    }, 3000)
    //setCopiing(false)
  }

  const iscopied = copyStatus === 'copied'
  const isPubslished = copyStatus === 'published'
  const rowColor = { compiled: '#e6f6f4', published: '#9bdcd2', 'not-copied': isLoadingInternal ? '#f2f2f2' : 'none' }

  copyMe && !isLoadingInternal && onCopyClicked()
  return (
    <Table.Row key={element.id} style={{ backgroundColor: rowColor[copyStatus] }}>
      <Table.Cell style={{ textAlign: 'right' }}>{element.identifier}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>{element.title}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>{element.type}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>{element.status}</Table.Cell>
      <Table.Cell style={{ textAlign: 'left' }}>
        {!isPubslished && !iscopied && !copySuccess && (
          <Button
            size="tiny"
            onClick={onCopyClicked}
            loading={isLoadingInternal}
            disabled={(!isLoadingInternal && props.migrating) || copyStatus === 'compiling'}
          >
            {!iscopied ? 'copy' : 'done'}
          </Button>
        )}
        {(iscopied || copySuccess) && <span>copied</span>}
      </Table.Cell>
    </Table.Row>
  )
}
