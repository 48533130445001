import React, { useState, useEffect } from 'react'
import { UserAgentApplication } from 'msal'
import { Loader } from 'semantic-ui-react'
import { usePost } from '../../hooks/restHooks'
import Emoji from '../../components/Emoji'
import { useUserContext } from '../../contexts/User'

const AzureLoginConfig = {
  auth: {
    clientId: '1fccd895-112a-41ce-bddc-adccbf32dfee',
  },
  redirectUri: window.location.origin,
}

export const AzureLogin = props => {
  const { login } = useUserContext()
  const { email, setError, emoji, setEmail } = props
  const { trigger, error } = usePost('session/verify', false, false)
  const [showTick, setShowTick] = useState(false)

  useEffect(() => {
    setError(error)
    return () => {
      setError('')
    }
  }, [error])

  const startLogin = async () => {
    const AzureInstance = new UserAgentApplication(AzureLoginConfig)
    try {
      const response = await AzureInstance.loginPopup({ loginHint: email })
      const userInformation = await trigger({ token: response.idToken.rawIdToken, email })
      // Because the login function just passes in all object properties we remove this before
      delete userInformation.ok
      setShowTick(true)
      setTimeout(() => {
        login(userInformation)
      }, 2000)
    } catch (error) {
      setError(error.errorMessage)
      setTimeout(() => setEmail(''), 2000)
    }
  }

  useEffect(() => {
    const tokenMatch = window.location.hash.includes('id_token=')
    if (tokenMatch) {
      /**
       * In this case we are in the login popup and instantiate the
       * UserAgentApplication which automatically handles the popup and token
       * handoff to the already open tab
       *  */
      new UserAgentApplication(AzureLoginConfig)
    } else {
      startLogin()
    }
  }, [])

  if (showTick) {
    return <Emoji size="80" name={emoji} />
  }

  return (
    <React.Fragment>
      <Loader active inline="centered" style={{ marginTop: '3em' }} />
      {error && (
        <a
          onClick={() => setEmail('')}
          href="https://login.microsoftonline.com/common/oauth2/logout"
          target="_blank"
          rel="noopener noreferrer"
        >
          Logout from Azure
        </a>
      )}
    </React.Fragment>
  )
}
