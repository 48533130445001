import React from 'react'
import { captionMappings, ROLE_PROPS } from '@douglas/baas-shared'
import { Table, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Container = styled.div`
  h3 {
    margin: 20px 0 !important;
  }
  .check {
    color: #5fc33c;
  }
  .cancel {
    color: #e6373c;
  }
  .flag {
    color: #8a8a8a;
  }
  .user {
    color: #000;
  }
  .user-roles-label {
    padding-right: 20px;
  }
`
const userRolesArray = Object.values(ROLE_PROPS).reverse()
const setUpRolesDescription = elem => {
  switch (elem) {
    case 10:
      return <Icon name="check" />
    case 5:
      return <Icon name="flag" />
    case 1:
      return <Icon name="user" />
    default:
      return <Icon name="cancel" />
  }
}

export default function UserRolesInfo() {
  return (
    <Container>
      <Icon name="check" />
      <span className="user-roles-label">All</span>
      <Icon name="flag" />
      <span className="user-roles-label">Country</span>
      <Icon name="user" />
      <span className="user-roles-label">Own</span>
      <Icon name="cancel" />
      <span className="user-roles-label">None</span>
      {userRolesArray.map((item, index) => (
        <div className="user-role-table" key={index} style={{ paddingBottom: '20px' }}>
          <h3>{item.label}</h3>
          <Table basic="very" celled collapsing>
            <Table.Body>
              <Table.Row>
                {Object.values(captionMappings).map((elem, i) => (
                  <Table.Cell key={i} style={{ borderBottom: '2px solid #74c4b8' }}>
                    {elem}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                {Object.values(item['features']).map((value, ind) => (
                  <Table.Cell key={ind} style={{ textAlign: 'center' }}>
                    {setUpRolesDescription(value)}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      ))}
    </Container>
  )
}
