import React from 'react'
import { Button, Loader } from 'semantic-ui-react'
import ImageZoom from 'react-medium-image-zoom'

export const VideoRepresentation = ({ videoDetails, onDelete, asText }) => {
  const { thumbnail, title } = videoDetails

  const onDeleteClicked = event => {
    event.preventDefault()
    //eslint-disable-next-line no-restricted-globals
    const answer = confirm(
      `Do you really want to delete the video "${title}"? This also permanently removes it from the movingimage24 servers.`
    )
    if (answer) {
      onDelete()
    }
  }
  return (
    <div style={{ display: 'flex' }}>
      <div>
        {!thumbnail && (
          <>
            <Loader active inline="left" />
            <span style={{ marginLeft: '1em' }}>
              Video is processing
              <br />
              (You don't have to wait until its finished for saving the briefing)
            </span>
          </>
        )}
        {thumbnail && (
          <ImageZoom
            image={{
              src: thumbnail,
              style: { maxWidth: '100px', maxHeight: '100px' },
            }}
            zoomImage={{
              src: thumbnail,
            }}
          />
        )}
      </div>
      <div style={{ padding: '8px 0 0 10px', flexGrow: '2', wordWrap: 'break-word', maxWidth: '65%' }}>{title}</div>
      <div>
        {!asText && thumbnail && <Button icon="trash" color="red" onClick={onDeleteClicked} className="test-delete-button" />}
      </div>
    </div>
  )
}
