import React, { useEffect, useState } from 'react'
import { Table, Icon, Button, Confirm, Modal } from 'semantic-ui-react'
import { usePost } from '../../../hooks/restHooks'

export default function EditUserButtons({ user, onLock, onDelete, onLeave }) {
  const { trigger, error } = usePost('user/patch')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (error) {
      setModalOpen(true)
    }
  }, [error])

  const lockUser = async () => {
    const res = await trigger({
      userId: user.userId,
      data: {
        isLocked: !user.isLocked,
      },
    })

    onLock(res)
  }

  const deleteUser = async userId => {
    const res = await trigger({
      userId,
      data: {
        isDeleted: true,
      },
    })

    onDelete(res)
  }

  return (
    <>
      <Table.Row>
        <Table.Cell colSpan="4">
          <Button basic onClick={() => lockUser()}>
            <Icon name={user.isLocked ? 'lock open' : 'lock'} />
            {user.isLocked ? 'Unlock' : 'Lock'} user
          </Button>

          <Button primary onClick={() => setConfirmOpen(true)}>
            <Icon name="user delete" />
            Delete user
          </Button>

          <Button floated="right" icon labelPosition="left" onClick={() => onLeave(false)}>
            <Icon name="close" />
            Exit edit mode
          </Button>
        </Table.Cell>
      </Table.Row>
      <Confirm
        content="Are you sure you want to delete this user?"
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={async () => deleteUser(user.userId)}
      />
      <Modal size="small" open={modalOpen} onClose={() => setModalOpen(true)}>
        <Modal.Header>Server Error</Modal.Header>
        <Modal.Content>
          <p>An error occurred while trying to update the data. Please try again later.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => setModalOpen(false)}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}
