import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useComponentConfigs } from '../../../hooks/useComponentConfigs'
import { ComponentContext } from '../../../contexts/Component'
import ContentModule from '../../../components/ContentModule'
import Layout from '../../Layout'
import BreakpointToggleButton from './BreakpointToggleButton'

const ModuleWrapper = styled.div`
  & .contentModule img {
    margin: 0 auto 90px auto;
  }
`

export default function ModuleView({ match, briefing }) {
  const briefingId = briefing.id
  const { elements } = useComponentConfigs(briefingId)
  const [isMobile, setIsMobile] = useState(false)

  const { getComponentByName } = useContext(ComponentContext)
  const renderElements = elements.map(e => {
    const componentPrototype = getComponentByName(e.name)
    return {
      ...e,
      metadata: { assets: componentPrototype.metadata.assets },
    }
  })

  return (
    <div style={{ position: 'relative' }}>
      <BreakpointToggleButton
        isMobile={isMobile}
        toggleIsMobile={() => {
          setIsMobile(!isMobile)
        }}
      />
      <Layout>
        <ModuleWrapper>
          {renderElements.map(e => (
            <ContentModule key={e._id} assets={e.metadata.assets} mobile={isMobile} name={e.metadata.name} />
          ))}
        </ModuleWrapper>
      </Layout>
    </div>
  )
}
