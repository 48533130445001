import React from 'react'
import { Wysi } from '../../../../components/semantic/Form'

const WysiTest = props => {
  if (props.validation && props.validation.pattern) {
    // Instantiating RegExp object, because react-hook-form relies on that for pattern validation.
    props.validation.pattern = new RegExp(props.validation.pattern)
  }
  return <Wysi {...props} />
}

export default WysiTest
