import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Form, Button, Loader } from 'semantic-ui-react'

import { Label } from '../sharedComponents'
import { FormInlineError } from '../FormInlineError'
import { UploadContainer, Link } from './styledComponents'
import { useMovingimage24 } from './useMovingimage24'
import { VideoRepresentation } from './VideoRepresentation'
import { fileTooLargeString } from './helpers'

const mimeTypesVideos = ['video/mp4', 'video/mpeg', 'video/webm']
export const maxVideoSize = 500000000 // 120mib

const VideoUploader = props => {
  const {
    maxSize = maxVideoSize,
    name,
    validation = {},
    label,
    register,
    unregister,
    errors,
    setValue,
    setError,
    clearError,
    getDefault,
    asText,
    description,
    componentIndex,
    componentName,
    originName,
  } = props
  const { required } = validation
  const defaultValue = getDefault(name)
  const [showInput, setShowInput] = useState(false)
  const [tempVideoId, setTempVideoId] = useState(defaultValue)
  const { uploadVideo, loading, error, videoDetails, videoId, setVideoId, onDelete } = useMovingimage24(defaultValue || '')

  /**
   * This variable is there to give the user a good experience using this component.
   * When the user has just uploaded a video we just assume that it will be available
   * and set this to true to skip validation.
   * When the user manually enters a `videoId` though we do want to check if the video
   * exists and we do so via the thumbnail. If we can get it, it's a valid video and if not
   * it's probably not a valid video.
   *
   * This all relies on the assumption that MovingImage24 processes quickly and doesn't break.
   *
   */
  const [hasJustBeenUploaded, setHasJustBeenUploaded] = useState(false)

  // Register the uploader to react-hook-form
  useEffect(() => {
    register({ name }, { ...validation })
    if (defaultValue) {
      setValue(name, defaultValue)
    }

    // Unregister from react-hook-form when the uploader is unmounted.
    // This also clears its validation requirements.
    return () => {
      unregister(name)
    }
  }, [])

  // Update the error message when the uploader throws an error
  useEffect(() => {
    if (error) {
      setError(name, error)
    } else {
      clearError(name)
    }
  }, [error])

  // If the videoId is changed as a result of an uploaded video,
  // also change the tempVideoId for the manual input field.
  useEffect(() => {
    setTempVideoId(videoId)
  }, [videoId])

  /* Validating the uploader component. It is valid, if:
   * - The video ID is correctly set and the thumbnail is available meaning, the video exists and is still processing
   * - The video ID is correctly set and the video was just uploaded by the user so it exists but is not processed
   * - The video is not required and the video ID is empty
   */
  useEffect(() => {
    if ((videoId && (hasJustBeenUploaded || videoDetails.thumbnail)) || (videoId === '' && !required)) {
      clearError(name)
      setValue(name, videoId)
    } else {
      setValue(name, null)
    }
  }, [videoDetails.thumbnail, hasJustBeenUploaded, videoId])

  // This function automatically gets a list of accepted and rejected files
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      // Checking if the file is too large else the filetype is wrong
      if (file.size > maxSize) {
        setError(name, fileTooLargeString(file.size, maxSize, true))
      } else {
        setError(name, 'This file type is not allowed.')
      }
      return
    }

    const file = acceptedFiles[0]
    setHasJustBeenUploaded(true)
    const newVideoId = await uploadVideo(file)
    if (newVideoId) {
      setVideoId(newVideoId)
    }
  }

  const saveManualVideoID = () => {
    setHasJustBeenUploaded(false)
    setVideoId(tempVideoId)
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: mimeTypesVideos,
    noClick: true,
    maxSize,
  })

  if (loading) {
    return (
      <UploadContainer>
        <Loader active inline="centered" />
        <div style={{ textAlign: 'center' }}>Uploading...</div>
      </UploadContainer>
    )
  }

  return (
    <Form.Field error={!!errors[name]} id={`${componentIndex}-${componentName}-${originName}-video`}>
      <Label required={required} text={label} description={description} />
      {!asText && (
        <UploadContainer
          className="upload-container"
          {...getRootProps({ noClick: true })}
          style={isDragActive ? { border: '3px solid #c3eae4' } : {}}
        >
          {videoId && !errors[name] && <VideoRepresentation videoDetails={videoDetails} onDelete={onDelete} />}
          {(!videoId || errors[name]) && (
            <React.Fragment>
              <input {...getInputProps({ multiple: false })} />
              <div style={{ float: 'right' }}>
                <Button onClick={open}>Select a file</Button>
              </div>
              <div style={{ paddingTop: '5px', fontWeight: 'bold' }}>Drag drop a file here</div>
            </React.Fragment>
          )}

          {!asText && showInput && (
            <Form.Group style={{ marginTop: '3em' }}>
              <input value={tempVideoId} onChange={event => setTempVideoId(event.target.value)} />
              <Button onClick={saveManualVideoID}>Save</Button>
            </Form.Group>
          )}
          <Link onClick={() => setShowInput(!showInput)}>{showInput ? 'Hide' : 'Enter a video ID manually'}</Link>
        </UploadContainer>
      )}

      {asText && !errors[name] && <VideoRepresentation videoDetails={videoDetails} onDelete={onDelete} asText={true} />}
      {!asText && errors[name] && <FormInlineError>{errors[name].type}</FormInlineError>}
    </Form.Field>
  )
}

export default VideoUploader
