import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import Text from '../Fields/Text'

const anchorValidation = {}

const TextInputWrapper = props => (
  <Text
    label="Anchor"
    description="A name which can be used to jump to this component."
    validation={anchorValidation}
    asText={true}
    originName={'anchor'}
    {...props}
  />
)

export const AnchorInput = props => {
  const { getDefault, name, asText = false } = props
  const defaultValue = getDefault(name)
  const [open, setOpen] = useState(!!defaultValue)

  if (asText) {
    return <TextInputWrapper asText={true} {...props} />
  }

  return (
    <div style={{ marginTop: '1em' }}>
      <Accordion fluid>
        <Accordion.Title active={open} index={0} onClick={() => setOpen(!open)} className="test-anchor-accordion-button">
          <Icon name="dropdown" />
          Advanced
        </Accordion.Title>
        <Accordion.Content active={open}>
          <TextInputWrapper {...props} />
        </Accordion.Content>
      </Accordion>
    </div>
  )
}
