import React, { useMemo, useState } from 'react'
import { Icon, Header, Message } from 'semantic-ui-react'
import { functions } from '@douglas/baas-shared'
import Layout from '../Layout'
import { LoginBox } from './LoginBox'
import { VerifyBox } from './VerifyBox'
import { AzureLogin } from './AzureLogin'
import { useLocalStorage } from '../../hooks/useLocaleStorage'

const { checkIsDouglasMail } = functions
const resultEmojis = ['tada', 'tanabata_tree', 'fire', 'rocket', 'thumbsup', 'v', 'white_check_mark']
const getRandomEmoji = () => resultEmojis[Math.floor(Math.random() * resultEmojis.length)]

const index = () => {
  const [error, setError] = useState('')
  const [email, setEmail] = useLocalStorage('baas-login-email')
  const isDouglasMail = useMemo(() => checkIsDouglasMail(email), [email])
  const emoji = useMemo(getRandomEmoji, [])

  const childProps = {
    email,
    setEmail,
    setError,
    emoji,
  }

  return (
    <Layout showHr={true}>
      <Header as="h2" icon textAlign="center" style={{ marginTop: '3em' }}>
        <Icon name="lock" circular />
        <Header.Content>Login</Header.Content>
      </Header>
      <div style={{ textAlign: 'center', maxWidth: '400px', margin: '2em auto 0 auto' }}>
        {!isDouglasMail && (!email && <LoginBox {...childProps} />)}
        {!isDouglasMail && (email && <VerifyBox {...childProps} />)}
        {isDouglasMail && <AzureLogin {...childProps} />}
      </div>
      {error && (
        <Message negative>
          <Message.Header>{error}</Message.Header>
        </Message>
      )}
    </Layout>
  )
}
export default index
