import { cloneDeep } from 'lodash'

export const insertAfter = (array, insertElement, index) => [...array.slice(0, index), insertElement, ...array.slice(index)]

export const swap = (array, fromIndex, toIndex) =>
  array.map((item, index) => {
    //eslint-disable-next-line
    if (index == fromIndex) return cloneDeep(array[toIndex])
    //eslint-disable-next-line
    if (index == toIndex) return cloneDeep(array[fromIndex])
    return item
  })

export const hasElements = array => Array.isArray(array) && array.length > 0
