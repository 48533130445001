import React, { useEffect, useState } from 'react'
import { EditorState, Editor, ContentState, convertToRaw, convertFromHTML, RichUtils } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import styled from 'styled-components'
import { Form } from 'semantic-ui-react'
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'
import { Label } from './sharedComponents'
import { FormInlineError } from './FormInlineError'

const RichEditorStyles = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  .RichEditor-editor {
    cursor: text;
    font-size: 16px;

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
      margin: 0 -15px -15px;
      padding: 0 15px 15px;
    }

    .public-DraftEditor-content {
      min-height: 100px;
    }
  }

  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
`
const Wysiwyg = props => {
  const {
    name,
    validation = {},
    errorMessage,
    label,
    register,
    unregister,
    setValue,
    errors,
    asText,
    getDefault,
    description,
    plainText,
    componentIndex = 0,
    componentName = 'form',
    originName = name,
  } = props
  /**
   * The default is a HTML string and thus we need to do some type conversions to get a working Editorstate.
   * We are not storing the paragraph tag that wraps our content because we wrap the HTML in our generator
   * already. Nesting paragraph tag's doesn't work as they will be misinterpreted by the browsers.
   **/
  const defaultValue = getDefault(name)
  const withParagraphTag = `<p>${defaultValue || ''}</p>`
  const fromHTML = convertFromHTML(withParagraphTag)
  const DraftState = ContentState.createFromBlockArray(fromHTML.contentBlocks, fromHTML.entityMap)

  /**
   * Here we check if the saved default has any text in it and if not we create a fresh
   * editorState instance. This is because `createWithContent` can't accept empty HTML.
   **/
  const defaultContent = DraftState.getPlainText() ? EditorState.createWithContent(DraftState) : EditorState.createEmpty()
  const [editorState, setEditorState] = useState(defaultContent)

  useEffect(() => {
    register(
      { name },
      {
        // Custom Validation because we store the HTML in our database but want to validate against plaintext
        validate: {
          maxLength: value => {
            if (!validation || !validation.maxLength || !value) return true
            return getTextFromHtml(value.trim()).length <= validation.maxLength
          },
          minLength: value => {
            if (!validation || !validation.minLength || !value) return true
            return getTextFromHtml(value.trim()).length >= validation.minLength
          },
          required: value => {
            if (!validation || !validation.required) return true
            if (!value) return false
            return Boolean(getTextFromHtml(value.trim()))
          },
        },
      }
    )

    return () => {
      unregister(name)
    }
  }, [])

  const handleKeyCommand = ({ command }) => {
    //const {editorState} = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      setEditorState(newState)
      return true
    }

    return false
  }

  const toggleInlineStyle = style => {
    //const {editorState} = this.state;
    setEditorState(RichUtils.toggleInlineStyle(editorState, style))
  }

  const toggleBlockType = type => {
    //const {editorState} = this.state;
    setEditorState(RichUtils.toggleBlockType(editorState, type))
  }

  return (
    <Form.Field
      error={!!errors[name]}
      id={`${componentIndex}-${componentName}-${originName}-text-wrapper`}
      style={{ position: 'relative' }}
    >
      <Label required={validation.required} text={label} description={description} />
      {/*<button onClick={() => saveContent(editorState.getCurrentContent())}>Save</button>*/}
      {!asText && (
        <RichEditorStyles>
          <div className="controls container RichEditor-controls">
            <BlockStyleControls className="block" editorState={editorState} onToggle={toggleBlockType} />
            <InlineStyleControls className="inline" editorState={editorState} onToggle={toggleInlineStyle} />
          </div>
          <div className="editor">
            <Editor
              editorState={editorState}
              onChange={newState => {
                const rawContentState = convertToRaw(newState.getCurrentContent())
                const markup = draftToHtml(rawContentState)
                const markupWithoutParagraph = markup.slice(3, -5)
                const getText = getTextFromHtml(markupWithoutParagraph)
                const newValue = plainText ? getText : markupWithoutParagraph
                setEditorState(newState)
                setValue(name, newValue)
              }}
              handleKeyCommand={handleKeyCommand}
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              //ref="editor"
              spellcheck={true}
              readOnly={false}
            />
          </div>
        </RichEditorStyles>
      )}
      {asText && <span dangerouslySetInnerHTML={{ __html: defaultValue }} />}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'blockquote'
    default:
      return null
  }
}

const StyleButton = props => {
  const { onToggle, label, active, style } = props
  let className = 'styleButton RichEditor-styleButton'

  if (active) {
    className += ' active RichEditor-activeButton'
  }

  const handleMouseDown = e => {
    e.preventDefault()
    onToggle(style)
  }

  return (
    <span className={className} onMouseDown={handleMouseDown}>
      {label}
    </span>
  )
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: '"', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: '</>', style: 'code-block' },
]

const BlockStyleControls = props => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <span className="block controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </span>
  )
}

const INLINE_STYLES = [
  { label: 'B', style: 'BOLD' },
  { label: 'I', style: 'ITALIC' },
  { label: 'U', style: 'UNDERLINE' },
  { label: 'M', style: 'CODE' },
]

const InlineStyleControls = props => {
  let currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <span className="inline controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </span>
  )
}

export default Wysiwyg
