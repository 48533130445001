import React, { useEffect, useState } from 'react'
import { Editor, EditorState, ContentState, convertToRaw, convertFromHTML, RichUtils } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import { FormInlineError } from './FormInlineError'
import { Label } from './sharedComponents'
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'

const CharsCount = styled.span`
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 0;
  font-weight: normal;
`
const RichEditorStyles = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  .RichEditor-editor {
    cursor: text;
    font-size: 16px;

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
      margin: 0 -15px -15px;
      padding: 0 15px 15px;
    }

    .public-DraftEditor-content {
      min-height: 100px;
    }
  }

  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
`

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
]

const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  const Button = props => {
    const { style, onToggle, label } = props

    // Conditionally set the class so we can see active and not active buttons
    let className = 'RichEditor-styleButton'
    if (currentStyle.has(style)) {
      className += ' RichEditor-activeButton'
    }

    // This onToggle comes from the Textarea component and sets the editorState styles
    const handleMouseDown = e => {
      e.preventDefault()
      onToggle(style)
    }

    return (
      <span className={className} onMouseDown={handleMouseDown}>
        {label}
      </span>
    )
  }

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <Button key={type.style} onToggle={onToggle} {...type} />
      ))}
    </div>
  )
}

export default function Textarea(props) {
  const {
    name,
    validation = {},
    errorMessage,
    label,
    register,
    unregister,
    setValue,
    errors,
    asText,
    getDefault,
    description,
    plainText,
    componentIndex = 0,
    componentName = 'form',
    originName = name,
    initialValue = '',
  } = props

  /**
   * The default is a HTML string and thus we need to do some type conversions to get a working Editorstate.
   * We are not storing the paragraph tag that wraps our content because we wrap the HTML in our generator
   * already. Nesting paragraph tag's doesn't work as they will be misinterpreted by the browsers.
   **/
  const defaultValue = getDefault(name) || initialValue
  const withParagraphTag = `<p>${defaultValue || ''}</p>`
  const fromHTML = convertFromHTML(withParagraphTag)
  const DraftState = ContentState.createFromBlockArray(fromHTML.contentBlocks, fromHTML.entityMap)

  /**
   * Here we check if the saved default has any text in it and if not we create a fresh
   * editorState instance. This is because `createWithContent` can't accept empty HTML.
   **/
  const defaultContent = DraftState.getPlainText() ? EditorState.createWithContent(DraftState) : EditorState.createEmpty()
  const [editorState, setEditorState] = useState(defaultContent)
  const [charsCount, setCharsCount] = useState('')
  /**
   * Whenever our editorState changes we want to convert the state
   * to either HTML or plainText
   **/
  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)
    const markupWithoutParagraph = markup.slice(3, -5)
    const getText = getTextFromHtml(markupWithoutParagraph)
    const charAmount = getText.length
    const newValue = plainText ? getText : markupWithoutParagraph
    setValue(name, newValue)
    setCharsCount(charAmount > 0 ? `${charAmount} character${charAmount > 1 ? 's' : ''}` : '')
  }, [editorState])

  useEffect(() => {
    register(
      { name },
      {
        // Custom Validation because we store the HTML in our database but want to validate against plaintext
        validate: {
          maxLength: value => {
            if (!validation || !validation.maxLength || !value) return true
            return getTextFromHtml(value.trim()).length <= validation.maxLength
          },
          minLength: value => {
            if (!validation || !validation.minLength || !value) return true
            return getTextFromHtml(value.trim()).length >= validation.minLength
          },
          required: value => {
            if (!validation || !validation.required) return true
            if (!value) return false
            return Boolean(getTextFromHtml(value.trim()))
          },
        },
      }
    )
    setValue(name, defaultValue)
    setCharsCount('')
    return () => {
      unregister(name)
    }
  }, [])

  const toggleInlineStyle = inlineStyle => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const handleReturn = () => {
    setEditorState(RichUtils.insertSoftNewline(editorState))
    return 'handled'
  }

  return (
    <Form.Field
      error={!!errors[name]}
      id={`${componentIndex}-${componentName}-${originName}-text-wrapper`}
      style={{ position: 'relative' }}
    >
      <Label required={validation.required} text={label} description={description}>
        <CharsCount>{charsCount}</CharsCount>
      </Label>
      {!asText && (
        <RichEditorStyles>
          {!plainText && <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />}
          <div className="RichEditor-editor" id={`${componentIndex}-${componentName}-${originName}-textarea`}>
            <Editor editorState={editorState} handleReturn={handleReturn} onChange={setEditorState} />
          </div>
        </RichEditorStyles>
      )}
      {asText && <span dangerouslySetInnerHTML={{ __html: defaultValue }} />}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}
