import { useState, useEffect } from 'react'
import { batchFetchBriefings, fetchBriefing, subscribeBriefingChanged, unsubscribeBriefingChanged } from '../core/briefingCache'

export const useBriefingList = (userId, role) => {
  const [briefings, setBriefings] = useState(new Map())
  const [loading, setLoading] = useState(true)

  const initBriefings = async () => {
    setLoading(true)
    await batchFetchBriefings()
    setLoading(false)
  }

  useEffect(() => {
    if (!userId || !role) return

    // Re-fetch briefings when user or role changes
    setBriefings(new Map())
    initBriefings()
  }, [userId, role])

  const briefingsChangedCallback = updatedBriefingMap => {
    if (!updatedBriefingMap || updatedBriefingMap.length === 0) {
      return
    }

    const newBriefings = new Map([...briefings, ...updatedBriefingMap])

    newBriefings.forEach((briefing, key) => {
      if (briefing.isDeleted) {
        newBriefings.delete(key)
      }
    })

    setBriefings(newBriefings)
  }

  // Subscribe to changes in briefings to re-render views
  useEffect(() => {
    subscribeBriefingChanged(briefingsChangedCallback)
    return () => {
      unsubscribeBriefingChanged(briefingsChangedCallback)
    }
  }, [briefings])

  const refetchOne = async id => {
    setLoading(true)
    await fetchBriefing(id)
    setLoading(false)
  }

  return [briefings, loading, refetchOne, initBriefings]
}
