import React, { createContext } from 'react'
import { filterAccessibleComponents } from '@douglas/baas-shared'
import { groupBy } from 'lodash'
import { generateHash } from '../helpers/generateHash'
import { useUserContext } from './User'

export const ComponentContext = createContext()

export default function ComponentContextProvider({ components: componentsRaw, children }) {
  const { role } = useUserContext()

  componentsRaw = componentsRaw.map(c => ({ ...c, id: generateHash(6) }))

  const getComponentById = id => {
    const results = componentsRaw.filter(c => c.id === id)
    if (results.length !== 1) {
      return null
    } else {
      return results[0]
    }
  }

  const getComponentByName = name => {
    const results = componentsRaw.filter(c => c.metadata.name === name)
    if (results.length !== 1) {
      return null
    } else {
      return results[0]
    }
  }

  const getFilteredCategories = packageName => {
    const filteredComponents = componentsRaw.filter(
      c => c.metadata && c.metadata.package && c.metadata.package.includes(packageName)
    )
    return groupBy(filterAccessibleComponents(filteredComponents, role), c => c.metadata.category)
  }

  const components = {
    raw: componentsRaw,
    categories: groupBy(componentsRaw, c => c.metadata.category),
    getFilteredCategories,
    getComponentById,
    getComponentByName,
  }
  return <ComponentContext.Provider value={components}>{children}</ComponentContext.Provider>
}
