import React from 'react'
import styled from 'styled-components'
import { Tab } from 'semantic-ui-react'
import GettingStarted from './gettingStarted'
import UserRolesInfo from './UserRolesInfo'
import BrifingsInfo from './brifingsInfo'
import Layout from '../Layout'

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .ui.segment {
    padding: 0;
    border: none;
    box-shadow: none;
  }
`

const panes = [
  {
    menuItem: 'Getting Started',
    render: () => (
      <Tab.Pane attached={false}>
        <GettingStarted />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'User Roles Information',
    render: () => (
      <Tab.Pane attached={false}>
        <UserRolesInfo />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Briefing Information',
    render: () => (
      <Tab.Pane attached={false}>
        <BrifingsInfo />
      </Tab.Pane>
    ),
  },
]

export default function Faq() {
  return (
    <Layout showHr={true}>
      <Container>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Container>
    </Layout>
  )
}
