import React from 'react'
import ListItem from './ListItem'
import {
  ListButtonViewModules,
  ListButtonShowConfiguration,
  ListButtonEditLatestStep,
  ListButtonShowIncompletePreviewExternal,
  ListButtonPreviewNew,
} from './ListButton'
import { isBaas15Active } from '../../../helpers/baasHelper'

export default function ListItemOpen(props) {
  const { isLocked, validatedSuccessfully } = props
  return (
    <ListItem {...props}>
      {!isLocked ? (
        <React.Fragment>
          {!validatedSuccessfully && (
            <>
              {/* tobedeleted: old ListButtonShowIncompletePreviewExternal*/}
              {!isBaas15Active ? <ListButtonShowIncompletePreviewExternal {...props} /> : <ListButtonPreviewNew {...props} />}
            </>
          )}
          <ListButtonEditLatestStep {...props} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ListButtonViewModules {...props} />
          <ListButtonShowConfiguration {...props} />
        </React.Fragment>
      )}
    </ListItem>
  )
}
