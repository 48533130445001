import React, { useContext } from 'react'
import { usePost } from '../../../hooks/restHooks'
import { BriefingContext } from '../../../contexts/Briefings'
import BriefingForm from '../BriefingForm'
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'
 
export default function index(props) {
  const { loading, trigger, error } = usePost('briefing')
  const { fetchOne } = useContext(BriefingContext)

  const onSaveAndProceed = async data => {
    data.shortDescription = getTextFromHtml(data.description)

    const res = await trigger({ ...data })
    if (res.ok) {
      fetchOne(res._id)
      props.history.push(`/briefings/${res._id}/modules`)
    }
  }

  const onSaveForLater = async data => {
    data.shortDescription = getTextFromHtml(data.description)

    const res = await trigger({ ...data })
    if (res.ok) {
      fetchOne(res._id)
      props.history.push(`/briefings`)
    }
  }

  return (
    <BriefingForm
      onSaveAndProceed={onSaveAndProceed}
      onSaveForLater={onSaveForLater}
      loading={loading}
      error={error}
      title="Create Briefing"
      icon="add"
      match={props.match}
      {...props}
    />
  )
}
