import React, { useState } from 'react'
import { Button, Popup, Icon } from 'semantic-ui-react'
import ListItem from './ListItem'
import { patchBriefing } from '../../../core/briefingCache'
import { ListButtonShowPreview, ListButtonShowSourcePublish, ListButtonPreviewNew } from './ListButton'
import { isBaas15Active } from '../../../helpers/baasHelper'

export default function ListItemClosed(props) {
  const [loading, setLoading] = useState(false)
  const { _id, hideLeftButtons } = props

  const onNeedsReworkClicked = async () => {
    setLoading(true)
    await patchBriefing(_id, { status: 'rework', ymlContent: '', htmlContent: '', jsContent: '' })
    setLoading(false)
  }

  const leftButtons = (
    <React.Fragment>
      <Popup
        trigger={
          <Button icon labelPosition="left" onClick={onNeedsReworkClicked} loading={loading} disabled={loading}>
            <Icon name="close" />
            Needs rework
          </Button>
        }
        content="Found a defect? Move the briefing to 'needs rework' to enable editing of modules and configurations."
        inverted
      />
    </React.Fragment>
  )

  return (
    <ListItem leftButtons={hideLeftButtons ? null : leftButtons} {...props}>
      {/* tobedeleted: old ListButtonShowPreview*/}
      {!isBaas15Active ? <ListButtonShowPreview {...props} /> : <ListButtonPreviewNew {...props} />}
      <ListButtonShowSourcePublish {...props} />
    </ListItem>
  )
}
