import React from 'react'
import { Step } from 'semantic-ui-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useBriefing } from '../../hooks/useBriefing'
import CircleIcon from '../../components/CircleIcon'

const StepsWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  background: white;
  padding: 14px 0;

  .ui.steps,
  .ui.steps .step:after,
  .ui.steps .step {
    border-width: 0;
  }
  .ui.steps .step {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ui.steps .step.active,
  .ui.steps .step.active:hover {
    background: none;
  }

  .ui.steps .step:after {
    content: unset;
  }

  .ui.steps .step .title {
    text-transform: uppercase;
    font-size: 1em;
  }
`

export default function Steps({ match }) {
  const { mode, id } = match.params
  const [briefing] = useBriefing(id)
  const isDescActive = mode === 'edit' || mode === 'new' || mode === 'success'
  const isModulesActive = mode === 'modules'
  const isConfigActive = mode === 'configure'
  const isReviewActive = mode === 'review'

  return (
    <StepsWrapper>
      <Step.Group>
        <Step active={isDescActive} as={Link} to={id ? `/briefings/${id}/edit` : '#'} id="step-information">
          <CircleIcon active={isDescActive}>1</CircleIcon>
          <Step.Content>
            <Step.Title>DESCRIPTION</Step.Title>
          </Step.Content>
        </Step>

        <Step active={isModulesActive} disabled={!id} as={Link} to={id ? `/briefings/${id}/modules` : '#'} id="step-modules">
          <CircleIcon active={isModulesActive}>2</CircleIcon>
          <Step.Content>
            <Step.Title>Modules</Step.Title>
          </Step.Content>
        </Step>

        <Step
          active={isConfigActive}
          disabled={!id || !briefing || !briefing.hasElements}
          as={Link}
          to={id ? `/briefings/${id}/configure` : '#'}
          id="step-configure"
        >
          <CircleIcon active={isConfigActive}>3</CircleIcon>
          <Step.Content>
            <Step.Title>Configuration</Step.Title>
          </Step.Content>
        </Step>

        <Step
          active={isReviewActive}
          disabled={!id || !briefing || !briefing.validatedSuccessfully}
          as={Link}
          to={id ? `/briefings/${id}/review` : '#'}
          id="step-review"
        >
          <CircleIcon active={isReviewActive} disabled={!id || !briefing || !briefing.validatedSuccessfully}>
            4
          </CircleIcon>
          <Step.Content>
            <Step.Title>Order</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    </StepsWrapper>
  )
}
