import React from 'react'
import { Segment, Container } from 'semantic-ui-react'
import { useBriefing } from '../../../hooks/useBriefing'
import BriefingForm from '../BriefingForm'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import { patchBriefing } from '../../../core/briefingCache'
import { BlackHr } from '../../../components/BlackHr'
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'

export default function index({ match, history }) {
  const briefingId = match.params.id
  const [briefing, loading] = useBriefing(briefingId)

  if (loading || !briefing) {
    return <DelayedSpinner />
  }
  if (!briefing.isEditable) {
    return <p>Briefing is closed</p>
  }
  const onSaveForLater = async data => {
    data.shortDescription = getTextFromHtml(data.description)

    patchBriefing(briefingId, { ...data, validatedSuccessfully: false })
    history.push(`/briefings`)
  }

  const onSaveAndProceed = async data => {
    data.shortDescription = getTextFromHtml(data.description)

    patchBriefing(briefingId, { ...data, validatedSuccessfully: false })
    history.push(`/briefings/${briefingId}/modules`)
  }

  return (
    <React.Fragment>
      {briefing.isLocked && <BlackHr />}

      {!briefing.isLocked && !loading && (
        <BriefingForm
          onSaveForLater={onSaveForLater}
          onSaveAndProceed={onSaveAndProceed}
          loading={loading}
          initialValues={briefing ? briefing : {}}
          editMode={true}
          title="Edit Briefing"
          icon="edit"
          match={match}
          briefingId={briefingId}
        />
      )}

      {briefing.isLocked && !loading && (
        <Container>
          <Segment raised style={{ marginBottom: '2rem', padding: '2rem' }}>
            <BriefingForm
              loading={loading}
              initialValues={briefing ? briefing : {}}
              editMode={true}
              asText={true}
              title="Briefing Information (locked, can only be changed by an admin)"
              icon="edit"
              briefingId={briefingId}
            />
          </Segment>
        </Container>
      )}
    </React.Fragment>
  )
}
