import React, { useState } from 'react'
import styled from 'styled-components'
import { Table, Loader, Checkbox, Button, Select } from 'semantic-ui-react'
import Layout from '../../Layout'
import { useGet } from '../../../hooks/restHooks'

import PublishItem from './PublishItem'

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  td.locked {
    opacity: 0.4;
  }

  td div.ui.message.publish_info {
    display: none;
  }

  tr.locked td.edit {
    opacity: 1;
  }

  .hidden {
    visibility: hidden;
  }
`

export default function Copy() {
  const { data, loading, refetch } = useGet(`admin/migrate`)
  const [copiing, setCopiing] = useState(false)
  const [hidePublished, setHidePublished] = useState(false)
  const [selectedTypes, setSelectedTypes] = useState('')
  const [copyAll, setCopyAll] = useState(false)
  const briefings = data || []
  const types = [
    { text: 'All', value: '' },
    { text: 'pop', value: 'pop' },
    { text: 'pop_cp', value: 'pop_cp' },
    { text: 'pdp', value: 'pdp' },
    { text: 'promos', value: 'promos' },
    { text: 'contentpages', value: 'contentpages' },
  ]

  const toggle = () => {
    setHidePublished(!hidePublished)
  }

  const onChangeType = (_, f) => {
    setSelectedTypes(f.value)
  }

  const copyAllClick = () => {
    setCopyAll(true)
  }

  if (loading) {
    return <Loader active inline="centered" />
  }

  let itemToCopyFound = false
  let copyMe = false
  //const itemsLeftToCopy = data.some(item => !item.copied)
  const itemsLeftToCopy = false
  return (
    <Layout showHr={false} style={{ paddingBottom: '1rem' }}>
      {false && (
        <Checkbox toggle label="Hide published" checked={hidePublished} onClick={toggle} style={{ paddingBottom: '1rem' }} />
      )}
      <Select
        label="Pagetype"
        name="pagetype"
        options={types}
        selectedvalue={selectedTypes}
        onChange={onChangeType}
        placeholder="Pagetype filter"
      />
      <br />
      {itemsLeftToCopy && <Button onClick={copyAllClick}>copy All</Button>}
      <br />
      <h3>Only not yet published briefings are shown</h3>
      <Container>
        <Table celled striped style={{ marginBottom: '3rem' }}>
          <Table.Body>
            <Table.Row active>
              <Table.Cell style={{ textAlign: 'right' }}>
                <b>ID</b>
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'left' }}>
                <b>Tenant</b>
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'left' }}>
                <b>Title</b>
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'left' }}>
                <b>Type</b>
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'left' }}>
                <b>Briefing Status</b>
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'left' }}>
                <b>Action</b>
              </Table.Cell>
            </Table.Row>

            {briefings.map(element => {
              if (hidePublished && element.publishedToHybris) {
                return ''
              }
              if (selectedTypes !== '' && element.type !== selectedTypes) {
                return ''
              }
              if (copyAll && !itemToCopyFound && !element.migrated) {
                copyMe = true
                itemToCopyFound = true
              } else {
                copyMe = false
              }
              return (
                <PublishItem
                  element={element}
                  key={element._id}
                  setCopiing={setCopiing}
                  copiing={copiing}
                  refetch={refetch}
                  copyMe={copyMe}
                />
              )
            })}
          </Table.Body>
        </Table>
      </Container>
    </Layout>
  )
}
