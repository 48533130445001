import React from 'react'
import styled from 'styled-components'
import { Header, Icon } from 'semantic-ui-react'

const ListHeaderWrapper = styled.div`
  & .list-header-icon {
    font-size: 1em !important;
  }

  & .list-header-content {
    padding-left: 0.35em !important;
  }

  & {
    height: ${props => (props.subheader ? '50px' : '30px')};
    width: 100%;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  }

  & .text {
    float: left;
  }

  & .searchbar {
    float: right;
    margin-top: -7px;
  }
`
export default function HeaderHr({ icon, title, style, subheader = false }) {
  return (
    <ListHeaderWrapper style={style} subheader={subheader}>
      <Header as="h3" className="text">
        <Icon className="list-header-icon" name={icon} size="mini" />
        <Header.Content className="list-header-content">
          {title} {subheader && <Header.Subheader>{subheader}</Header.Subheader>}
        </Header.Content>
      </Header>
    </ListHeaderWrapper>
  )
}
