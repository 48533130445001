import React from 'react'
import { Segment, Container } from 'semantic-ui-react'
import Checkout from '../Configure/index'
import BriefingForm from '../../BriefingForm'
import { useBriefing } from '../../../../hooks/useBriefing'
import Actionbar from './Actionbar'
import ActionbarView from '../../ActionbarView'
import DelayedSpinner from '../../../../components/semantic/DelayedSpinner'

export default function index(props) {
  const briefingId = props.match.params.id
  const [briefing, loading] = useBriefing(briefingId)

  if (loading || !briefing) {
    return <DelayedSpinner />
  }

  return (
    <React.Fragment>
      {props.view && <ActionbarView active="configure" briefing={briefing} loading={loading} {...props} />}
      {!props.view && <Actionbar briefing={briefing} {...props} />}

      <Container>
        <Segment raised style={{ marginBottom: '2rem', padding: '2rem' }}>
          <BriefingForm
            loading={loading}
            initialValues={briefing ? briefing : {}}
            editMode={true}
            asText={true}
            title="Review your briefing"
            icon="edit"
            briefingId={briefingId}
          />
        </Segment>
      </Container>
      <Checkout step="review" {...props} />
    </React.Fragment>
  )
}
