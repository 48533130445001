import React, { useEffect, memo, useState } from 'react'
import { Table, Icon, Button, Modal } from 'semantic-ui-react'
import { ROLE, ROLE_PROPS, countryOptions } from '@douglas/baas-shared'
import { Input, Select } from '../../../components/semantic/Form'
import { usePost } from '../../../hooks/restHooks'
import { useForm } from '../../../hooks/useForm'

const USER_ROLES = Object.keys(ROLE).map(role => ({
  key: ROLE[role],
  value: ROLE[role],
  text: ROLE_PROPS[ROLE[role]].label,
}))

const AddUserForm = ({ userEdit, onUpdate, onCreate }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { trigger: update, error: updateError } = usePost('user/patch')
  const { trigger: create, error: createError } = usePost('user')
  const { handleSubmit, ...formControls } = useForm()

  const onCreateUser = async data => {
    const res = await create(data)

    onCreate(res)
  }

  const onUpdateUser = async data => {
    const res = await update({
      data,
      userId: userEdit.userId,
    })

    onUpdate(res)
  }

  useEffect(() => {
    if (updateError || createError) {
      setModalOpen(true)
    }
  }, [createError, updateError])

  return (
    <>
      <Table.Row active>
        <Table.Cell width="7">
          <Input
            validation={{ pattern: /(.+)@(.+){2,}\.(.+){2,}/ }}
            initialValue={userEdit.email}
            errorMessage="Please enter a valid email address."
            label="E-Mail"
            name="email"
            {...formControls}
          />
        </Table.Cell>
        <Table.Cell width="4">
          <Select
            validation={{ required: true }}
            errorMessage="Please select the country"
            label="Country"
            name="country"
            options={countryOptions}
            multiple={false}
            default={userEdit.country}
            disabled={false}
            {...formControls}
          />
        </Table.Cell>
        <Table.Cell width="4">
          <Select
            validation={{ required: true }}
            errorMessage="User role"
            label="User role"
            name="role"
            options={USER_ROLES}
            default={parseInt(userEdit.role)}
            multiple={false}
            disabled={false}
            {...formControls}
          />
        </Table.Cell>
        <Table.Cell collapsing className="edit" width="1">
          <Button primary floated="right" onClick={handleSubmit(userEdit ? onUpdateUser : onCreateUser)}>
            <Icon name={userEdit ? 'save' : 'user add'} />
            {userEdit ? 'Update' : 'Add'}
          </Button>
        </Table.Cell>
      </Table.Row>
      <Modal size="small" open={modalOpen} onClose={() => setModalOpen(true)}>
        <Modal.Header>Server Error</Modal.Header>
        <Modal.Content>
          <p>An error occurred while trying to update the data. Please try again later.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => setModalOpen(false)}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default memo(AddUserForm)
