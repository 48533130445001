import React from 'react'

export const NewshopSVGs = () => {
  return (
    <svg style={{ display: 'none' }}>
      <symbol viewBox="0 0 10 10" id="SVG_10--Rating_Star_000" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#e6e6e6"
          d="M10 3.824l-2.5-.38h-.005l-.955-.146L5 0h-.005v.01L3.46 3.298l-.96.146-2.5.38 2.5 2.563L1.9 10l.6-.33L4.995 8.3 5 8.299l2.495 1.37.005.003.6.329-.6-3.613z"
        />
      </symbol>
      <symbol viewBox="0 0 10 10" id="SVG_10--Rating_Star_025" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 3.444l-2.5.38 2.5 2.563z" className="abst1" />
        <path d="M2.5 6.387L1.9 10l.6-.33z" className="abst1" />
        <path
          fill="#c3eae4"
          d="M10 3.824l-2.5-.38h-.005l-.955-.146L5 0h-.005v.01L3.46 3.298l-.96.146v6.227L4.995 8.3 5 8.298l2.495 1.37.005.003.6.329-.6-3.613z"
        />
      </symbol>
      <symbol viewBox="0 0 10 10" id="SVG_10--Rating_Star_050" xmlns="http://www.w3.org/2000/svg">
        <path fill="#3cbeaf" d="M4.995 0v.01L3.46 3.298l-.96.146-2.5.38 2.5 2.563L1.9 10l.6-.33L4.995 8.3 5 8.299V0z" />
        <path fill="#c3eae4" d="M10 3.824l-2.5-.38h-.005l-.955-.146L5 0v8.298l2.495 1.37.005.003.6.329-.6-3.613z" />
      </symbol>
      <symbol viewBox="0 0 10 10" id="SVG_10--Rating_Star_075" xmlns="http://www.w3.org/2000/svg">
        <path fill="#c3eae4" d="M10 3.824l-2.5-.38h-.005v6.224l.005.003.6.329-.6-3.613z" />
        <path
          fill="#3cbeaf"
          d="M6.54 3.298L5 0h-.005v.01L3.46 3.298l-.96.146-2.5.38 2.5 2.563L1.9 10l.6-.33L4.995 8.3 5 8.299l2.495 1.37V3.443z"
        />
      </symbol>
      <symbol viewBox="0 0 10 10" id="SVG_10--Rating_Star_100" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#3cbeaf"
          d="M10 3.824l-2.5-.38h-.005l-.955-.146L5 0h-.005v.01L3.46 3.298l-.96.146-2.5.38 2.5 2.563L1.9 10l.6-.33L4.995 8.3 5 8.299l2.495 1.37.005.003.6.329-.6-3.613z"
        />
      </symbol>
    </svg>
  )
}
