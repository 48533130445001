import React from 'react'
import { Wysiwyg } from '../../../../components/semantic/Form'

const WysiwygTest = props => {
  if (props.validation && props.validation.pattern) {
    // Instantiating RegExp object, because react-hook-form relies on that for pattern validation.
    props.validation.pattern = new RegExp(props.validation.pattern)
  }
  return <Wysiwyg {...props} />
}

export default WysiwygTest
