import React, { useState } from 'react'
import styled from 'styled-components'
import { Table, Input, Button } from 'semantic-ui-react'
import Layout from '../../Layout'
import { usePost } from '../../../hooks/restHooks'
import HeaderHr from '../../../components/semantic/HeaderHr'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  td.locked {
    opacity: 0.4;
  }

  tr.locked td.edit {
    opacity: 1;
  }

  .hidden {
    visibility: hidden;
  }
`

export default function Products() {
  const [products, setProducts] = useState('3001002972, 5009766084')
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { trigger: findProducts } = usePost('admin/products/find')

  if (loading) {
    return <DelayedSpinner />
  }

  const handleProducts = event => {
    setProducts(event.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    const res = await findProducts({
      products,
    })
    setData(res)
    setLoading(false)
  }

  return (
    <Layout showHr={false} style={{ paddingBottom: '1rem' }}>
      <Container>
        <HeaderHr style={{ marginBottom: '1rem', marginTop: '1rem' }} title="Product search" icon={'search'} />
        <Input
          focus
          value={products}
          onChange={handleProducts}
          placeholder="Enter product-ids (comma deperated)"
          style={{ width: '500px' }}
        />{' '}
        <Button onClick={handleSearch}>Search</Button>
        {data && (
          <Table celled striped style={{ marginBottom: '3rem' }}>
            <Table.Body>
              <ModuleTable briefings={data} />
            </Table.Body>
          </Table>
        )}
      </Container>
    </Layout>
  )
}

function formatDate(d) {
  const event = new Date(d)
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }

  return event.toLocaleDateString('de-DE', options)
}

function ModuleTable(props) {
  return (
    <>
      <Table.Row active>
        <Table.Cell style={{ textAlign: 'right' }}>
          <b>title</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'right' }}>
          <b>identifier</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>country</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>status</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>type</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>created</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>updated</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>id</b>
        </Table.Cell>
      </Table.Row>
      {props.briefings.briefings.map(b => (
        <Table.Row key={b.identifier}>
          <Table.Cell style={{ textAlign: 'left' }}>{b.title}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{b.identifier}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{b.country}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{b.status}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{b.type}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{formatDate(b.created)}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{formatDate(b.updated)}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>
            <a target="_blank" href={`/briefings/${b.id}/edit`} rel="noreferrer">
              {b.id}
            </a>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  )
}

/*
created: "2021-10-05T07:52:53.004Z"
id: "615c0454ff6c4e00ec725da3"
updated: "2021-10-05T07:53:36.648Z"
*/
