import React, { useState } from 'react'
import { Menu, Button, Icon, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fetchBriefing, patchBriefing } from '../../../../core/briefingCache'
import { ListButtonPreviewNew } from '../../Overview/ListButton'
import { isBaas15Active } from '../../../../helpers/baasHelper'

const TipWrapper = styled.div`
  margin: 0 auto;
`

const CenterHelper = styled.div`
  width: 460px;
  @media (max-width: 1800px) {
    & {
      width: 100px;
    }
  }
  @media (max-width: 1500px) {
    & {
      width: 0px;
    }
  }
`
export default function Actionbar({ loading, match, briefing }) {
  const [loadingState, setLoadingState] = useState(false)
  const briefingId = match.params.id

  const onNeedsReworkClicked = async () => {
    setLoadingState(true)
    await patchBriefing(briefingId, { status: 'rework' })
    fetchBriefing(briefingId)
    setLoadingState(false)
  }

  return (
    <Menu inverted style={{ height: '2em', minHeight: 'auto', margin: '0' }} className="action-bar-sticky">
      <Menu.Menu position="right">
        <Menu.Item>
          <CenterHelper />
        </Menu.Item>
      </Menu.Menu>
      <Menu.Item style={{ flexGrow: '1' }}>
        <TipWrapper>
          <Icon name="arrow up" />
          Something wrong with your briefing? You can always go back by clicking on the steps above.
          <Icon name="arrow up" />
        </TipWrapper>
      </Menu.Item>

      <Menu.Menu position="right">
        {briefing.validatedSuccessfully && (
          <>
            {!isBaas15Active ? (
              <Menu.Item style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}>
                <Button
                  style={{ height: '2em', paddingTop: '7px' }}
                  secondary
                  icon
                  labelPosition="left"
                  as={Link}
                  to={`/preview/${briefingId}`}
                  target="_blank"
                  id="preview-button"
                >
                  <Icon name="eye" />
                  Preview
                </Button>
              </Menu.Item>
            ) : (
              <Menu.Item style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}>
                <ListButtonPreviewNew {...briefing} asLink label={'Preview'} />
              </Menu.Item>
            )}
          </>
        )}
        <Menu.Item style={{ borderRight: '1px solid white' }}>
          <Button
            style={{ height: '2em', paddingTop: '7px' }}
            secondary
            icon
            disabled={loading}
            labelPosition="left"
            as={Link}
            to="/briefings"
            id="save-later-button"
          >
            <Icon name="save" />
            Save for later
          </Button>
        </Menu.Item>

        {['open', 'rework'].includes(briefing.status) && briefing.validatedSuccessfully && (
          <Menu.Item style={{ paddingLeft: '0', paddingRight: '0' }}>
            <Popup
              trigger={
                <Button
                  style={{
                    height: '2em',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    borderRadius: '0',
                    borderRight: '1px solid white',
                  }}
                  primary
                  icon
                  disabled={loading && loadingState}
                  labelPosition="left"
                  to={`/briefings/${briefingId}/review/request-review`}
                  as={Link}
                  id="request-review-button"
                >
                  <Icon name="angle double right" />
                  Request review
                </Button>
              }
              content="Request a review for your briefing."
              inverted
              position="bottom right"
            />
          </Menu.Item>
        )}

        {briefing.status === 'review' && (
          <Menu.Item style={{ paddingLeft: '0', paddingRight: '0' }}>
            <Button
              style={{ height: '2em', borderRadius: '0', borderRight: '1px solid white', paddingTop: '0', paddingBottom: '0' }}
              primary
              icon
              loading={loadingState}
              disabled={loading}
              labelPosition="left"
              onClick={onNeedsReworkClicked}
              id="needs-rework-button"
            >
              <Icon name="angle double right" />
              Needs rework
            </Button>
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu>
  )
}
