import React, { memo, useContext, useState } from 'react'
import { Message, Form, Modal, Button, Icon } from 'semantic-ui-react'
import { countryOptions as countryOptionsRaw, languageOptionsRaw } from '@douglas/baas-shared'
import { useForm } from '../../../hooks/useForm'
import { useBriefing } from '../../../hooks/useBriefing'
import { Textarea, Input, Select } from '../../../components/semantic/Form'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import { usePost } from '../../../hooks/restHooks'
import { setBriefing } from '../../../core/briefingCache'
import DatePicker from '../../../components/semantic/Form/DatePicker'
import Checkbox from '../../../components/semantic/Form/Checkbox'
import { UserContext } from '../../../contexts/User'
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'

const textResetOptions = [
  { key: 'yes', value: 'yes', text: 'Yes, reset all module text fields.' },
  { key: 'no', value: 'no', text: 'No, keep all module texts from the parent briefing.' },
]

function index(props) {
  const { briefingId, closeOverlay } = props
  const [briefing, loading] = useBriefing(briefingId)
  const { handleSubmit, ...formControls } = useForm()
  const { loadingCopy, trigger } = usePost('briefing/copy')
  const { fingerprint } = useContext(UserContext)
  const [selectedCountry, setSelectedCountry] = useState('')

  if (loading || !briefing) {
    return <DelayedSpinner />
  }

  let countryOptions = countryOptionsRaw

  const onSubmit = async data => {
    data.shortDescription = getTextFromHtml(data.description)

    const { briefing } = await trigger({ _id: briefingId, ...data, owner: fingerprint })
    setBriefing(briefing._id, briefing)
    closeOverlay()
  }

  return (
    <React.Fragment>
      <Modal.Content>
        <Message>
          You can copy a briefing to adapt it to a new language or campaign.
          <br /> For the new copy, the title and description <b>can</b> be changed. The list of countries <b>should</b> be
          changed. The briefing will also be marked as a copy automatically and will be initialized with the status <b>open</b>.
        </Message>
        <Form loading={loadingCopy}>
          <Select
            validation={{ required: true }}
            errorMessage="Please decide on an option!"
            label="Reset module text fields"
            name="resetTexts"
            options={textResetOptions}
            {...formControls}
          />
          <Input
            validation={{ required: true, minLength: 5, maxLength: 50 }}
            errorMessage="Please enter a title with between 5 and 50 characters."
            label="Title"
            name="title"
            initialValue={briefing.title}
            {...formControls}
          />
          <Textarea
            validation={{}}
            errorMessage="Please enter a description with between 5 and 500 characters."
            label="Description"
            name="description"
            initialValue={briefing.description}
            {...formControls}
          />
          <Input
            validation={{}}
            errorMessage=""
            label={briefing.type === 'pdp' ? 'Product ID' : 'Page ID'}
            name="identifier"
            initialValue={briefing.identifier}
            {...formControls}
          />
          <Form.Group widths="equal">
            <DatePicker
              validation={{ required: true }}
              errorMessage="Please enter a valid date."
              label="Due date go live"
              name="dueDateGolive"
              initialValue={new Date(briefing.dueDateGolive)}
              {...formControls}
            />
            <Select
              validation={{ required: true }}
              errorMessage="Please pick a country"
              label="Countries"
              name="country"
              options={countryOptions}
              search={true}
              hookIntoValueState={setSelectedCountry}
              {...formControls}
            />
            {selectedCountry !== '' && (
              <Select
                validation={{ required: true }}
                errorMessage="Please pick a language"
                label="Language"
                name="language"
                options={languageOptionsRaw.filter(lang =>
                  countryOptionsRaw.find(c => c.value === selectedCountry).lang.includes(lang.key)
                )}
                search={true}
                {...formControls}
              />
            )}
          </Form.Group>

          <Checkbox
            label="Lock briefing "
            name="isLocked"
            {...formControls}
            initialValue={briefing.isLocked}
            id="lock-briefing-checkbox"
          />
          <p style={{ marginTop: '-15px' }}>Details and module config can't be changed anymore, module details can</p>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button loading={loading} primary icon labelPosition="left" onClick={handleSubmit(onSubmit)} id="ok-button">
          <Icon name="fork" />
          Create a copy now
        </Button>
        <Button secondary icon labelPosition="right" onClick={closeOverlay} id="cancel-button">
          <Icon name="close" />
          Cancel
        </Button>
      </Modal.Actions>
    </React.Fragment>
  )
}

export default memo(index)
