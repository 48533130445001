import React, { memo, useState } from 'react'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import { FormInlineError } from './FormInlineError'
import { Label } from './sharedComponents'

const CharsCount = styled.span`
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 0;
  font-weight: normal;
`

function Input(props) {
  const {
    name,
    validation = {},
    errorMessage,
    label,
    register,
    errors,
    asText,
    getDefault,
    onChange = input => countChars(input),
    componentIndex = 0,
    componentName = 'form',
    originName = name,
    disabled = false,
    initialValue = '',
  } = props

  const defaultValue = getDefault(name) || initialValue

  let required
  if (validation) {
    required = validation.required
  }
  const [charsCount, setCharsCount] = useState('')
  const countChars = input => {
    const charAmount = input.value.length
    setCharsCount(charAmount > 0 ? `${charAmount} character${charAmount > 1 ? 's' : ''}` : '')
  }

  return (
    <Form.Field
      error={Boolean(errors[name])}
      id={`${componentIndex}-${componentName}-${originName}-text-wrapper`}
      style={{ position: 'relative' }}
    >
      <Label required={required} text={label} description={props.description}>
        <CharsCount>{charsCount}</CharsCount>
      </Label>
      {!asText && (
        <input
          defaultValue={defaultValue}
          name={name}
          onChange={e => onChange(e.target)}
          ref={register(validation)}
          id={`${componentIndex}-${componentName}-${originName}-text`}
          disabled={disabled}
        />
      )}
      {asText && <p>{defaultValue}</p>}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}

export default memo(Input)
