import React, { memo, useState } from 'react'
import { hasAccess, FEATURE } from '@douglas/baas-shared'
import { Button, Icon } from 'semantic-ui-react'
import ListItem from './ListItem'
import { ListButtonShowPreview, ListButtonViewConfiguration, ListButtonEditBriefing, ListButtonPreviewNew } from './ListButton'
import { useUserContext } from '../../../contexts/User'
import { patchBriefing } from '../../../core/briefingCache'
import { isBaas15Active } from '../../../helpers/baasHelper'

function ListItemReview(props) {
  const { _id, country, type, hideLeftButtons } = props
  const { role, country: userCountry } = useUserContext()
  const [loading, setLoading] = useState(false)

  const isSameCountry = country === userCountry
  const canFinalizePromo = hasAccess(role, FEATURE.finalize_promos, isSameCountry)
  const canFinalizeOther = hasAccess(role, FEATURE.finalize, isSameCountry)
  const canFinalize = canFinalizeOther || (type === 'promos' && canFinalizePromo)

  const onNeedsReworkClicked = async () => {
    setLoading(true)
    await patchBriefing(_id, { status: 'rework' })
    setLoading(false)
  }

  const onFinalizeClicked = async () => {
    setLoading(true)
    await patchBriefing(_id, { status: 'ready' })
    setLoading(false)
  }

  const leftButtons = (
    <React.Fragment>
      {canFinalize && (
        <>
          <Button icon labelPosition="left" onClick={onNeedsReworkClicked} disabled={loading}>
            <Icon name="close" />
            Needs rework
          </Button>

          <Button icon labelPosition="left" onClick={onFinalizeClicked} disabled={loading}>
            <Icon name="check" />
            Finalize
          </Button>
        </>
      )}
    </React.Fragment>
  )

  return (
    <ListItem leftButtons={hideLeftButtons ? null : leftButtons} {...props}>
      {!canFinalize && <ListButtonViewConfiguration {...props} />}
      {canFinalize && <ListButtonEditBriefing {...props} />}
      {/* tobedeleted: old ListButtonShowPreview*/}
      {!isBaas15Active ? <ListButtonShowPreview {...props} /> : <ListButtonPreviewNew {...props} />}
    </ListItem>
  )
}

export default memo(ListItemReview)
