import React, { useState } from 'react'
import { Accordion, Icon, GridRow, GridColumn, Grid } from 'semantic-ui-react'
import { DatePicker } from '../../../../components/semantic/Form'
import Checkbox from '../../../../components/semantic/Form/Checkbox'
import { hasConfigRestriction } from '../../../../helpers/baasHelper'

const dot = require('dot-object')

export const DateInput = props => {
  const { name, asText = false } = props
  const restrictionsHeadline = 'Restrictions'

  const hasRestriction = () => {
    let flatted = dot.dot({
      config: props.getValues(),
    })
    let flattedString = JSON.stringify(flatted, null, 2)

    flatted = JSON.parse(flattedString)
    dot.object(flatted)
    const n = name.replace('.visibility', '')
    if (!flatted.config[n] || !flatted.config[n].visibility) {
      return false
    }

    return hasConfigRestriction(flatted.config[n])
  }
  const [open, setOpen] = useState(hasRestriction())

  return (
    <div style={{ marginTop: '1em' }}>
      <Accordion fluid>
        <Accordion.Title
          active={open || asText}
          index={0}
          onClick={() => setOpen(!open)}
          className="test-anchor-accordion-button"
        >
          {asText ? (
            <b>{restrictionsHeadline}</b>
          ) : (
            <>
              <Icon name="dropdown" />
              {restrictionsHeadline}
            </>
          )}
        </Accordion.Title>
        <Accordion.Content active={open}>
          <Grid columns={2}>
            <GridRow>
              <GridColumn>
                <DatePicker
                  showTimeSelect
                  errorMessage="Please enter a valid date."
                  label="Visible from"
                  disabled={asText}
                  {...props}
                  name={props.name + '.start'}
                  dateFormat={'dd.MM.yyyy H:mm'}
                  initialValue=""
                  asText={false}
                />
              </GridColumn>
              <GridColumn>
                <DatePicker
                  showTimeSelect
                  errorMessage="Please enter a valid date."
                  label="Visible till"
                  disabled={asText}
                  {...props}
                  name={props.name + '.end'}
                  dateFormat={'dd.MM.yyyy H:mm'}
                  initialValue=""
                  asText={false}
                />
              </GridColumn>
            </GridRow>
          </Grid>

          <Grid columns={4}>
            <GridRow>
              <GridColumn>
                <Checkbox label="Hide App" asText={false} {...props} name={props.name + '.hideApp'} />
              </GridColumn>
              <GridColumn>
                <Checkbox label="Hide S-view" asText={false} {...props} name={props.name + '.hideS'} />
              </GridColumn>
              <GridColumn>
                <Checkbox label="Hide M-view" asText={false} {...props} name={props.name + '.hideM'} />
              </GridColumn>
              <GridColumn>
                <Checkbox label="Hide L-view" asText={false} {...props} name={props.name + '.hideL'} />
              </GridColumn>
            </GridRow>
          </Grid>
        </Accordion.Content>
      </Accordion>
    </div>
  )
}
