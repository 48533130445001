import React from 'react'
import { Menu, Input, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import BriefingInformation from '../Overview/BriefingInformation'

export default function Actionbar(props) {
  const { searchterm, setSearchterm, briefingId, isValid } = props
  let resetAction = false
  if (searchterm !== '') {
    resetAction = {
      color: 'primary',
      labelPosition: 'left',
      icon: 'undo',
      content: 'Reset',
      onClick: () => {
        setSearchterm('')
      },
    }
  }

  return (
    <Menu className="action-bar-sticky" inverted style={{ height: '2em', minHeight: 'auto', margin: '0' }}>
      <Menu.Menu position="left" style={{ width: 'calc(100% / 5 * 1)' }}>
        <Menu.Item style={{ width: '100%', paddingLeft: '0', paddingRight: '0' }}>
          <Input
            className='input--no-border'
            action={resetAction}
            icon={'search'}
            value={searchterm}
            placeholder="Search..."
            iconPosition="left"
            onChange={e => {
              setSearchterm(e.target.value)
            }}
          />
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu style={{ flexGrow: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        {briefingId && <BriefingInformation briefingId={briefingId} />}
      </Menu.Menu>
      <Menu.Menu position="right">
        <Menu.Item style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}>
          <Button style={{ paddingTop: '0', paddingBottom:'0'}} secondary icon as={Link} to={`/briefings`} labelPosition="left" id="save-later-button">
            <Icon name="save" />
            Save for later
          </Button>
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: '0', paddingRight: '0' }}>
          <Button
            style={{ height: '2em', paddingTop: '7px', paddingBottom:'7px', borderRadius: '0', borderRight: '1px solid white'}}
            primary
            icon
            as={Link}
            disabled={!isValid}
            to={`/briefings/${briefingId}/configure`}
            labelPosition="left"
            id="save-proceed-button"
            size="mini"
          >
            <Icon name="angle double right" />
            Save & proceed to configuration
          </Button>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}
