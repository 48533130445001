import React from 'react'
import { Button, Popup, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { isBaas15Active } from '../../../helpers/baasHelper'

const ListButtonPreviewNew = props => {
  if (props.asLink) {
    return (
      <Button
        onClick={props.onClick}
        style={{ height: '2em', paddingTop: '7px' }}
        secondary
        icon
        labelPosition="left"
        as={Link}
        to={`/briefings/${props._id}/view/preview`}
        id="preview-button"
      >
        <Icon name="eye" />
        {props.label}
      </Button>
    )
  }
  return (
    <Popup
      trigger={
        <Button
          onClick={props.onClick}
          primary
          as={Link}
          to={`/briefings/${props._id}/view/preview`}
          icon="eye"
          className="test-action-preview-button"
        />
      }
      content="Show the preview of this briefing."
      inverted
    />
  )
}

const ListButtonShowPreview = props => {
  return (
    <Popup
      trigger={
        <Button primary as={Link} to={`/briefings/${props._id}/view/preview`} icon="eye" className="test-action-preview-button" />
      }
      content="Show the preview of this briefing."
      inverted
    />
  )
}

const ListButtonShowPreviewExternal = props => {
  return (
    <Popup
      trigger={
        <Button
          primary
          as={Link}
          to={`/preview/${props._id}`}
          target="_blank"
          icon="eye"
          className="test-action-preview-external-button"
        />
      }
      content="Show the preview of this briefing."
      inverted
    />
  )
}

const ListButtonShowIncompletePreviewExternal = props => {
  return (
    <Popup
      trigger={
        <Button
          primary
          as={Link}
          to={`/incompletePreview/${props._id}`}
          target="_blank"
          icon="eye"
          className="test-action-preview-external-button"
        />
      }
      content="Show the preview of this (incomplete) briefing."
      inverted
    />
  )
}

const ListButtonShowSource = props => {
  return (
    <Popup
      trigger={
        <Button primary as={Link} to={`/briefings/${props._id}/view/code`} icon="code" className="test-action-source-button" />
      }
      content="(For developers) show config file for implementation."
      inverted
    />
  )
}

const ListButtonShowSourcePublish = props => {
  return (
    <Popup
      trigger={
        <Button
          primary={!props.publishedToHybris}
          secondary={props.publishedToHybris}
          as={Link}
          to={`/briefings/${props._id}/view/code`}
          icon
          labelPosition="left"
          className="test-action-publish-button"
        >
          <Icon name="code" />
          {props.publishedToHybris ? 'Republish' : 'Publish'}
        </Button>
      }
      content={props.publishedToHybris ? 'Republish to Hybris' : 'Publish to Hybris'}
      inverted
    />
  )
}

const ListButtonShowConfiguration = props => {
  return (
    <Popup
      trigger={
        <Button
          primary
          as={Link}
          to={`/briefings/${props._id}/configure`}
          icon="configure"
          className="test-action-configure-button"
        />
      }
      content="Fill out the briefing details."
      inverted
    />
  )
}

const ListButtonShowReview = props => {
  return (
    <Popup
      trigger={
        <Button primary as={Link} to={`/briefings/${props._id}/review`} icon="shop" className="test-action-review-button" />
      }
      content="Show the review of the briefing details."
      inverted
    />
  )
}

const ListButtonViewConfiguration = props => {
  return (
    <Popup
      trigger={
        <Button
          primary
          as={Link}
          to={`/briefings/${props._id}/view`}
          icon="configure"
          className="test-action-view-configure-button"
        />
      }
      content="Show the module configuration of this briefing."
      inverted
    />
  )
}

const ListButtonPuzzleModules = props => {
  return (
    <Popup
      trigger={
        <Button
          primary
          as={Link}
          to={`/briefings/${props._id}/modules`}
          icon="puzzle piece"
          className="test-action-modules-button"
        />
      }
      content="Puzzle and configure the content modules of this briefing."
      inverted
    />
  )
}

const ListButtonEditBriefing = props => {
  return (
    <Popup
      trigger={<Button primary as={Link} to={`/briefings/${props._id}/edit`} icon="pencil" className="test-action-edit-button" />}
      content="Edit this briefing."
      inverted
    />
  )
}

const ListButtonViewModules = props => {
  return (
    <Popup
      trigger={
        <Button
          primary
          as={Link}
          to={`/briefings/${props._id}/view/modules`}
          icon="puzzle piece"
          className="test-action-view-modules-button"
        />
      }
      content="Show the content modules of this briefing."
      inverted
    />
  )
}

const ListButtonEditLatestStep = props => {
  if (props.validatedSuccessfully) {
    return (
      <React.Fragment>
        {/* tobedeleted: old ListButtonShowIncompletePreviewExternal*/}
        {!isBaas15Active && <ListButtonShowPreviewExternal {...props} />}
        {isBaas15Active && <ListButtonPreviewNew {...props} />}
        <ListButtonShowReview {...props} />
      </React.Fragment>
    )
  }

  if (props.hasElements) {
    return <ListButtonShowConfiguration {...props} />
  }

  return <ListButtonPuzzleModules {...props} />
}

export {
  ListButtonShowPreview,
  ListButtonShowSource,
  ListButtonShowSourcePublish,
  ListButtonShowConfiguration,
  ListButtonViewConfiguration,
  ListButtonPuzzleModules,
  ListButtonEditBriefing,
  ListButtonViewModules,
  ListButtonShowReview,
  ListButtonEditLatestStep,
  ListButtonShowPreviewExternal,
  ListButtonShowIncompletePreviewExternal,
  ListButtonPreviewNew,
}
