import React, { memo, useMemo } from 'react'
import { Item } from 'semantic-ui-react'
import HeaderHr from '../../../components/semantic/HeaderHr'

function BriefingList(props) {
  const { briefings, title, icon, searchterm, ListItemComponent, match, reporterFilter } = props
  if (!briefings || !Array.isArray(briefings) || briefings.length === 0) return <React.Fragment />

  const sortedBriefings = useMemo(
    () =>
      briefings.sort((a, b) => {
        if (a.dueDateGolive === b.dueDateGolive) return 0
        return a.dueDateGolive > b.dueDateGolive ? 1 : -1
      }),
    [briefings]
  )

  return (
    <React.Fragment>
      <HeaderHr style={{ marginTop: '3em' }} title={title} icon={icon} />
      <Item.Group relaxed divided style={{ padding: '0 3em' }}>
        {sortedBriefings.map(briefing => (
          <ListItemComponent
            key={briefing._id}
            match={match}
            searchterm={searchterm}
            reporterFilter={reporterFilter}
            {...briefing}
          />
        ))}
      </Item.Group>
    </React.Fragment>
  )
}

export default memo(BriefingList)
