import React from 'react'
import { Message, Button } from 'semantic-ui-react'
import { errorStrings } from '@douglas/baas-shared'

export const Error = ({ code, close }) => {
  if (!code) return null

  return (
    <Message
      negative
      header="An error occurred :("
      style={{ maxWidth: '700px' }}
      content={
        <>
          <p>{errorStrings[code] ? errorStrings[code] : `Unknown error: ${code}`}</p>
          <p>If this error was unexpected, please create a SERVICEOPS ticket in Jira.</p>
          <Button style={{ marginTop: '10px' }} onClick={close} basic compact>
            Close
          </Button>
        </>
      }
    />
  )
}
