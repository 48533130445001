import React from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

export const FormInlineErrorWrapper = styled.div`
  color: #9f3a38;
  font-weight: bold;
`

export const WarningSignPositionWrapper = styled.div`
  position: absolute;
  left: -60px;
  margin-top: -33px;
`

export const FormInlineError = ({ children }) => {
  return (
    <React.Fragment>
      <WarningSignPositionWrapper>
        <Icon color="red" name="warning sign" size="big" />
      </WarningSignPositionWrapper>
      <FormInlineErrorWrapper>{children}</FormInlineErrorWrapper>
    </React.Fragment>
  )
}
