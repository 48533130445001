import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { deactivatedComponents } from '../Overview/helpers'
import ContentModule from '../../../components/ContentModule'
import { getComponentname } from '../../../helpers/baasHelper'

const ComponentWrapperSidebar = styled.div`
  border: 2px solid #c3eae4;
  background-color: #c3eae4;
  ${props => props.mobile && { maxWidth: '200px' }};
  img {
    background: white;
  }
  margin-bottom: 1em;
`

const ComponentWrapperWorkszone = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  .icon {
    margin-left: 1rem;
  }
`

const ComponentTitle = styled.div`
  text-align: center;
  background-color: #c3eae4;
`

function DraggableContentModule({ location = 'sidebar', mobile, item, small, index, removeModule }) {
  const getStyle = (style, snapshot) => {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    }
  }

  const isDeactivated = deactivatedComponents.includes(item.metadata.name)

  if (isDeactivated && location === 'sidebar') {
    return null
  }
  return (
    <Draggable draggableId={`${item.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          className="draggable"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isdragging={snapshot.isDragging.toString()}
          style={getStyle(provided.draggableProps.style, snapshot)}
        >
          {location === 'workzone' && (
            <ComponentWrapperWorkszone>
              <ContentModule
                mobile={mobile}
                assets={item.metadata.assets}
                small={small}
                isDragging={snapshot.isDragging}
                name={item.name}
              />
              <Icon name="window close" size="huge" onClick={() => removeModule(index)} />
            </ComponentWrapperWorkszone>
          )}

          {location === 'sidebar' && (
            <ComponentWrapperSidebar mobile={mobile}>
              <ComponentTitle>{getComponentname(item.metadata)}</ComponentTitle>
              <ContentModule mobile={mobile} assets={item.metadata.assets} small={small} isDragging={snapshot.isDragging} />
            </ComponentWrapperSidebar>
          )}
        </div>
      )}
    </Draggable>
  )
}

export default DraggableContentModule
