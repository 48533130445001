import React, { memo } from 'react'
import { Loader } from 'semantic-ui-react'
import useTimedBoolean from '../../hooks/useTimedBoolean'

const DelayedSpinner = ({ startDelay = 600 }) => {
  const showLoadingSpinner = useTimedBoolean({ startDelay, duration: -1, numberOfOccurences: -1 })
  return <>{showLoadingSpinner && <Loader active inline="centered" style={{ marginTop: '3em' }} />}</>
}

export default memo(DelayedSpinner)
