import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Footer from './Footer'
import Routes from './Routes'
import Preview from './Briefings/Preview/FrameWrapper'
import PreviewFrameContent from './Briefings/Preview/FrameContent'
import BriefingContextProvider from '../contexts/Briefings'

const ScreensWithLayout = props => {
  return (
    <BriefingContextProvider>
      <Routes {...props} />
      <Footer />
    </BriefingContextProvider>
  )
}

function index() {
  return (
    <Switch>
      <Route exact path="/preview/:id/frame" render={props => <PreviewFrameContent briefingId={props.match.params.id} />} />
      <Route exact path="/preview/:id" render={props => <Preview briefingId={props.match.params.id} />} />

      <Route
        exact
        path="/incompletePreview/:id"
        render={props => <Preview briefingId={props.match.params.id} renderIncomplete="true" />}
      />
      <Route
        exact
        path="/incompletePreview/:id/frame"
        render={props => <PreviewFrameContent briefingId={props.match.params.id} renderIncomplete="true" />}
      />
      <Route
        exact
        path="/qaPreview/:id/frame"
        render={props => <PreviewFrameContent briefingId={props.match.params.id} renderIncomplete="true" qa="true" />}
      />
      <Route component={ScreensWithLayout} />
    </Switch>
  )
}

export default index
