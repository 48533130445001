/* eslint-disable import/no-cycle */
import React from 'react'
import { startCase } from 'lodash'
import Fields from './index'
import { maxVideoSize } from '../../../../components/semantic/Form/uploader/Video'
import { formatAsset, formatValidation, isBaas15Active } from '../../../../helpers/baasHelper'

export const createRequirementsText = validation => {
  if (!validation) return ''
  let { minLength, maxLength, asset, video } = formatValidation(validation)

  asset = formatAsset(asset)

  if (video) return `Max ${maxVideoSize / 1000 / 1000} MB, will be resized automatically`
  if (asset) {
    if (asset.minHeight && asset.maxHeight && asset.width) {
      return `Width: ${asset.width} px, Height: between ${asset.minHeight} and ${asset.maxHeight} px`
    }
    if (asset.width && asset.height) {
      return `${asset.width} x ${asset.height} px`
    }
    if (asset.height) {
      return `Width: no limit, Height: ${asset.height} px`
    }
    if (asset.width) {
      return `Width: ${asset.width} px, Height: No limit`
    }
    if (asset.maxHeight && asset.minHeight) {
      return `Height: between ${asset.minHeight} and ${asset.maxHeight}`
    }
  }

  if (minLength && maxLength) {
    return `Between ${minLength} and ${maxLength} characters`
  }
  if (minLength) {
    return `Min ${minLength} characters`
  }
  if (maxLength) {
    return `Max ${maxLength} characters`
  }

  return false
}

const _addToMessage = (message, add) => `${!message ? 'This field' : `${message} and`} ${add}`

const createErrorMessage = validation => {
  if (!validation) return
  const { required, minLength, maxLength, asset, video } = formatValidation(validation)

  if (asset) return 'Please upload an image.'
  if (video) return 'Please upload a video.'

  let errorMessage
  if (required) {
    errorMessage = _addToMessage(errorMessage, 'is required')
  }
  if (minLength && maxLength) {
    errorMessage = _addToMessage(
      errorMessage,
      `needs to be at least ${minLength} characters but not longer than ${maxLength} characters`
    )
    return `${errorMessage}.`
  }
  if (minLength) {
    errorMessage = _addToMessage(errorMessage, `needs to be at least ${minLength} characters long`)
    return `${errorMessage}.`
  }
  if (maxLength) {
    errorMessage = _addToMessage(errorMessage, `has to be less than ${maxLength} characters long`)
    return `${errorMessage}.`
  }
  return `${errorMessage}.`
}

export const createField = (field, additionalProps, prefix, briefingId, isReviewMode, componentName, componentIndex) => {
  const identifier = `${prefix}.${field.name || field.label}${field.type === 'repeatable' ? '_BaasMetadata_' : ''}`
  const errorMessage = field.errorMessage || createErrorMessage(field.validation)
  const description = field.description || createRequirementsText(field.validation)
  if (isBaas15Active) {
    if (field.hide15) {
      return null
    }
  }

  if (typeof isReviewMode === 'undefined') {
    // If review mode is not set, fall-back on asText prop
    isReviewMode = additionalProps.asText
  }
  return React.createElement(Fields[startCase(field.type)], {
    key: identifier,
    ...field,
    ...additionalProps,
    errorMessage,
    description,
    name: identifier,
    briefingId,
    prefix,
    asText: isReviewMode,
    originName: field.name,
    componentName,
    componentIndex,
  })
}

export default { createField }
