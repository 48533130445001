import React from 'react'
import { Select } from '../../../../components/semantic/Form'

export default function Enum(props) {
  const options = props.values.map((_value, index) => {
    if (typeof _value === 'string') {
      _value = [_value, _value]
    }
    const [key, text, value] = [index, ..._value]
    return { key, text, value }
  })
  return <Select {...props} default={props.values[0]} options={options} />
}
