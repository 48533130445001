import React, { useRef, useEffect } from 'react'
import { Input, Icon, Button, Message } from 'semantic-ui-react'
import { usePost } from '../../hooks/restHooks'
import DelayedSpinner from '../../components/semantic/DelayedSpinner'

export const LoginBox = props => {
  /**
   * This is our main entry point to the app so we block unsupported
   * browsers before anything else can happen.
   *
   * TODO: Adapt error message when the BaaS gets released outside Douglas
   */
  const isEdge = /Edge\/\d./i.test(navigator.userAgent)
  if (isEdge)
    return (
      <Message negative>
        <Message.Header>
          Microsoft Edge is not supported. <br />
          Please use either Chrome or Firefox.
          <br />
          <br />
          You can get Chrome via the DIS Software Store
        </Message.Header>
      </Message>
    )

  const { setError, setEmail } = props
  const { trigger, loading, error } = usePost('session/request', false, false)
  const emailRef = useRef()

  useEffect(() => {
    setError(error)
    return () => {
      setError('')
    }
  }, [error])

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  if (loading) return <DelayedSpinner />

  const onSubmit = async event => {
    event.preventDefault()
    const email = emailRef.current.value
    await trigger({ email })
    setEmail(email)
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Input iconPosition="left" placeholder="Email">
          <Icon name="at" />
          <input size="30" ref={emailRef} />
          <Button primary type="submit">
            Login
          </Button>
        </Input>
      </form>
    </>
  )
}
