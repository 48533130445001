// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
let counter = 0
export const generateHash = length => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length - 1; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  counter++
  return counter + '-' + result
}
