import { Observable } from '../helpers/observableClass'
import { myFetch, myPost } from '../hooks/restHooks'

const _cache = new Map()
window.briefingCache = _cache

const cacheUpdateObserver = new Observable()

export function setBriefing(id, data) {
  _cache.set(id, data)
  cacheUpdateObserver.notify(new Map([[id, data]]))
}

export function batchSetBriefing(briefingArray) {
  const updatedBriefingMap = new Map()
  briefingArray.forEach(briefing => {
    _cache.set(briefing._id, briefing)
    updatedBriefingMap.set(briefing._id, briefing)
  })
  cacheUpdateObserver.notify(updatedBriefingMap)
}

export function clearBriefingCache() {
  _cache.clear()
}

export function getBriefing(id) {
  if (!_cache.has(id)) {
    return null
  }
  const data = _cache.get(id)
  return data
}

export function subscribeBriefingChanged(fn) {
  cacheUpdateObserver.subscribe(fn)
}

export function unsubscribeBriefingChanged(fn) {
  cacheUpdateObserver.unsubscribe(fn)
}

// Used for preventing duplicated fetching of the same briefing (race conditions)
const fetchQueue = new Set()

export async function fetchBriefing(id) {
  if (!fetchQueue.has(id)) {
    fetchQueue.add(id)
    try {
      const isQaRequest = window.location.pathname.search('qaPreview') > 0
      const briefingApiEndpoint = isQaRequest ? 'qa' : 'briefing'
      const res = await myFetch(`${briefingApiEndpoint}/${id}`)
      const resData = await res.json()
      setBriefing(id, resData.briefing)
    } catch (e) {
      console.error(e)
    }
    fetchQueue.delete(id)
  }
}

export async function batchFetchBriefings() {
  try {
    const res = await myFetch(`briefing/batch/get`)
    const resData = await res.json()
    batchSetBriefing(resData.briefings)
  } catch (e) {
    console.error(e)
  }
}

export const patchBriefingLocally = (id, data) => {
  const briefing = getBriefing(id)
  if (!briefing) {
    return false
  }
  let newData = data
  if (typeof data === 'function') {
    newData = data(briefing)
  }
  const newBriefing = { ...briefing, ...newData }
  setBriefing(id, newBriefing)
  return true
}

export const patchBriefing = (id, data) => {
  if (data.status && ['open', 'review', 'rework'].includes(data.status)) {
    // Detected change to an editable status. Update client side cache accordingly.
    patchBriefingLocally(id, { isEditable: true })
  }
  patchBriefingLocally(id, data)
  return myPost('briefing/patch', { _id: id, data })
}
