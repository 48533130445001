import { useState, useRef, useEffect } from 'react'
import { myPost, myFetch } from '../../../../hooks/restHooks'

const videoDetailsDefault = { thumbnailUrl: null, name: null }

export const useMovingimage24 = defaultVideoId => {
  const [videoId, setVideoId] = useState(defaultVideoId)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [videoDetails, setVideoDetails] = useState(videoDetailsDefault)
  const timeout = useRef()

  useEffect(() => {
    if (videoId.length === 22) {
      // Form is initialized with already stored video id; try to fetch thumbnail without retrying
      _fetchThumbnail(videoId, 0)
    }
    // Clean-up a running timeout, in case the component was unmounted
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  /* This function tries to fetch a thumbnail von MI24.
   * If its not available after 15 retries, its likely stuck in transcoding state,
   * so the video will never be available.
   * It will recursivly call itself again, when the thumbnail is not available yet. */
  const _fetchThumbnail = async (videoId, retry = false) => {
    let response
    let result = {}
    try {
      response = await myFetch(`movingimage24/${videoId}`)
      result = await response.json()
    } catch (e) {
      setError(e.error)
    }

    if (!result.thumbnail) {
      if (retry) {
        timeout.current = setTimeout(() => _fetchThumbnail(videoId, true), 1000) // Retry after 10 sek
      } else {
        setError('Video is not available or still being processed by the video provider.')
      }
    } else {
      setError(null)
      setVideoDetails(result)
    }
  }

  const uploadVideo = async file => {
    setLoading(true)
    setError(null)
    let videoId = null
    try {
      // Step 1: get MI24 upload URL from backend
      const uploadUrlResponse = await myPost('movingimage24/', { filename: file.name })
      if (!uploadUrlResponse.ok) {
        setError('Error while reaching for upload service.')
        setLoading(false)
        return
      }

      const uploadUrlResult = await uploadUrlResponse.json()
      if (!uploadUrlResult.videoId) {
        setError('Error while requesting upload url for video.')
        setLoading(false)
        return
      }

      // Step 2: try to post video to fetched upload URL.
      // Chunked-uploading is used here. Read MI24 documentation for details.
      await fetch(uploadUrlResult.uploadUrl, {
        method: 'POST',
        body: file,
        headers: {
          'Mi24-Upload-Total-Chunks': 1,
          'Mi24-Upload-Current-Chunk': 1,
          'Content-Type': 'application/octet-stream',
        },
      })

      setVideoId(uploadUrlResult.videoId)
      setVideoDetails(videoDetailsDefault)

      // Step 3: trying to poll the thumbnail, endless retrys, 10 second delay
      _fetchThumbnail(uploadUrlResult.videoId, true)
    } catch (e) {
      setError(e.error)
    } finally {
      setLoading(false)
    }
    return videoId // is null on error
  }

  const setVideoIdAndFetchThumbnail = newVideoId => {
    if (newVideoId.length !== 22) {
      setError('The video ID is invalid.')
      return
    }

    setError(null)
    setVideoDetails(videoDetailsDefault)

    // Try to fetch the videos thumbnail.
    // No refetch tries; the user should only enter video ids, that have already been processed successfuly.
    _fetchThumbnail(newVideoId)
    setVideoId(newVideoId)
  }

  const onDelete = () => {
    if (videoId) {
      myFetch(`movingimage24/${videoId}`, { method: 'DELETE' })
    }

    setVideoId('')
  }

  return { uploadVideo, videoDetails, loading, error, videoId, setVideoId: setVideoIdAndFetchThumbnail, onDelete }
}
