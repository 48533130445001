import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { map } from 'lodash'
import CategoryOverview from './CategoryOverview'
import Layout from '../Layout'
import { ComponentContext } from '../../contexts/Component'
import { beautifyCategoryName } from '../../helpers/string'

export default function Index({
  history: {
    location: { pathname },
  },
  match: { params },
}) {
  const components = useContext(ComponentContext)

  if (!params || !params.category) {
    const firstCategory = Object.keys(components.categories)[0]
    return <Redirect to={`/guide/${firstCategory}`} />
  }
  const menuItems = map(components.categories, (_, name) => ({
    path: `/guide/${name}`,
    name: beautifyCategoryName(name),
  }))
  return (
    <Layout menuItems={menuItems} pathname={pathname} showHr={true} smallOffset={true}>
      <Route path="/guide/:category" component={CategoryOverview} />
    </Layout>
  )
}
