import React, { memo, useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { Pagination } from 'semantic-ui-react'
import BriefingList from './BriefingList'
import NothingHere from '../../../components/NothingHere'
import LoadingList from '../../../components/LoadingList'
import { BriefingContext } from '../../../contexts/Briefings'
import { getListItemComponent } from './helpers'

const rowsPerPage = 15

const Wrapper = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
`

function index(props) {
  const { category, searchterm, groupedBriefings, reporterFilter } = props
  const { loading } = useContext(BriefingContext)
  const [page, setPage] = useState(1)

  const briefingsFilteredByStatus = Array.isArray(groupedBriefings[category]) ? groupedBriefings[category] : []

  useEffect(() => {
    setPage(1)
  }, [searchterm])

  const totalPages = Math.max(Math.ceil(briefingsFilteredByStatus.length / rowsPerPage), 1)

  const onPageChange = (e, { activePage }) => setPage(activePage)
  const currentPageBriefings = briefingsFilteredByStatus.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)

  return (
    <Wrapper>
      {loading && <LoadingList />}
      {!loading && briefingsFilteredByStatus.length === 0 && <NothingHere />}
      {!loading && briefingsFilteredByStatus.length !== 0 && (
        <>
          <BriefingList
            title={`Briefings (${category})`}
            icon="edit"
            briefings={currentPageBriefings}
            ListItemComponent={getListItemComponent(category)}
            searchterm={searchterm}
            reporterFilter={reporterFilter}
            match={props.match}
          />
          {totalPages > 1 && (
            <div style={{ marginTop: '3em', display: 'flex', justifyContent: 'center' }}>
              <Pagination activePage={page} totalPages={totalPages} onPageChange={onPageChange} />
            </div>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default memo(index)
