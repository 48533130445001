export const anyObjectFieldMatchingFilter = (needle, checkEquality = true) => object => {
  if (typeof needle === 'string') {
    // if needle is a string,
    // transform to lowercase
    needle = needle.toLowerCase()
  } else {
    // if needle is not a string,
    // string-based subset search is not possible
    // falling-back to equality check then
    checkEquality = true
  }

  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      let value = object[key]
      if (typeof value !== 'string') {
        continue
      }
      value = value.toLowerCase()
      if (checkEquality) {
        if (value === needle) {
          return true
        }
      } else {
        if (value.search(needle) !== -1) {
          return true
        }
      }
    }
  }
  return false
}
