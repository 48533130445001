import React, { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Icon, Accordion } from 'semantic-ui-react'
import DraggableContentModule from './DraggableContentModule'
import { deactivatedComponents } from '../Overview/helpers'

const CategoryWrapper = styled.div``

export default function SidebarCategory({ open, categories, categoryKey, mobile, index }) {
  const [isOpen, setIsOpen] = useState(false)

  const getModules = () => {
    return categories[categoryKey].map((item, index) =>
      deactivatedComponents.includes(item.metadata.name) ? null : (
        <DraggableContentModule
          location="sidebar"
          key={item.id}
          small={true}
          item={item}
          device="sidebar"
          mobile={mobile}
          index={index}
        />
      )
    )
  }

  let isNotNull = value => value != null
  const contentModules = getModules().filter(isNotNull)

  return contentModules.length > 0 ? (
    <CategoryWrapper key={categoryKey}>
      <Droppable droppableId={categoryKey}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Accordion styled style={mobile ? { maxWidth: '250px' } : {}}>
              <Accordion.Title
                active={isOpen || open}
                index={index}
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
              >
                <Icon name="dropdown" />
                {categoryKey}
              </Accordion.Title>
              <Accordion.Content active={isOpen || open}>{contentModules}</Accordion.Content>
            </Accordion>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </CategoryWrapper>
  ) : null
}
