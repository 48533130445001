import React from 'react'
import styled from 'styled-components'

const CircleIcon = props => {
  const { active, disabled } = props
  const CircleIconWrapper = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 30px;
    text-align: center;
    position: relative;
    margin-bottom: 8px;
    border: 1px solid ${disabled ? 'rgba(40,40,40,.3)' : 'black'};
    background-color: ${active ? 'black' : 'white'};
    color: ${active ? 'white' : disabled ? 'rgba(40,40,40,.3)' : 'black'};

    @media screen and (min-width: 768px) {
      margin-bottom: auto;
      margin-right: 8px;
    }

    .value {
      font-size: 12px;
      font-weight: bold;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  `
  return (
    <CircleIconWrapper>
      <div className="value">{props.children}</div>
    </CircleIconWrapper>
  )
}
export default CircleIcon
