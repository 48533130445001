import React, { memo } from 'react'
import { Image, Menu, Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FEATURE, hasAccess } from '@douglas/baas-shared'
import { isBaas15Active } from '../helpers/baasHelper'
import logo from '../images/DOUGLAS_LOGO_S_RGB_white.png'
import slogan from '../images/ContentBuilder.png'
import slogan15 from '../images/baas.png'
import Layout from './Layout'
import Steps from './Briefings/Steps'
import { useBriefing } from '../hooks/useBriefing'
import { useUserContext } from '../contexts/User'
import './Navbar.css'

const Header = styled.div`
  display: flex;
  margin: 1em 0em;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.div`
  .ui.image {
    max-width: 180px;
  }
`

const MintHr = styled.div`
  height: ${props => (props.size === 'small' ? '1em' : '2em')};
  margin-bottom: ${props => (props.size === 'small' ? '1em' : '0')};
  background-color: #c3eae4;
  margin-bottom: 0;
  position: sticky;
  z-index: 2000;
  top: 0;
`

const CollapsedNavbarWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2000;

  .ui.image {
    position: absolute;
    left: 1em;
    max-width: 180px;
  }
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 1.8em;
  right: 1em;
`
function Navbar(props) {
  const { history, minimal, match } = props
  const [briefing] = useBriefing(match.params.id)
  const { pathname } = history.location
  const { role, isAdmin } = useUserContext()

  // If user is on module editor, show minimal navbar
  if (minimal) {
    return (
      <CollapsedNavbarWrapper>
        <MintHr size="small" />
        <div>
          <Image as={Link} to="/" src={logo} className="vcenter" style={{ paddingTop: '14px' }} />
        </div>
        <Steps {...props} />
        <CloseButtonWrapper>
          <Button
            size="medium"
            icon="close"
            onClick={() => {
              history.push(`/briefings/${briefing ? briefing.status : ''}`)
            }}
            id="checkout-close-button"
          />
        </CloseButtonWrapper>
      </CollapsedNavbarWrapper>
    )
  }

  const hasBriefingAccess = hasAccess(role, FEATURE.list, true, true)
  return (
    <div className="Navbar">
      <MintHr size="small" />
      <Layout>
        <Header>
          <Logo>
            <Image as={Link} to="/" src={logo} className="vcenter" />
          </Logo>
          <div className="menu-wrapper">
            <Menu pointing secondary>
              {hasBriefingAccess && (
                <Menu.Item
                  name="Briefings"
                  active={pathname.includes('/briefings')}
                  as={Link}
                  to="/briefings"
                  id="navbar-briefings"
                />
              )}
              <Menu.Item name="Modules" active={pathname.includes('/guide')} as={Link} to="/guide" id="navbar-guide" />
              {isAdmin && <Menu.Item name="Admin" active={pathname === '/admin'} as={Link} to="/admin" id="admin-start" />}
              <Menu.Item name="FAQ" active={pathname === '/faq'} as={Link} to="/faq" id="navbar-start" />
            </Menu>
          </div>
          <Image src={isBaas15Active ? slogan15 : slogan} style={{ height: '100%', maxHeight: '44px' }} />
        </Header>
      </Layout>
    </div>
  )
}

export default memo(Navbar)
