import React, { useEffect, useState } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'
import { FormInlineError } from './FormInlineError'
import { Label } from './sharedComponents'

const regexp = /^#[0-9a-fA-F]{6,8}$/
const ControlsWrapper = styled.div`
  display: flex;
  button {
    width: 15rem;
  }
`

const ColorPickerWrapper = styled.div`
  position: absolute;
  z-index: 2;
`

// creating a div which covers the entire screen,
// to catch clicks outside of the color picker window for closing it
const CoverDiv = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

const ColorBox = styled.div`
  display: inline-block;
  width: 55px;
  height: ${({ small }) => (small ? '13px' : 'auto')};
  padding: 5px;
  background-color: ${({ color }) => (regexp.test(color) ? color : '#f2f2f2')};
  border-top-left-radius: 0.28rem;
  border-bottom-left-radius: 0.28rem;
  border: 1px solid rgba(34,36,38,.15);
  border-right-width: 0;
  box-sizing: content-box;
`

export default function ColorPicker({
  name,
  validation = {},
  initialValue = '#f2f2f2',
  errorMessage,
  label,
  register,
  unregister,
  errors,
  setValue,
  clearError,
  getDefault,
  asText,
  description,
  componentIndex = 0,
  componentName = 'form',
  originName = name,
}) {
  // Getting edit-mode default values from hook-form lib,
  // falling back to initialValue for create-mode if not existant
  const defaultValue = getDefault(name) || initialValue
  const [value, setInternalValue] = useState(defaultValue)
  const [colorpickerOpen, setColorpickerOpen] = useState(false)
  const { required } = validation

  useEffect(() => {
    register({ name }, validation)
    setValue(name, defaultValue)

    return () => {
      unregister(name)
    }
  }, [])

  const updateValue = val => {
    clearError(name)
    setValue(name, val)
    setInternalValue(val)
  }

  const onColorPickerChange = color => {
    updateValue(color.hex)
  }

  const onInputFieldChange = event => {
    updateValue(event.target.value)
  }

  return (
    <Form.Field error={!!errors[name]} id={`${componentIndex}-${componentName}-${originName}-color-wrapper`}>
      <Label required={required} text={label} description={description} />
      {asText && (
        <span>
          <ColorBox color={value} small={true} /> {value}
        </span>
      )}
      {!asText && (
        <ControlsWrapper>
          <ColorBox color={value} onClick={setColorpickerOpen} />
          <input value={value} onChange={onInputFieldChange} style={{ borderRadius: 0 }} />
          <Button
            onClick={e => {
              e.preventDefault()
              setColorpickerOpen(true)
            }}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            Pick color
          </Button>
        </ControlsWrapper>
      )}

      {!asText && colorpickerOpen && (
        <div style={{ display: 'relative' }}>
          <ColorPickerWrapper style={{}}>
            <SketchPicker
              onClose={() => {
                setColorpickerOpen(false)
              }}
              color={value}
              onChangeComplete={onColorPickerChange}
            />
          </ColorPickerWrapper>
          <CoverDiv
            onClick={() => {
              setColorpickerOpen(false)
            }}
          />
        </div>
      )}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}
