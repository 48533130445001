import React, { useState } from 'react'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { FEATURE, hasAccess } from '@douglas/baas-shared'
import { hasBriefingAccess } from '../../helpers/hasBriefingAccess'
import { useUserContext } from '../../contexts/User'
import { patchBriefing } from '../../core/briefingCache'
import { isBaas15Active } from '../../helpers/baasHelper'

export default function Actionbar(props) {
  const [loading, setLoading] = useState(false)
  const user = useUserContext()
  const { active, match, briefing, _id, country, type } = props
  const { role, country: userCountry } = useUserContext()

  const isSameCountry = country === userCountry
  const canFinalizePromo = hasAccess(role, FEATURE.finalize_promos, isSameCountry)
  const canFinalizeOther = hasAccess(role, FEATURE.finalize, isSameCountry)
  const canFinalize = canFinalizeOther || (type === 'promos' && canFinalizePromo)

  const briefingId = match.params.id
  const showBackButton = hasBriefingAccess(briefing, user)
  const hasCodeAccess =
    (['closed', 'ready'].includes(briefing.status) && hasBriefingAccess(briefing, user, FEATURE.code)) || user.isAdmin

  const onNeedsReworkClicked = async () => {
    setLoading(true)
    await patchBriefing(_id, { status: 'rework' })
    setLoading(false)
  }

  const onFinalizeClicked = async () => {
    setLoading(true)
    await patchBriefing(_id, { status: 'ready' })
    setLoading(false)
  }

  //const previewLink = isBaas15Active ? getDouglasPreviewUrl(briefing) : `/briefings/${briefingId}/view/preview`
  const previewLink = `/briefings/${briefingId}/view/preview`
  return (
    <Menu inverted style={{ justifyContent: 'center' }}>
      <Menu.Menu>
        {showBackButton && <Menu.Item name="overview" icon="undo" as={Link} to={`/briefings/${briefing.status}`} />}
        {isBaas15Active ? (
          <a href={previewLink}>
            <Menu.Item name="preview" icon="eye" active={active === 'preview'} />
          </a>
        ) : (
          <Menu.Item name="preview" icon="eye" active={active === 'preview'} as={Link} to={previewLink} />
        )}
        <Menu.Item
          name="configure"
          icon="configure"
          active={active === 'configure'}
          as={Link}
          to={`/briefings/${briefingId}/view`}
        />
        {hasCodeAccess && (
          <Menu.Item name="code" icon="code" active={active === 'code'} as={Link} to={`/briefings/${briefingId}/view/code`} />
        )}
        {briefing.status === 'review' && canFinalize && (
          <>
            <Menu.Item
              name="needs rework"
              icon="close"
              onClick={onNeedsReworkClicked}
              disabled={loading}
              as={Link}
              to={`/briefings/review`}
              className="reworkBtn"
            />
            <Menu.Item
              name="finalize"
              icon="check"
              onClick={onFinalizeClicked}
              disabled={loading}
              as={Link}
              to={`/briefings/review`}
              className="finalizeBtn"
            />
          </>
        )}
      </Menu.Menu>
    </Menu>
  )
}
