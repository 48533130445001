import React, { memo, useContext } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { hasAccess, FEATURE } from '@douglas/baas-shared'
import { useUserContext } from '../../../contexts/User'
import { patchBriefing } from '../../../core/briefingCache'
import { BriefingContext } from '../../../contexts/Briefings'

const LockButton = memo(({ isLocked, _id, title, role, isSameCountry, isOwner }) => {
  const canLock = hasAccess(role, FEATURE.lock, isSameCountry, isOwner)
  const canUnlock = hasAccess(role, FEATURE.unlock, isSameCountry, isOwner)

  const onLockBriefing = async () => {
    //eslint-disable-next-line no-restricted-globals
    const answer = confirm(
      `Do you really want to lock the briefing "${title}?\n` +
      `Non-Admins will not be able to change briefing information or the module composition or order anymore. They can still complete the module form.`
    )
    if (answer) {
      patchBriefing(_id, { isLocked: true })
    }
  }

  const onOpenBriefing = async () => {
    //eslint-disable-next-line no-restricted-globals
    const answer = confirm(`Do you really want to open the briefing "${title}"?`)
    if (answer) {
      patchBriefing(_id, { isLocked: false })
    }
  }

  return (
    <React.Fragment>
      {!isLocked && canLock && (
        <Popup
          trigger={<Button basic onClick={onLockBriefing} icon="lock" className="test-action-lock-button" />}
          content="Lock this briefing.
          You can then share it with co-workers, and they will only be able to fill out the form details.
          They will not be able to change briefing information or manipulate the composition or order of modules anymore.
          Only admins can re-open a briefing once it has been locked."
          inverted
        />
      )}
      {isLocked && canUnlock && (
        <Popup
          trigger={<Button basic icon="lock open" onClick={onOpenBriefing} className="test-action-unlock-button" />}
          content="Reopen the briefing to enable module composition changes."
          inverted
        />
      )}
    </React.Fragment>
  )
})

const DeleteButton = memo(({ title, _id, isSameCountry, isOwner, role, refetch = () => { } }) => {
  const { refetchAll } = useContext(BriefingContext)

  const canDelete = hasAccess(role, FEATURE.delete, isSameCountry, isOwner)
  if (!canDelete) return <React.Fragment />

  const deleteBriefing = async () => {
    //eslint-disable-next-line no-restricted-globals
    const answer = confirm(`Do you really want to delete the briefing "${title}"?`)
    if (answer) {
      await patchBriefing(_id, { isDeleted: true })
      refetch()
    }
    refetchAll()
  }

  return <Button basic onClick={deleteBriefing} icon="trash" className="test-action-delete-button" />
})

const ActionButtons = memo(props => {
  const { country, owner } = props
  const { role, userId, country: userCountry } = useUserContext()
  const isOwner = owner === userId
  const isSameCountry = userCountry === country
  const componentProps = { ...props, isOwner, isSameCountry, role }

  return (
    <React.Fragment>
      <DeleteButton {...componentProps} />
      <LockButton {...componentProps} />
    </React.Fragment>
  )
})

export default ActionButtons
