import React, { memo, useEffect, useState } from 'react'
import { Card, Button } from 'semantic-ui-react'
import CodeInput from '../../components/semantic/CodeInput'
import DelayedSpinner from '../../components/semantic/DelayedSpinner'
import { usePost } from '../../hooks/restHooks'
import Emoji from '../../components/Emoji'
import { useUserContext } from '../../contexts/User'

export const VerifyBox = memo(props => {
  const { login } = useUserContext()
  const { email, setError, emoji, setEmail } = props
  const { trigger, loading, error } = usePost('session/verify', false, false)
  const [showTick, setShowTick] = useState(false)

  useEffect(() => {
    setError(error)
    return () => {
      setError('')
    }
  }, [error])

  if (loading) return <DelayedSpinner />

  if (showTick) {
    return <Emoji size="80" name={emoji} />
  }

  const onFilled = async pin => {
    const userInformation = await trigger({ pin, email })
    delete userInformation.ok
    setShowTick(true)
    setTimeout(() => {
      login(userInformation)
    }, 2000)
  }

  return (
    <React.Fragment>
      <CodeInput length={5} allowedChars="0123456789" onFilled={onFilled} />
      <Card
        style={{ margin: '2em auto 0 auto' }}
        header="Check your inbox"
        description={
          <React.Fragment>
            {'You should have received an e-mail with a login pin. Please enter it here.'}
            <br />
            <Button style={{ marginTop: '10px' }} onClick={() => setEmail('')} basic compact>
              Try another user
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
})
