import React, { useState, useContext } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { insertAfter, swap } from '../../../helpers/array'
import { generateHash } from '../../../helpers/generateHash'
import { ComponentContext } from '../../../contexts/Component'
import Actionbar from './Actionbar'
import WorkingSurface from './WorkingSurface'
import { useComponentConfigs } from '../../../hooks/useComponentConfigs'
import BreakpointToggleButton from './BreakpointToggleButton'
import { useBriefing } from '../../../hooks/useBriefing'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import Sidebar from './Sidebar'
import ModuleView from './ModuleView'
import { BlackHr } from '../../../components/BlackHr'

function index(props) {
  const briefingId = props.match.params.id
  const [searchterm, setSearchterm] = useState('')
  const { getFilteredCategories, getComponentById } = useContext(ComponentContext)
  const { addElement, removeElement, setOrder, elements, order, loading: isComponentsLoading } = useComponentConfigs(briefingId)
  const [briefing, isBriefingLoading] = useBriefing(briefingId)
  const [mobile, setMobile] = useState(false)

  if (isBriefingLoading || (isComponentsLoading && !elements)) {
    return <DelayedSpinner />
  }

  if (!briefing.isEditable || briefing.isLocked) {
    return (
      <>
        <BlackHr />
        <ModuleView briefing={briefing} />
      </>
    )
  }

  let categories = []
  if (briefing) {
    categories = briefing.type === 'pop_cp' ? getFilteredCategories('pop') : getFilteredCategories(briefing.type)
  }

  const onDragEnd = result => {
    const { source, destination, draggableId } = result
    if (!destination) return

    let sourceId = source.droppableId
    let destinationId = destination.droppableId
    if (destinationId !== 'content') return

    if (sourceId !== 'content') {
      // sidebar --> content
      // Copy content module to disconnect reference.
      // Generate new id to support independant dragging.
      const { name, category, customValues } = getComponentById(draggableId).metadata

      let newElement = { name, category, config: {}, customValues }
      const uniqueElementInstanceId = 'd' + generateHash(6)
      const newOrder = insertAfter(order, uniqueElementInstanceId, destination.index)
      addElement(uniqueElementInstanceId, newElement, newOrder)
      return
    }

    // content --> content
    const newOrder = swap(order, source.index, destination.index)
    setOrder(newOrder)
  }

  const removeModule = index => {
    removeElement(order[index])
  }

  return (
    <div style={{ position: 'relative' }}>
      <Actionbar searchterm={searchterm} setSearchterm={setSearchterm} briefingId={briefingId} isValid={elements.length > 0} />

      <BreakpointToggleButton
        isMobile={mobile}
        toggleIsMobile={() => {
          setMobile(!mobile)
        }}
      />

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Sidebar categories={categories} mobile={mobile} searchterm={searchterm} />
          <WorkingSurface mobile={mobile} elements={elements} removeModule={removeModule} />
        </DragDropContext>
      </div>
    </div>
  )
}

export default index
