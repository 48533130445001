import React, { useMemo } from 'react'
import styled from 'styled-components'
import Emoji from 'node-emoji'
import SidebarCategory from './SidebarCategory'

const SidebarWrapper = styled.div`
  width: calc(100% / 5 * 1);
  padding-right: 0.5rem;
`

const NoResults = styled.div`
  margin: 2rem auto;
  max-width: 200px;
  font-size: 1.5rem;
`

export default function Sidebar({ categories, mobile, searchterm }) {
  const getFilteredCategories = () => {
    searchterm = searchterm.toLowerCase()
    const filteredCategories = Object.keys(categories).reduce((acc, category) => {
      const result = categories[category].filter(({ metadata }) => {
        return metadata.name.toLowerCase().search(searchterm) !== -1 || metadata.category.toLowerCase().search(searchterm) !== -1
      })
      if (result.length) acc[category] = result
      return acc
    }, {})
    return filteredCategories
  }

  if (searchterm !== '') {
    categories = useMemo(getFilteredCategories, [searchterm, categories])
  }
  const objectKeys = Object.keys(categories)
  return (
    <SidebarWrapper>
      {objectKeys.length === 0 && <NoResults>Nothing found {Emoji.get('sob')}</NoResults>}
      {Object.keys(categories).map((categoryKey, index) => (
        <SidebarCategory
          key={categoryKey}
          index={index}
          categoryKey={categoryKey}
          categories={categories}
          mobile={mobile}
          open={objectKeys.length === 1 || (searchterm !== '' && categories[categoryKey].length === 1)}
        />
      ))}
    </SidebarWrapper>
  )
}
