import React from 'react'
import { Placeholder, Item } from 'semantic-ui-react'
import HeaderHr from './semantic/HeaderHr'

const LoadingList = () => {
  const item = (
    <Item>
      <Placeholder fluid style={{ width: '100%' }}>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Item>
  )

  let rows = []
  for (let i = 0; i < 7; i++) {
    rows.push(item)
  }

  return (
    <React.Fragment>
      <HeaderHr style={{ marginTop: '3em' }} title={'loading'} icon={'sync'} />
      <Item.Group divided style={{ padding: '0 3em' }}>
        {rows}
      </Item.Group>
    </React.Fragment>
  )
}

export default LoadingList
