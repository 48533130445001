import React from 'react'
import { Prompt } from 'react-router-dom'

export const UnsavedFormChanges = props => {
  const { formControls, initialValues } = props

  const values = formControls.watch()
  const dirty = Object.keys(values).some(key => values[key] !== undefined && values[key] !== initialValues[key])

  return (
    <Prompt when={dirty} message="You have unsaved changes, are you sure you want to leave this page and lose your changes?" />
  )
}
