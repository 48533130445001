import React, { useContext } from 'react'
import { Header } from 'semantic-ui-react'
import { useTrail, config } from 'react-spring'
import ComponentRepresentation from './ComponentRepresentation'
import { ComponentContext } from '../../contexts/Component'
import { beautifyCategoryName } from '../../helpers/string'

export default function CategoryOverview(props) {
  const categoryName = props.match.params.category
  const componentsObj = useContext(ComponentContext)
  const components = componentsObj.categories[categoryName]
  const trail = useTrail(components.length, { opacity: 1, from: { opacity: 0 }, config: config.slow })
  return (
    <>
      <Header textAlign="center" as="h1">
        Modules: {beautifyCategoryName(props.match.params.category)}
      </Header>
      {trail.map(({ opacity }, index) => (
        <ComponentRepresentation opacity={{ opacity }} key={components[index].metadata.name} {...components[index]} />
      ))}
    </>
  )
}
