import React from 'react'
import styled from 'styled-components'
import { Icon, Popup, Label, Table, TableCell, TableRow } from 'semantic-ui-react'

const Wrapper = styled.div`
  display: inline;
  float: right;
  padding-left: 5px;
`

function BriefingInfoComponent(props) {
  const formatDate = dateString => {
    const date = new Date(dateString)

    if (isNaN(date) || !dateString) return '-'

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }

    // Format the date and time
    return date.toLocaleDateString('en-US', options)
  }

  return (
    <Popup
      position="left center"
      hoverable
      trigger={
        <Wrapper>
          <Label circular>
            <Icon name="calendar alternate outline " color="grey" style={{ margin: 0, cursor: 'help' }} />
          </Label>
        </Wrapper>
      }
    >
      <Popup.Header>Briefing History</Popup.Header>
      <Popup.Content>
        <Table celled collapsing>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Created</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(props.briefing.created)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Changed</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(props.briefing.updated)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Published first</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(props.briefing.publishedFirstDate)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Published last</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(props.briefing.publishedLastDate)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Closed</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(props.briefing.closed)}</TableCell>
          </TableRow>
        </Table>
      </Popup.Content>
    </Popup>
  )
}

export default BriefingInfoComponent
