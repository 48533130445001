/* eslint-disable import/no-cycle */
import React, { useState, useMemo, memo } from 'react'
import styled from 'styled-components'
import { includes } from 'lodash'
import { Select } from '../../../../components/semantic/Form'
import { createField } from './functions'

const DependentWrapper = styled.div`
  margin: 1.5rem 0;
`

const SelectWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 1.5rem;
`

const Dependent = props => {
  let defaultValue = props.getDefault(props.name)
  const { errors, dependents, values: dropDownValues } = props
  const options = useMemo(
    () =>
      dropDownValues.map((dropDownValue, index) => {
        // Setting this exact structure because this is what the SemanticUI Select component wants
        const [key, text, value] = [index, ...Object.entries(dropDownValue)[0]]
        return { key, text, value }
      }),
    [dropDownValues]
  )

  if (defaultValue === null && props.default) {
    defaultValue = options.find(option => option.text === props.default).value
  }

  const errorString = Object.keys(errors).join('_')
  const [dropDownValue, setDropdownValue] = useState(defaultValue)
  const dependentComponents = useMemo(() => {
    if (!dropDownValue) return
    const selectedOption = options.find(option => option.value === dropDownValue)
    return props.dependents.reduce((acc, field) => {
      if (includes(field.attachedTo, selectedOption && selectedOption.text)) {
        return [
          ...acc,
          createField(
            field,
            { ...props, ...field, showWrapper: true },
            props.prefix,
            props.briefingId,
            props.asText,
            props.componentName,
            props.componentIndex
          ),
        ]
      }
      return acc
    }, [])
  }, [dropDownValue, errorString, dependents])

  return (
    <DependentWrapper>
      <SelectWrapper key={props.prefix}>
        <Select {...props} default={defaultValue} options={options} hookIntoValueState={setDropdownValue} />
      </SelectWrapper>
      {dependentComponents}
    </DependentWrapper>
  )
}

export default memo(Dependent)
