import React from 'react'
import styled from 'styled-components'

const LargeContainerWrapper = styled.div`
  display: block;
  max-width: 100% !important;
  /* Mobile */

  @media only screen and (max-width: 767px) {
    & {
      width: auto !important;
      margin-left: 1em !important;
      margin-right: 1em !important;
    }
  }

  /* Tablet */

  @media only screen and (min-width: 933px) and (max-width: 1143px) {
    & {
      width: 933px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  /* Small Monitor */

  @media only screen and (min-width: 1143px) and (max-width: 1337px) {
    & {
      width: 1143px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  /* Large Monitor */

  @media only screen and (min-width: 1337px) {
    & {
      width: 1337px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
`
export default function LargeContainer(props) {
  return (
    <LargeContainerWrapper className="LargeContainer" {...props}>
      {props.children}
    </LargeContainerWrapper>
  )
}
