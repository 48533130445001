import { useState, useEffect } from 'react'
import { getBriefing, fetchBriefing, subscribeBriefingChanged, unsubscribeBriefingChanged } from '../core/briefingCache'

export const useBriefing = id => {
  const [briefing, setBriefing] = useState(null)
  const [loading, setLoading] = useState(false)

  const initBriefing = async briefingId => {
    setLoading(true)
    await fetchBriefing(briefingId)
    setLoading(false)
  }

  useEffect(() => {
    // No ID set so we dont subscribe and dont need cleanup
    if (!id) {
      setBriefing(null)
      return
    }

    const briefingChangedCallback = updatedBriefingMap => {
      if (updatedBriefingMap.has(id)) {
        setBriefing(updatedBriefingMap.get(id))
      }
    }

    // Subscribe to briefing changes for this id
    subscribeBriefingChanged(briefingChangedCallback)

    // Check if briefing is available immediately in cache
    const cachedBriefing = getBriefing(id)
    if (!cachedBriefing) {
      /* Case: briefing isnt cached yet
       * setTimeout for moving the fetching of a new briefing to the end of the callstack.
       * This prevents concurrent loading issues. */
      setTimeout(() => initBriefing(id), 0)
    } else {
      setBriefing(cachedBriefing)
    }

    return () => {
      unsubscribeBriefingChanged(briefingChangedCallback)
    }
  }, [id])

  const refetch = () => {
    fetchBriefing(id)
  }

  return [briefing, loading || !briefing, refetch]
}
