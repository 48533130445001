import React, { useContext, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Form, Header, Segment, Container, Card, List } from 'semantic-ui-react'
import { find } from 'lodash'
import dot from 'dot-object'
import { useForm } from '../../../../hooks/useForm'
import { useComponentConfigs } from '../../../../hooks/useComponentConfigs'
import Fields from '../Fields'
import { ComponentContext } from '../../../../contexts/Component'
import Actionbar from './Actionbar'
import { patchBriefing } from '../../../../core/briefingCache'
import { useBriefing } from '../../../../hooks/useBriefing'
import DelayedSpinner from '../../../../components/semantic/DelayedSpinner'
import { AnchorInput } from './AnchorInput'
import { DateInput } from './DateInput'
import { UnsavedFormChanges } from '../../../../components/UnsavedFormChanges'
import { isBaas15Active, getComponentname } from '../../../../helpers/baasHelper'
import { deactivatedComponents } from '../../Overview/helpers'

const ConfigureStyles = styled(Segment)`
  &:not(#Override) {
    margin: 2em 0;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    .componentfields {
      max-width: calc(100% - 3rem - 400px);
      flex-grow: 1;
      margin-right: 3rem;
    }
    .metainformation {
      max-width: 400px;
      img {
        width: 100%;
      }
    }
  }
`
const ComponentCategorySuffix = styled.span`
  font-size: 0.5em;
  opacity: 0.7;
  margin-left: 0.3em;
`

export default function index(props) {
  const isReviewMode = props.step === 'review'
  const isConfigMode = props.step === 'configure'

  const briefingId = props.match.params.id

  const { elements, setElementConfig } = useComponentConfigs(briefingId)
  const [redirectDestination, setRedirectDestination] = useState(false)
  const componentsObj = useContext(ComponentContext)

  // Load component configs and create initial value
  const initialValues = useMemo(
    () =>
      elements.reduce((acc, element) => {
        const { id, config } = element
        if (!config || !Object.keys(config).length) return acc
        return { ...acc, ...dot.dot({ [id]: { ...config } }) }
      }, {}),
    [elements]
  )
  const { handleSubmit, ...formControls } = useForm(initialValues)

  const onSaveForLater = async () => {
    const data = formControls.getValues()
    const json = dot.object(data)
    await setElementConfig(json)
    setRedirectDestination(`/briefings/`)
  }

  const onSaveForIncompletePreview = async () => {
    const data = formControls.getValues()
    const json = dot.object(data)
    await setElementConfig(json)
  }

  const onSaveAndProceed = async () => {
    const data = formControls.getValues()
    const json = dot.object(data)
    /**
     * This order is important here because validatedSuccessfully is
     * reset when we update the element config. It isn't worthwhile
     * to change this up in any way right now with the local cache
     */
    await setElementConfig(json)
    patchBriefing(briefingId, { validatedSuccessfully: true })
    setRedirectDestination(`/briefings/${briefingId}/review`)
  }

  const dynamic = elements.map((element, elementIndex) => {
    const component = find(componentsObj.raw, component => {
      return component.metadata.name === element.name
    })

    const assets = component.metadata.assets
    const assetL = (assets.assetL = isBaas15Active ? assets.L.replace('L.', 'L15.') : assets.L)

    const isPop1new = component.metadata.name === 'pop1new'
    const deactivatedAndInBaas15 = !isPop1new && deactivatedComponents.includes(component.metadata.name)
    return (
      <ConfigureStyles raised disabled={deactivatedAndInBaas15} key={element.id}>
        <div className="componentfields">
          <Header dividing size="huge">
            {deactivatedAndInBaas15 && (
              <b
                style={{
                  fontSize: '20px',
                  display: 'block',
                  color: 'red',
                }}
              >
                not available in BaaS 1.5
              </b>
            )}
            {getComponentname(component.metadata)}
            <ComponentCategorySuffix>{component.metadata.category}</ComponentCategorySuffix>
          </Header>
          {component.hasOwnProperty('options') ? (
            <>
              {component.options.map(field =>
                Fields.fn.createField(
                  field,
                  formControls,
                  element.id,
                  briefingId,
                  isReviewMode,
                  component.metadata.name,
                  elementIndex
                )
              )}
              <AnchorInput
                name={element.id + '.anchor'}
                {...formControls}
                asText={isReviewMode}
                componentIndex={elementIndex}
                componentName={component.metadata.name}
              />
              {isBaas15Active && !deactivatedAndInBaas15 && (
                <DateInput
                  name={element.id + '.visibility'}
                  {...formControls}
                  asText={isReviewMode}
                  componentIndex={elementIndex}
                  componentName={component.metadata.name}
                />
              )}
            </>
          ) : (
            <Fields.NotAvailable />
          )}
        </div>
        <div className="metainformation">
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = `${process.env.REACT_APP_BACKEND_URL}public/${assets.S}`
            }}
            src={`${process.env.REACT_APP_BACKEND_URL}public/${assetL}`}
            alt="s-view"
          />
          {component.metadata.comments && component.metadata.comments.length > 0 && (
            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Card.Meta
                  style={{
                    marginTop: '0.5em',
                    fontWeight: '700',
                    color: 'black',
                  }}
                >
                  Comments:
                </Card.Meta>
                <Card.Description>
                  <List as="ul">
                    {component.metadata.comments.map(r => (
                      <List.Item as="li" key={r}>
                        {r}
                      </List.Item>
                    ))}
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          )}
        </div>
      </ConfigureStyles>
    )
  })

  const [briefing, loading] = useBriefing(briefingId)
  if (loading || !briefing) {
    return <DelayedSpinner />
  }
  if (!briefing.isEditable && !isReviewMode) {
    return <p>Briefing is closed</p>
  }

  return (
    <>
      {!redirectDestination && !isReviewMode && !isConfigMode && (
        <UnsavedFormChanges formControls={formControls} initialValues={initialValues} />
      )}
      {redirectDestination && <Redirect to={redirectDestination} />}
      {!isReviewMode && (
        <Actionbar
          onSaveForLater={onSaveForLater}
          onSaveForIncompletePreview={onSaveForIncompletePreview}
          onSaveAndProceed={handleSubmit(onSaveAndProceed)}
          briefingId={briefingId}
          briefing={briefing}
          {...props}
        />
      )}

      <Container>
        <Form>{dynamic}</Form>
      </Container>
    </>
  )
}
