import React, { useState } from 'react'
import Iframe from 'react-iframe'
import { Popup, Button, Select, Icon, Message } from 'semantic-ui-react'
import styled from 'styled-components'

import { getDouglasPreviewUrl } from '../../../helpers/getDouglasPageLink'
import { usePost } from '../../../hooks/restHooks'

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 3rem;
  button.basic.ui.button {
    box-shadow: initial;
    border: 0;
  }
`

const IframeWrapper = styled.div`
  margin: 0 auto;
  max-width: ${props => props.maxWidth};
`

const breakpointOptions = [
  { key: 's', value: 's', text: 'S-View (iPhone 8, 375px width)' },
  { key: 'sp', value: 'sp', text: 'S-View+ (iPhone S Max, 414px width)' },
  { key: 'm', value: 'm', text: 'M-View (iPad, 768px width)' },
  { key: 'l', value: 'l', text: 'L-View (Desktop device)' },
]

const deviceMapping = {
  s: { width: '375px', height: '667px', icon: 'mobile alternate', label: 'iPhone 8' },
  sp: { width: '414px', height: '896px', icon: 'mobile alternate', label: 'iPhone S Max' },
  m: { width: '768px', height: '1024px', icon: 'tablet alternate', label: 'iPad' },
  l: { width: '100%', height: '800px', icon: 'desktop', label: 'Desktop' },
}

const FrameWrapper = ({ briefing }) => {
  let previewUrl = getDouglasPreviewUrl(briefing)
  const [loading, setLoading] = useState(false)
  const [breakpoint, setBreakpoint] = useState('s')
  const { trigger } = usePost('screenshot')

  const [screenshotErrormessage, setScreenshotErrormessage] = useState('')
  const showScreenshotButton = true //localStorage.getItem('showScreenshotButton') === '1'

  function arrayBufferToBase64(buffer) {
    let binary = ''
    let bytes = new Uint8Array(buffer)
    let len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  const triggerScreenshotDownloadNew = async () => {
    setLoading(true)
    const result = await trigger({ previewUrl })

    // mobile screenshot
    if (!result.image_mobile) {
      if (result.error) {
        setLoading(false)
        setScreenshotErrormessage(result.error)
        if (result.screenshot && result.screenshot.data) {
          const base64StringMobile = arrayBufferToBase64(result.screenshot.data)
          const dataUriError = 'data:image/png;base64,' + base64StringMobile
          var link = document.createElement('a')
          link.setAttribute('href', dataUriError)

          var fileName = `screenshot_error.jpg`
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      return
    }
    const image_mobile = result.image_mobile.data

    const base64StringMobile = arrayBufferToBase64(image_mobile)
    const dataUriMobile = 'data:image/png;base64,' + base64StringMobile

    link = document.createElement('a')
    link.setAttribute('href', dataUriMobile)

    fileName = `screenshot_mobile.jpg`
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()

    // desktop screenshot
    const image_desktop = result.image_desktop.data

    const base64StringDesktop = arrayBufferToBase64(image_desktop)
    const dataUriDesktop = 'data:image/png;base64,' + base64StringDesktop

    link = document.createElement('a')
    link.setAttribute('href', dataUriDesktop)

    fileName = `screenshot_desktop.jpg`
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    setLoading(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <ButtonWrapper>
        <Popup
          trigger={<Button basic size="massive" icon={deviceMapping[breakpoint].icon} />}
          flowing
          hoverable
          position="bottom right"
        >
          <Select
            placeholder="Select breakpoint to show"
            options={breakpointOptions}
            value={breakpoint}
            style={{ width: '300px' }}
            onChange={(_, element) => {
              setBreakpoint(element.value)
            }}
          />
          <br />
        </Popup>
        <br />
        <Message>
          <Message.Content>
            <a href={previewUrl} target="_blank" rel="noreferrer">
              <Button icon labelPosition="left" style={{ marginTop: '1em' }}>
                <Icon name="eye" />
                Open Preview in new Tab
              </Button>
            </a>
            {showScreenshotButton && (
              <>
                <br />
                <Button
                  icon
                  labelPosition="left"
                  style={{ marginTop: '1em' }}
                  onClick={triggerScreenshotDownloadNew}
                  loading={loading}
                >
                  <Icon name="photo" />
                  Download screenshots
                </Button>
                <div
                  style={{ maxWidth: '230px', paddingTop: '10px', color: 'red', fontWeight: 'bold' }}
                  dangerouslySetInnerHTML={{ __html: screenshotErrormessage }}
                />
              </>
            )}
          </Message.Content>
        </Message>
      </ButtonWrapper>
      <IframeWrapper maxWidth={deviceMapping[breakpoint].width}>
        <Iframe url={previewUrl} height="1500px" width="100%" id="preview-iframe" overflow="hidden" frameBorder="0" />
      </IframeWrapper>
    </div>
  )
}

export default FrameWrapper
