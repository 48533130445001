import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Icon, Item } from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import { useBriefing } from '../../../hooks/useBriefing'
import HeaderHr from '../../../components/semantic/HeaderHr'
import { BriefingContext } from '../../../contexts/Briefings'
import Layout from '../../Layout'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import { usePost } from '../../../hooks/restHooks'

import { getListItemComponent } from '../Overview/helpers'

const BriefingWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

export default function index() {
  const params = useParams()
  const briefingId = params.id
  const [briefing, loading] = useBriefing(briefingId)
  const { fetchOne } = useContext(BriefingContext)
  const { trigger } = usePost('briefing/share')

  // Add the currently logged-in user to the share list of the briefing
  const addUserToBriefingShared = async () => {
    await trigger({ briefingId })
    fetchOne(briefingId)
  }

  useEffect(() => {
    addUserToBriefingShared()
  }, [])

  if (loading || !briefing) {
    return <DelayedSpinner />
  }
  const ListItemComponent = getListItemComponent(briefing.status)

  return (
    <Layout showHr={true}>
      <BriefingWrapper>
        <HeaderHr
          style={{ marginBottom: '2rem' }}
          title={'Someone shared a briefing with you!'}
          subheader="It has been added to your briefing list automatically, so you can work on it later."
          icon={'share alternate'}
        />
        <Item.Group relaxed divided style={{ padding: '0 3em' }}>
          <ListItemComponent shareScreen={true} hideLeftButtons={true} {...briefing} />
        </Item.Group>
        <Button icon labelPosition="left" as={Link} to="/briefings">
          <Icon name="undo" />
          Back to overview
        </Button>
      </BriefingWrapper>
    </Layout>
  )
}
