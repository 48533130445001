import React, { useContext } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { hasAccess, FEATURE } from '@douglas/baas-shared'
import ListItem from './ListItem'
import { patchBriefing } from '../../../core/briefingCache'
import { BriefingContext } from '../../../contexts/Briefings'
import { useUserContext } from '../../../contexts/User'
import {
  ListButtonViewConfiguration,
  ListButtonShowPreview,
  ListButtonShowSourcePublish,
  ListButtonPreviewNew,
} from './ListButton'

import { isBaas15Active } from '../../../helpers/baasHelper'

export default function ListItemClosed(props) {
  const { role, country: userCountry, userId, hideLeftButtons } = useUserContext()
  const { refetchAll } = useContext(BriefingContext)
  const { _id, refetch, country, owner } = props

  const reopenAccess = hasAccess(role, FEATURE.reopen, country === userCountry, owner === userId)

  const onNeedsReworkClicked = async () => {
    await patchBriefing(_id, { status: 'rework' })
    refetch()

    /* Normally, when using patchBriefing we dont have to refetch the !closed briefings (using refetchAll from the briefing context).
     * But in this case, the briefings we are patching here are not in the client side cache yet.
     * However patchBriefing only patches state changes client-side without refetching briefings which dont exist client-side.
     * After patching the briefing here, we know, that a new briefing outside the closed-filter exists which needs to be fetched.
     */
    refetchAll()
  }

  const leftButtons = (
    <Button onClick={onNeedsReworkClicked} icon labelPosition="left">
      <Icon name="close" />
      Reopen / Needs rework
    </Button>
  )

  return (
    <ListItem leftButtons={reopenAccess && !hideLeftButtons ? leftButtons : null} {...props}>
      <ListButtonViewConfiguration {...props} />
      {!isBaas15Active ? <ListButtonShowPreview {...props} /> : <ListButtonPreviewNew {...props} />}
      <ListButtonShowSourcePublish {...props} />
    </ListItem>
  )
}
