import React from 'react'
import styled from 'styled-components'
import NodeEmoji from 'node-emoji'

export default function Emoji(props) {
  const EmojiWrapper = styled.div`
    font-size: ${props.size}px;
    line-height: ${props.size}px;
    margin-bottom: ${props.marginBottom}px;
    margin-top: ${props.marginTop}px;
  `
  return <EmojiWrapper>{NodeEmoji.get(props.name)}</EmojiWrapper>
}
