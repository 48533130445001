import styled from 'styled-components'

import React from 'react'

export const LabelDescriptionsWrapper = styled.div`
  opacity: 0.85;
  display: inline-block;
  font-weight: 400;
`

export function Label({ text, description, required, children }) {
  return (
    <label>
      {text}
      {required && '*'}{' '}
      {description && <LabelDescriptionsWrapper>&nbsp;&nbsp;({description})</LabelDescriptionsWrapper>}
      {children}
    </label>
  )
}
