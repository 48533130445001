import React, { useEffect } from 'react'
import { Form, Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { FormInlineError } from './FormInlineError'
import { Label } from './sharedComponents'

export default function Checkbox(props) {
  const {
    name,
    label,
    validation = {},
    initialValue = false,
    errorMessage,
    register,
    unregister,
    errors,
    setValue,
    clearError,
    getDefault,
    asText,
    description,
    componentIndex = 0,
    componentName = 'form',
    originName = name,
  } = props

  const { required } = validation
  const defaultValue = getDefault(name) || initialValue
  useEffect(() => {
    register({ name }, { ...validation })
    setValue(name, defaultValue)

    return () => {
      unregister(name)
    }
  }, [])

  const onChange = (_, element) => {
    clearError(name)
    setValue(name, element.checked)
  }
  return (
    <Form.Field error={!!errors[name]} id={`${componentIndex}-${componentName}-${originName}-checkbox-wrapper`}>
      <Label text={label} required={required} description={asText ? description : ''} />
      {asText && <b>{defaultValue ? 'yes' : 'no'}</b>}
      {!asText && (
        <SemanticCheckbox
          onChange={onChange}
          defaultChecked={defaultValue}
          name={name}
          label={props.description}
          id={`${componentIndex}-${componentName}-${originName}-checkbox`}
        />
      )}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}
