import React from 'react'
import styled from 'styled-components'
import { Table } from 'semantic-ui-react'
import Layout from '../../Layout'
import { useGet } from '../../../hooks/restHooks'
import HeaderHr from '../../../components/semantic/HeaderHr'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  td.locked {
    opacity: 0.4;
  }

  tr.locked td.edit {
    opacity: 1;
  }

  .hidden {
    visibility: hidden;
  }
`

export default function Statistics(props) {
  const { data, loading } = useGet(`admin/modules/all`)
  const modules = data || []

  if (loading) {
    return <DelayedSpinner />
  }

  return (
    <Layout showHr={false} style={{ paddingBottom: '1rem' }}>
      <Container>
        <HeaderHr style={{ marginBottom: '1rem', marginTop: '1rem' }} title={props.title} icon={'puzzle'} />
        <Table celled striped style={{ marginBottom: '3rem' }}>
          <Table.Body>
            <ModuleTable modules={modules} />
          </Table.Body>
        </Table>
      </Container>
    </Layout>
  )
}

function ModuleTable(props) {
  return (
    <>
      <Table.Row active>
        <Table.Cell style={{ textAlign: 'right' }}>
          <b>Modulename</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>Number</b>
        </Table.Cell>
      </Table.Row>
      {props.modules.modules.map(([name, count]) => (
        <Table.Row key={name}>
          <Table.Cell style={{ textAlign: 'right' }}>{name}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{count}</Table.Cell>
        </Table.Row>
      ))}
    </>
  )
}
