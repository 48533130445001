import React from 'react'
import Actionbar from '../ActionbarView'
import { useBriefing } from '../../../hooks/useBriefing'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import ModuleView from './ModuleView'

export default function index({ match }) {
  const briefingId = match.params.id
  const [briefing, loading] = useBriefing(briefingId)

  if (loading || !briefing) {
    return <DelayedSpinner />
  }

  return (
    <div>
      <Actionbar active="modules" match={match} briefing={briefing} loading={loading} />
      <ModuleView briefing={briefing} />
    </div>
  )
}
