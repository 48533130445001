import React, { memo, useState, useContext } from 'react'
import { Grid, Button, Icon, Header, Message, Checkbox, Modal } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { isNewShop, generateBriefingIdForHybris } from '@douglas/baas-shared'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import { useGet } from '../../../hooks/restHooks'
import { UserContext } from '../../../contexts/User'
import { isBaas15Active } from '../../../helpers/baasHelper'

const SuccessWrapper = styled.span`
  font-size: 17px;
  font-weight: bold;
  color: #48c1b3;
`

const CodeBox = memo(props => {
  const [copied, setCopied] = useState(false)
  const { code, title, large, disabled = true, type = 'textarea' } = props
  let style = { width: '100%' }
  if (large) {
    style = { ...style, height: '130px' }
  }

  if (!code) {
    return null
  }

  return (
    <div style={{ marginTop: '2rem' }}>
      <Header as="h3">{title}</Header>
      <Grid>
        <Grid.Row>
          <Grid.Column width="12">
            {type === 'textarea' && <textarea style={style} defaultValue={code} disabled={disabled} />}
            {type === 'input' && <input style={style} defaultValue={code} disabled={disabled} />}
          </Grid.Column>
          <Grid.Column width="2">
            <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
              <Button primary>Copy</Button>
            </CopyToClipboard>
            {copied && (
              <SuccessWrapper>
                <Icon name="thumbs up" />
              </SuccessWrapper>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
})

function Code(props) {
  const emptyResponse = { data: { error: '', htmlContent: '' }, loading: false }
  const { briefingId, briefing } = props
  const { data, loading } = !isBaas15Active ? useGet(`briefing/${briefingId}/code`) : emptyResponse
  const { isAdmin } = useContext(UserContext)
  const isNewShopBriefing = isNewShop(briefing.country)
  const [showCode, setShowCode] = useState(false)

  if ((loading || !data || !briefing) && !isBaas15Active) {
    return <DelayedSpinner />
  }

  if (data.error) {
    return (
      <Message negative>
        <Message.Header>Can not load code</Message.Header>
        <p>The build of this page was not successful. Please talk to Webdev.</p>
        <p>{data.error}</p>
      </Message>
    )
  }

  let { headContent = '', htmlContent, jsContent, ymlContent } = data
  // eslint-disable-next-line no-useless-escape
  const absUrlRegex = /https:\/\/(?:(?:dev|prd)\-baas\.pub\.az\-we\.dglecom\.net|baas\.dglecom\.net|www\.douglas\.(?:[bd]e|ch|[np]l|[ai]t))/g

  if (htmlContent) {
    htmlContent = htmlContent.replaceAll(absUrlRegex, '')
  }
  if (ymlContent) {
    ymlContent = ymlContent.replaceAll(absUrlRegex, '')
  }
  // In case of not showing a PDP or POP, concatinate the html and scrip code into one text block
  // since that is the way the HMC expects the page to be inserted.
  if (!['pdp', 'pop', 'pop_cp'].includes(briefing.type)) {
    if (briefing.type === 'contentpages' && isNewShopBriefing) {
      // keep htmlContent
    } else if (briefing.type === 'promos' && isNewShopBriefing) {
      // keep htmlContent
    } else {
      htmlContent = `${htmlContent}\r\n<script>${jsContent}</script>`
    }
  }

  if ((briefing.type === 'contentpages' && !isNewShopBriefing) || (briefing.type === 'promos' && !isNewShopBriefing)) {
    jsContent = null
  }

  if (isNewShopBriefing) {
    jsContent = `<jsc>${jsContent}</jsc>`

    if (htmlContent.search('liveshopping') > 0) {
      jsContent += '<jsc src="https://sdk.livebuy.io/livebuy-sdk.js"></jsc>'
      htmlContent = htmlContent.replace('<script src="https://sdk.livebuy.io/livebuy-sdk.js"></script>', '')
    }
  }

  return (
    <React.Fragment>
      {(['pop', 'pdp', 'pop_cp'].includes(briefing.type) ||
        (briefing.type === 'contentpages' && isNewShopBriefing) ||
        (briefing.type === 'promos' && isNewShopBriefing)) && (
        <CodeBox
          type="input"
          title="Element ID"
          code={generateBriefingIdForHybris(briefing.country, briefing.language, briefing.identifier)}
        />
      )}
      {isNewShopBriefing && !isBaas15Active && (
        <Checkbox
          style={{ marginBottom: '10px' }}
          label="Show code (please always use the 'Publish to Hybris'-Button because Smart-Edit can cause errors with copy&paste)"
          onClick={e => setShowCode(!showCode)}
        />
      )}
      {isNewShopBriefing && isBaas15Active && (
        <Modal
          trigger={
            <Button icon labelPosition="left" size="mini">
              <Icon name="code" />
              Show Code
            </Button>
          }
          header={'JSON Data of Briefing ' + briefingId}
          content=<iframe
            style={{ border: 'none' }}
            title="code"
            width="100%"
            height="600px"
            src={'/api/preview/getnice/' + briefingId}
          />
          actions={[{ key: 'done', content: 'Done', positive: true }]}
        />
      )}
      {(showCode || !isNewShopBriefing) && (
        <div>
          {['pop', 'pdp', 'pop_cp', 'contentpages', 'promos'].includes(briefing.type) && isNewShopBriefing && (
            <CodeBox title="Head scripts" code={headContent.trim()} />
          )}
          <CodeBox title="Content HTML" code={htmlContent} large={true} />
          <CodeBox title="Script" code={jsContent} large={true} />
          {isAdmin && <CodeBox title="Config file (developers-only)" code={ymlContent} large={true} />}
        </div>
      )}
    </React.Fragment>
  )
}

export default memo(Code)
