import { useState, useEffect } from 'react'
import { useLocalStorage } from './useLocaleStorage'

/* Returns a boolean which:
 * - Starts with false
 * - turns into true after props.startDelay ms
 * - turns into false again after props.duration ms
 *
 * Limits the number of occurences based on a locale storage counter. Set this with props.numberOfOccurences.
 * If the props.numberOfOccurences is exceeded, the returned boolean keeps the default value at all times.
 * Set a unique props.id to not share counters between multiple usecases of useHint.
 *
 * Intended be used for timing tooltips and UI hints.
 */
export default function useTimedBoolean({
  defaultValue = false,
  startDelay = 5000,
  duration = 10000,
  id = 'default',
  numberOfOccurences = -1,
}) {
  const [show, setShow] = useState(defaultValue)
  const [occurences, setOccurences] = useLocalStorage('useHint-' + id, 0)

  useEffect(() => {
    let timeouts = []
    if (numberOfOccurences === -1 || occurences < numberOfOccurences) {
      timeouts.push(
        setTimeout(() => {
          setShow(!defaultValue)
          if (duration > -1) {
            timeouts.push(
              setTimeout(() => {
                setShow(defaultValue)
              }, duration)
            )
          }
        }, startDelay)
      )
    }
    if (numberOfOccurences !== -1) {
      setOccurences(occurences + 1)
    }
    return () => {
      timeouts.forEach(t => clearTimeout(t))
    }
  }, [])

  return show
}
