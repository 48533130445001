import React, { useState, useEffect } from 'react'
import { Button, Icon, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import { times, startCase } from 'lodash'
import { generateHash } from '../../../../helpers/generateHash'
//eslint-disable-next-line import/no-cycle
import { createField } from './functions'

const RepeatableWrapper = styled.div`
  margin-top: 2rem;
  border-left: 20px solid #d8d8d8;
  margin-bottom: 1rem;
  .upload-container {
    border: 3px solid #cacbcd;
  }
`

const HeadlineSuffix = styled.span`
  font-size: 0.7em;
  opacity: 0.7;
  margin-left: 1rem;
`

const RepeatableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-bottom: 5px solid #d3d3d4;
  padding: 1rem;
`

const ElementRowWrapper = styled.div`
  position: relative;
  background-color: #f2f2f2;
  border-bottom: 5px solid #d3d3d4;
  .delete-button {
    position: absolute;
    top: 0px;
    right: 0px;
    .ui.basic.button {
      border: 0 !important;
      box-shadow: initial !important;
      &:hover,
      &:active {
        background: transparent none !important;
      }
    }
  }
  .element-wrapper {
    padding: 1.5rem;
  }
`

function ElementRow({ onDelete, children, asText, deleteButtonEnabled }) {
  return (
    <ElementRowWrapper>
      <div className="element-wrapper">{children}</div>
      {!asText && deleteButtonEnabled && (
        <div className="delete-button">
          <Button icon basic onClick={onDelete}>
            <Icon name="close" />
          </Button>
        </div>
      )}
    </ElementRowWrapper>
  )
}

export default function Repeatable(props) {
  const { name, register, setValue, getDefault, validation = {}, asText, description, briefingId } = props
  const fieldIdsString = getDefault(name)
  const [fieldIds, setFieldIds] = useState(fieldIdsString ? fieldIdsString.split(',') : [])

  useEffect(() => {
    register({ name }, { ...validation })
    setValue(name, fieldIdsString)
  }, [])

  useEffect(() => {
    setValue(name, fieldIds.join(','))
  }, [fieldIds])

  let deleteButtonEnabled = true
  if (validation.minElements && fieldIds.length <= validation.minElements) {
    deleteButtonEnabled = false

    const elementCountToAdd = validation.minElements - fieldIds.length
    if (elementCountToAdd > 0) {
      setFieldIds([...fieldIds, ...times(elementCountToAdd, () => generateHash(6))])
    }
  }

  // If minElements equals maxElements, we dont have to show add or remove buttons
  let fixedAmountOfElements = false
  if (validation.maxElements && validation.minElements && validation.maxElements === validation.minElements) {
    fixedAmountOfElements = true
  }

  const header = (
    <RepeatableHeader>
      <div style={{ width: '100%', paddingRight: '2rem' }}>
        <Header dividing={description !== ''}>
          {startCase(props.label)}
          {!fixedAmountOfElements && (
            <HeadlineSuffix>
              ({fieldIds.length} of {validation.maxElements ? validation.maxElements : 'unlimited'}
              {validation.minElements && validation.minElements > 1 && `, min: ${validation.minElements}`})
            </HeadlineSuffix>
          )}
        </Header>
        {description && <div>{description}</div>}
      </div>
      {!asText && !fixedAmountOfElements && (
        <div>
          <Button
            icon
            disabled={validation.maxElements && fieldIds.length >= validation.maxElements}
            onClick={() => {
              if (validation.maxElements && fieldIds.length >= validation.maxElements) return
              setFieldIds([...fieldIds, generateHash(6)])
            }}
            labelPosition="left"
          >
            <Icon name="plus" />
            Add
          </Button>
        </div>
      )}
    </RepeatableHeader>
  )

  const onDeleteFn = id => () => {
    setFieldIds(fieldIds.filter(fieldId => fieldId !== id))
  }

  const elements = fieldIds.map((fieldId, index) => {
    const prefix = `${props.prefix}.${props.name || props.label}Array${index}.${fieldId}`
    //eslint-disable-next-line
    const isDeleteButtonEnabled = deleteButtonEnabled && index == fieldIds.length - 1
    return (
      <ElementRow key={prefix} onDelete={onDeleteFn(fieldId)} asText={asText} deleteButtonEnabled={isDeleteButtonEnabled}>
        {props.fields.map(field =>
          createField(
            field,
            { ...props, description: '', ...field },
            prefix,
            briefingId,
            asText,
            props.componentName,
            `${props.componentIndex}-${index}`
          )
        )}
      </ElementRow>
    )
  })

  return (
    <RepeatableWrapper>
      {props.label && header}
      {elements}
    </RepeatableWrapper>
  )
}
