import React from 'react'
import { Button } from 'semantic-ui-react'
import ImageZoom from 'react-medium-image-zoom'

export const AssetRepresentation = ({ assetUrl, onDelete = () => {}, asText }) => {
  if (!assetUrl) return <></>
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
      <div>
        <ImageZoom
          image={{
            src: assetUrl,
            style: { maxWidth: '100px', maxHeight: '100px' },
          }}
          zoomImage={{
            src: assetUrl,
          }}
        />
      </div>
      <input type="text" value={assetUrl} />
      <div>{!asText && <Button icon="trash" color="red" onClick={onDelete} className="test-delete-button" />}</div>
    </div>
  )
}
