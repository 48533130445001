import React from 'react'
import { Popup, Image } from 'semantic-ui-react'
import animations from '../../../images/tooltips/Animations.gif'

export default function Animation() {
  return (
    <div style={{ width: '600px' }}>
      <Popup.Header>Animations example</Popup.Header>
      <Popup.Content>
        <Image src={animations} className="tooltip" />
      </Popup.Content>
    </div>
  )
}
