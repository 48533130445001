import React from 'react'
import styled from 'styled-components'
import { Card, List, Divider, Image, Popup } from 'semantic-ui-react'
import { animated } from 'react-spring'
import { startCase } from 'lodash'
import ContentModule from '../../components/ContentModule'
import Animation from './tooltips/AnimationFeature'
import Overlay from './tooltips/OverlayFeature'
import TabletImage from '../../images/Tablet.png'
import MobileImage from '../../images/Mobile.png'
import DesktopImage from '../../images/Desktop.png'
import { hasElements } from '../../helpers/array'
import { deactivatedComponents } from '../Briefings/Overview/helpers'
import { isBaas15Active } from '../../helpers/baasHelper'

const CssGrid = styled.div`
  display: grid;
  grid-template-columns: 16rem calc(100% - 16rem);
  grid-template-areas: '. .' '. .';
  grid-gap: 1em;
  grid-row-gap: 5em;
  img {
    object-fit: cover;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  right: -125px;
  img.ui.image {
    width: auto;
    margin-right: 0.5em;
    display: inline-block;
    z-index: 1;
  }
`

const ImageWrapperS = styled.div`
  position: absolute;
  left: -32px;
  img.ui.image {
    width: auto;
    margin-right: 0.5em;
    display: inline-block;
    z-index: 1;
  }
`

function ContentModuleInfoBox({ metadata }) {
  const { requirements, comments, features, requirements15 } = metadata
  const requirementsToShow = isBaas15Active && requirements15 ? requirements15 : requirements

  return (
    <Card>
      <Card.Content>
        {isBaas15Active && metadata.displayname ? (
          <Card.Header style={{ fontSize: metadata.name.length > 7 ? '18px' : '28px' }}>
            {metadata.displayname} ({metadata.name})
          </Card.Header>
        ) : (
          <Card.Header style={{ fontSize: metadata.name.length > 7 ? '28px' : '40px', textTransform: 'uppercase' }}>
            {metadata.name}
          </Card.Header>
        )}

        {hasElements(requirementsToShow) && (
          <React.Fragment>
            <Card.Meta style={{ marginTop: '0.5em', fontWeight: '700', color: 'black' }}>Deliverable:</Card.Meta>
            <Card.Description>
              <List as="ul">
                {requirementsToShow.map(requirement => (
                  <List.Item as="li" key={requirement}>
                    {requirement}
                  </List.Item>
                ))}
              </List>
            </Card.Description>
          </React.Fragment>
        )}
        {hasElements(comments) && (
          <React.Fragment>
            <Card.Meta style={{ marginTop: '0.5em', fontWeight: '700', color: 'black' }}>Comments:</Card.Meta>
            <Card.Description>
              <List as="ul">
                {comments.map(comment => (
                  <List.Item as="li" key={comment}>
                    {comment}
                  </List.Item>
                ))}
              </List>
            </Card.Description>
          </React.Fragment>
        )}
        {hasElements(features) && (
          <React.Fragment>
            <Card.Meta style={{ marginTop: '1em', fontWeight: '700', color: 'black' }}>Features:</Card.Meta>
            <Card.Content extra>
              <List as="ul">
                {features.map(feature => (
                  <List.Item as="li" key={feature}>
                    <Popup trigger={<span>{startCase(feature)}</span>}>
                      {feature.toLowerCase() === 'animations' && <Animation />}
                      {feature.toLowerCase() === 'overlay' && <Overlay />}
                    </Popup>
                  </List.Item>
                ))}
              </List>
            </Card.Content>
          </React.Fragment>
        )}
      </Card.Content>
    </Card>
  )
}

export default function ComponentRepresentation({ metadata, opacity }) {
  const isDeactivated = deactivatedComponents.includes(metadata.name)

  return isDeactivated ? null : (
    <animated.div style={opacity}>
      <Divider />
      <CssGrid>
        <div style={{ position: 'relative' }}>
          <ContentModuleInfoBox metadata={metadata} />
          <ImageWrapperS>
            <Image src={MobileImage} />
          </ImageWrapperS>
          <ContentModule assets={metadata.assets} mobile={true} />
        </div>
        <div style={{ position: 'relative' }}>
          <ImageWrapper>
            <Image src={DesktopImage} />
            <Image src={TabletImage} />
          </ImageWrapper>
          <ContentModule assets={metadata.assets} />
        </div>
      </CssGrid>
    </animated.div>
  )
}
