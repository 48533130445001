import React, { useEffect } from 'react'
import { Form, Dropdown } from 'semantic-ui-react'
import { FormInlineError } from './FormInlineError'
import { Label } from './sharedComponents'

export default function Select(props) {
  const {
    multiple = false,
    options,
    name,
    validation = {},
    errorMessage,
    label,
    register,
    unregister,
    errors,
    setValue,
    clearError,
    getDefault,
    asText,
    description,
    disabled,
    search = false,
    componentIndex = 0,
    componentName = 'form',
    originName = name,
    selectedValue,
  } = props
  const { required } = validation
  /**
   * Getting edit-mode default values from hook-form lib.
   * We check if the provided value is an array and if it is we get
   * the value directly so it mimicks the state we get when picking the first
   * option in the dropdown
   * */
  const normalizedDefault = Array.isArray(props.default) ? props.default[1] : props.default
  let defaultValue = getDefault(name, normalizedDefault)

  useEffect(() => {
    register({ name }, { ...validation })
    if (defaultValue) {
      if (multiple) {
        setValue(name, defaultValue.join(','))
      } else {
        setValue(name, defaultValue)
      }
    }

    return () => {
      unregister(name)
    }
  }, [])

  useEffect(() => {
    setValue(name, selectedValue ? selectedValue : defaultValue)
  }, [selectedValue])

  const onMultipleChange = (_, element) => {
    clearError(name)
    setValue(name, element.value.join(','))
    if (props.hookIntoValueState) {
      props.hookIntoValueState(element.value)
    }
  }

  const isHexColor = color => {
    if (!color || typeof color !== 'string') return false

    // Validate hex values
    if (color.substring(0, 1) === '#') color = color.substring(1)

    switch (color.length) {
      case 3:
        return /^[0-9A-F]{3}$/i.test(color)
      case 6:
        return /^[0-9A-F]{6}$/i.test(color)
      case 8:
        return /^[0-9A-F]{8}$/i.test(color)
      default:
        return false
    }
  }

  const onChange = (_, element) => {
    clearError(name)
    setValue(name, element.value)
    if (props.hookIntoValueState) {
      props.hookIntoValueState(element.value)
    }
  }

  if (asText) {
    var textRepresentations = []
    let values
    if (multiple) {
      values = defaultValue.split(',')
    } else {
      values = [defaultValue]
    }
    for (let v of values) {
      const selectedOption = options.filter(o => o.value === v)
      if (selectedOption && Array.isArray(selectedOption) && selectedOption.length > 0) {
        textRepresentations.push(options.filter(o => o.value === v)[0].text)
      }
    }
  }

  if (defaultValue) {
    defaultValue = multiple ? defaultValue.split(',') : defaultValue
  }

  options.map(option => {
    if (option.value && isHexColor(option.value.toString())) {
      option.text = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              marginRight: '10px',
              width: '60px',
              height: '20px',
              backgroundColor: options[0].value,
            }}
          ></div>
          <span>{option.text}</span>
        </div>
      )
    }
  })

  return (
    <Form.Field error={!!errors[name]} id={`${componentIndex}-${componentName}-${originName}-text-wrapper`}>
      <Label required={required} text={label} description={description} />
      {asText && <b>{textRepresentations.join(', ')}</b>}
      {!asText && (
        <Dropdown
          placeholder="Please select..."
          fluid
          search={search}
          multiple={multiple}
          selection
          disabled={disabled}
          options={options}
          onChange={multiple ? onMultipleChange : onChange}
          defaultValue={defaultValue}
          {...(selectedValue ? { value: selectedValue } : {})}
          id={`${componentIndex}-${componentName}-${originName}-select`}
        />
      )}
      {asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}
