import React, { useContext } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import DraggableContentModule from './DraggableContentModule'
import { ComponentContext } from '../../../contexts/Component'

const ContentWrapper = styled.div`
  text-align: center;
  width: calc(100% / 5 * 4);
  display: inline-flex;

  .modules-container {
    width: 100%;
    min-height: 50em;
  }

  .draggable:hover .close.icon {
    opacity: 1;
  }

  .close.icon {
    position: relative;
    color: #c3eae4;
    height: 0em;
    width: 0em;
    cursor: pointer;
    opacity: 0;
  }

  .remove-button-mobile {
    top: 0.3em;
    left: 12.3em;
  }

  .remove-button {
    top: 0em;
    left: 17.6em;
  }
  .contentModule {
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #c3eae4;
    }
  }
`
export default function WorkingSurface({ elements, removeModule, mobile }) {
  const { getComponentByName } = useContext(ComponentContext)
  const renderElements = elements.map(e => {
    const componentPrototype = getComponentByName(e.name)
    return {
      ...e,
      metadata: { assets: componentPrototype.metadata.assets },
    }
  })
  return (
    <ContentWrapper>
      <Droppable droppableId="content">
        {provided => (
          <div className="modules-container" ref={provided.innerRef} {...provided.droppableProps}>
            {renderElements.map((item, index) => (
              <DraggableContentModule
                location="workzone"
                key={item.id}
                item={item}
                device="content"
                mobile={mobile}
                index={index}
                removeModule={removeModule}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </ContentWrapper>
  )
}
