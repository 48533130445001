import styled from 'styled-components'

export const UploadContainer = styled.div`
  border: 3px solid #f4f2f2;
  padding: 1.5rem 2rem 2rem 2rem;
`
export const Link = styled.a`
  margin-top: 6px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
`
