import React from 'react'
import { isNewShop } from '@douglas/baas-shared'
import styled from 'styled-components'

export const appendExternalScript = (filepath, delay = false) => {
  const scriptTag = document.createElement('script')
  scriptTag.type = 'text/javascript'
  scriptTag.async = true
  scriptTag.src = filepath
  if (delay) {
    setTimeout(() => document.body.appendChild(scriptTag), delay)
  } else {
    document.body.appendChild(scriptTag)
  }
}

export const appendScriptTag = (src, delay = false) => {
  const scriptTag = document.createElement('script')
  scriptTag.type = 'text/javascript'
  scriptTag.async = true
  scriptTag.innerHTML = src
  if (delay) {
    setTimeout(() => document.body.appendChild(scriptTag), delay)
  } else {
    document.body.appendChild(scriptTag)
  }
}

export const loadScripts = pagetype => {
  appendExternalScript(`//code.jquery.com/jquery-1.11.0.min.js`)
  //appendExternalScript(`//code.jquery.com/jquery-migrate-1.2.1.min.js`)
  appendScriptTag('var $$ = $', 450)

  switch (pagetype) {
    case 'pdp':
    case 'pop':
    case 'pop_cp':
      appendExternalScript(`${process.env.PUBLIC_URL}/previewenv/slick.js`, 500)
      break
    default:
      break
  }
}

// Set styles on the page wrapper to match the production environment for the specific page type
const wrapper = {
  pop: { maxWidth: '1312px', margin: '0 auto 0', paddingTop: '2em' },
  contentpageNav: { maxWidth: '979px', margin: '0 auto 0', paddingTop: '2em' },
  contentpageFull: { maxWidth: '1312px', margin: '0 auto 0', paddingTop: '2em' },
  promos: { margin: '0 auto 0', paddingTop: '2em' },
}

export const getWrapperStyle = (pagetype, navigation) => {
  switch (pagetype) {
    case 'pdp':
    case 'pop':
    case 'pop_cp':
      return wrapper.pop
    case 'contentpages':
      if (navigation === 'nav') {
        return wrapper.contentpageNav
      }
      return wrapper.contentpageFull
    case 'promo':
      return wrapper.promos
    default:
      break
  }
}

const proxyPath = process.env.REACT_APP_BACKEND_URL + 'proxy'
export const getCPGEnvironment = (country, language) => {
  const lowercaseFirstCountry = country.split(',')[0].toLowerCase()

  if (isNewShop(country)) {
    return `window.doguide = {env: "production", productApiUrl: "${proxyPath}/https://douglas.${lowercaseFirstCountry}/jsapi/v2/products/<productId>?lang=${language}", dynamicVersion: 1}`
  }
  return `window.doguide = {env: "production", productApiUrl: "${proxyPath}/https://douglas.${lowercaseFirstCountry}/p/compact-product/", dynamicVersion: 1}`
}

const PromoPreviewFix = styled.div`
  .swiper-wrapper-60-height {
    max-height: 533px;
  }
  .swiper-wrapper-80-height {
    max-height: 700px;
  }
  .swiper-wrapper-100-height {
    max-height: 850px;
  }
`
export const CssPreviewFixes = props => {
  const { pagetype } = props
  switch (pagetype) {
    case 'promos':
      return <PromoPreviewFix {...props} />
    default:
      return <React.Fragment>{props.children}</React.Fragment>
  }
}
