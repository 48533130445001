import React, { createContext } from 'react'
import { groupBy } from 'lodash'
import { useBriefingList } from '../hooks/useBriefingList'
import { useUserContext } from './User'

export const BriefingContext = createContext()

export default function BriefingContextProvider({ children }) {
  const { userId, role } = useUserContext()
  const [briefings, loading, fetchOne, refetchAll] = useBriefingList(userId, role)

  const groupedBriefings = groupBy([...briefings.values()], briefing => briefing.status)

  return (
    <BriefingContext.Provider value={{ fetchOne, loading, briefings, groupedBriefings, refetchAll }}>
      {children}
    </BriefingContext.Provider>
  )
}
