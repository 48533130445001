import React, { useState, useContext, useMemo, useCallback } from 'react'
import { Menu, Input, Select, Button, Icon, Popup, Checkbox, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { hasAccess, FEATURE, countryOptions } from '@douglas/baas-shared'
import Layout from '../../Layout'
import CategoryView from './CategoryView'
import CategoryViewClosed from './CategoryViewClosed'
import { BriefingContext } from '../../../contexts/Briefings'
import { memoFilterGroupedBriefings } from '../../../helpers/filters/memoFilterBriefings'
import { useLocalStorage, useDebouncedLocaleStorage } from '../../../hooks/useLocaleStorage'
import { useUserContext } from '../../../contexts/User'

const FilterStyles = styled.div`
  > * {
    margin-top: 10px;
    width: 100%;
  }
  .header {
    font-weight: bold;
    margin-top: 22.5px;
    margin-bottom: -5px;
  }
`
const ActionButtonWrapper = styled.div`
  .ui.icon.button {
    margin-top: 10px;
    display: block;
    width: 100%;
    text-align: left;
  }
`

const typeOptions = [
  { key: 'all', value: 'all', text: 'Show all types' },
  { key: 'contentpages', value: 'contentpages', text: 'Contentpage' },
  { key: 'promos', value: 'promos', text: 'Promotion' },
  { key: 'pdp', value: 'pdp', text: 'PDP+ content' },
  { key: 'pop', value: 'pop', text: 'POP+ content' },
  { key: 'pop_cp', value: 'pop_cp', text: 'POP+ without products' },
]

const calculateLabelNumber = (groups, name) => Array.isArray(groups[name]) && groups[name].length > 0 && groups[name].length

export default function index(props) {
  const { groupedBriefings, refetchAll } = useContext(BriefingContext)
  const {
    history: {
      location: { pathname },
    },
    match: { params },
  } = props
  const [refetchLoading, setRefetchLoading] = useState(false)
  const { userId, role } = useUserContext()
  const [searchterm, tempSearchterm, setSearchterm, isSearchDirty, resetSearch] = useDebouncedLocaleStorage(
    'baas-filter-searchterm',
    '',
    500
  )
  const [reporter, tempReporter, setReporter, isReporterDirty, resetReporter] = useDebouncedLocaleStorage(
    'baas-filter-reporter',
    '',
    500
  )
  const [pagetype, setPagetype, isPagetypeDirty, resetPagetype] = useLocalStorage('baas-filter-pagetype', 'all')
  const [country, setCountry, isCountryDirty, resetCountry] = useLocalStorage('baas-filter-country', 'all')
  const [ownBriefingsFilter, setOwnBriefings, isOwnerDirty, resetOwner] = useLocalStorage('baas-filter-own', false)
  const needsOwnBriefingsFilter = hasAccess(role, FEATURE.list, true, false)
  const ownBriefings = ownBriefingsFilter && needsOwnBriefingsFilter
  const isDirty = isSearchDirty || isReporterDirty || isPagetypeDirty || isCountryDirty || isOwnerDirty

  // Fetch count of briefings for server-side filtered closed-status.
  // The count is also passed to paginated <CategoryViewClosed> to render correct amount of pages.
  //const { data, trigger, loading } = usePost(`briefing/archive/count`)
  //const closedBriefingsCount = data && data.count ? data.count : false
  const closedBriefingsCount = false
  const loading = false

  // Refetch closed-briefings count after every filter change
  /*
  useEffect(() => {
    trigger({
      searchterm,
      reporter,
      pagetype: pagetype === 'all' ? undefined : pagetype,
      country: country === 'all' ? undefined : country,
      ownBriefings,
    })
  }, [searchterm, reporter, pagetype, country, ownBriefings])
  */

  const resetFilters = useCallback(() => {
    resetSearch()
    resetPagetype()
    resetCountry()
    resetOwner()
    resetReporter()
  }, [])

  // Filter all client-side cached briefings (all status != closed)
  const groupedFilteredBriefings = useMemo(
    memoFilterGroupedBriefings(groupedBriefings, pagetype, reporter, searchterm, country, ownBriefings, userId),
    [searchterm, reporter, pagetype, groupedBriefings, country, ownBriefings, userId]
  )

  // Configure left side filter menu (See <Layout> component)
  const menuItems = useMemo(
    () => [
      {
        path: '/briefings/open',
        name: 'Draft',
        label: calculateLabelNumber(groupedFilteredBriefings, 'open'),
        new: false, // "true" highlights the count bubble with green color.
      },
      {
        path: '/briefings/rework',
        name: 'Needs rework',
        label: calculateLabelNumber(groupedFilteredBriefings, 'rework'),
        new: false,
      },
      {
        path: '/briefings/review',
        name: 'In review',
        label: calculateLabelNumber(groupedFilteredBriefings, 'review'),
        new: false,
      },

      {
        path: '/briefings/ready',
        name: 'Ready for go-live',
        label: calculateLabelNumber(groupedFilteredBriefings, 'ready'),
        new: false,
      },
      {
        path: '/briefings/closed',
        name: 'Closed',
        label: closedBriefingsCount,
        info:
          'For performance reasons we do not automatically show the number of closed briefings anymore. You can still search for closed briefings if you click on "Closed"',
        new: false,
      },
    ],
    [groupedFilteredBriefings, closedBriefingsCount]
  )

  const pagetypeChanged = (_, element) => {
    setPagetype(element.value)
  }

  const countryChanged = (_, element) => {
    setCountry(element.value)
  }

  const refetchBriefings = async () => {
    setRefetchLoading(true)
    await refetchAll()
    setRefetchLoading(false)
  }

  const Filters = (
    <FilterStyles>
      <Menu.Header>Filter</Menu.Header>
      <Input
        icon="search"
        placeholder="Search..."
        value={tempSearchterm}
        onChange={(_, data) => {
          setSearchterm(data.value)
        }}
        id="sidebar-filter-searchterm-input"
      />
      <Select
        placeholder="Select page type"
        options={typeOptions}
        value={pagetype}
        onChange={pagetypeChanged}
        id="sidebar-filter-pagetype-select"
      />
      <Select
        placeholder="Select country"
        options={countryOptions}
        value={country}
        onChange={countryChanged}
        id="sidebar-filter-country-select"
      />
      <Input
        icon="user"
        placeholder="Author username..."
        value={tempReporter}
        onChange={(_, data) => {
          setReporter(data.value)
        }}
        id="sidebar-filter-author-input"
      />
      {needsOwnBriefingsFilter && (
        <Checkbox
          label="Only my briefings"
          checked={ownBriefings}
          onChange={() => {
            setOwnBriefings(!ownBriefings)
          }}
          id="sidebar-filter-my-briefings-checkbox"
        />
      )}

      {isDirty && (
        <Message
          style={{ marginTop: '20px' }}
          warning
          header="Filters active"
          content={
            <>
              You might not see all briefings.
              <Button style={{ marginTop: '10px' }} onClick={resetFilters} basic compact id="sidebar-reset-filters-button">
                Reset all filters
              </Button>
            </>
          }
        />
      )}
      <Menu.Header>Actions</Menu.Header>
      <ActionButtonWrapper>
        <Button primary as={Link} to="/briefings/new" icon labelPosition="left" id="sidebar-new-button">
          <Icon name="add" />
          Create new
        </Button>

        <Popup
          trigger={
            <Button icon labelPosition="left" onClick={refetchBriefings} loading={refetchLoading} id="sidebar-refresh-button">
              <Icon name="refresh" />
              Refresh
            </Button>
          }
          content="Reload briefings from the server. Handy if a coworker changed something in a briefing you want to use now."
          inverted
          position="right center"
        />
      </ActionButtonWrapper>
    </FilterStyles>
  )

  return (
    <Layout menuItems={menuItems} pathname={pathname} showHr={true} additionalMenus={Filters}>
      {params.category !== 'closed' && (
        <CategoryView
          groupedBriefings={groupedFilteredBriefings}
          category={params.category}
          searchterm={searchterm}
          reporterFilter={reporter}
          {...props}
        />
      )}
      {params.category === 'closed' && (
        <CategoryViewClosed
          searchterm={searchterm}
          reporter={reporter}
          ownBriefings={ownBriefings}
          pagetype={pagetype}
          country={country}
          closedBriefingsCount={closedBriefingsCount}
          loading={loading}
          {...props}
        />
      )}
    </Layout>
  )
}
