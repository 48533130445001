import React, { Component } from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

export default class Overlay extends Component {
  state = {
    triggerCloseRedirect: false,
  }

  static defaultProps = {
    children: <React.Fragment />,
    allowUserClose: true,
    actions: <React.Fragment />,
    headline: '',
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    allowUserClose: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    location: PropTypes.object,
    headline: PropTypes.string,
  }

  constructor() {
    super()
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    /*this.backUrl = this.props.location.pathname
          .split('/')
          .filter(param => param !== this.props.name)
          .join('/')*/
    this.regex = new RegExp(`${this.props.name}.*`)
  }

  handleClose = () => {
    if (this.props.allowUserClose) {
      this.setState({
        triggerCloseRedirect: true,
      })
    }
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { closeOverlay: this.handleClose })
    )

    return (
      <React.Fragment>
        {this.state.triggerCloseRedirect && <Redirect to={this.props.location.pathname.replace(this.regex, '')} />}
        <Modal open={true}>
          <Modal.Header className="model-header">
            {this.props.headline}
            {this.props.allowUserClose && (
              <Button
                icon
                size="mini"
                style={{ position: 'absolute', top: '10px', right: '10px' }}
                onClick={this.handleClose}
              >
                <Icon name="close" />
              </Button>
            )}
          </Modal.Header>

          {childrenWithProps}
        </Modal>
      </React.Fragment>
    )
  }
}
