import { useState, useEffect } from 'react'
import { usePost } from './restHooks'
import { patchBriefingLocally } from '../core/briefingCache'

let configCache = {}

export function useComponentConfigs(briefingId) {
  const { trigger: triggerFetch, loading: loadingFetch } = usePost('briefing/config/fetch')
  const { trigger: triggerUpdateElements, loading: loadingElementsPost } = usePost('briefing/config/update/elements')
  const { trigger: triggerUpdateOrder, loading: loadingOrderPost } = usePost('briefing/config/update/order')
  const [state, setState] = useState({ elements: {}, order: [] })
  const loading = loadingFetch || loadingElementsPost || loadingOrderPost

  const fetchBriefingConfig = async briefingId => {
    if (configCache[briefingId]) {
      return configCache[briefingId]
    }

    const { config } = await triggerFetch({ briefingId })
    if (typeof config.elements !== 'object') {
      config.elements = {}
    }
    if (!Array.isArray(config.order)) {
      config.order = []
    }
    configCache = { ...configCache, [briefingId]: config }

    return config
  }

  useEffect(() => {
    if (!configCache[briefingId]) {
      fetchBriefingConfig(briefingId).then(config => {
        setState({ elements: config.elements, order: config.order })
      })
    } else {
      const { elements, order } = configCache[briefingId]
      setState({ elements, order })
    }
  }, [])

  const persistElements = elements => {
    if (!configCache[briefingId]) configCache[briefingId] = {}
    configCache[briefingId].elements = elements

    // Update client side cache for computed briefing fields
    const hasElements = Object.keys(elements).length > 0
    patchBriefingLocally(briefingId, { hasElements })

    return triggerUpdateElements({ briefingId, elements })
  }

  const persistOrder = order => {
    if (!configCache[briefingId]) configCache[briefingId] = {}
    configCache[briefingId].order = order
    return triggerUpdateOrder({ briefingId, order })
  }

  const setOrder = order => {
    persistOrder(order)
    setState({ elements: state.elements, order })
  }

  const setElementConfig = config => {
    const newElements = { ...state.elements }
    Object.keys(newElements).forEach(elementId => {
      if (!config[elementId]) return
      newElements[elementId].config = config[elementId]
    })
    setState({ elements: newElements, order: state.order })
    return persistElements(newElements)
  }

  const addElement = (elementId, element, order) => {
    const newElements = { ...state.elements, [elementId]: element }
    persistElements(newElements)
    persistOrder(order)
    setState({ elements: newElements, order })
  }

  const removeElement = elementId => {
    const newElements = { ...state.elements }
    delete newElements[elementId]

    const newOrder = [...state.order]
    newOrder.splice(newOrder.indexOf(elementId), 1)
    setState({ order: newOrder, elements: newElements })
    persistElements(newElements)
    persistOrder(newOrder)
  }

  const elements = []

  state.order.forEach(o => {
    if (!state.elements[o]) return
    elements.push({
      id: o,
      ...state.elements[o],
    })
  })

  return { elements, order: state.order, addElement, removeElement, setOrder, setElementConfig, loading }
}
