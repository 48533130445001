import React, { useContext } from 'react'
import styled from 'styled-components'
import { Tab } from 'semantic-ui-react'
import Layout from '../Layout'
import Users from './Users'
import Statistics from './Statistics'
import Undelete from './Undelete'

import { UserContext } from '../../contexts/User'

const panes = [
  {
    menuItem: { key: 'usermanagement', content: 'User Management', icon: 'users' },
    render: () => (
      <Tab.Pane>
        <Users title="User Management" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: { key: 'modulestatistics', content: 'Module Statistics', icon: 'puzzle' },
    render: () => (
      <Tab.Pane>
        <Statistics title="Module Statistics" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: { key: 'undelete', content: 'Undelete Briefing', icon: 'recycle' },
    render: () => (
      <Tab.Pane>
        <Undelete title="Undelete Briefing" />
      </Tab.Pane>
    ),
  },
]

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
  }

  td.locked {
    opacity: 0.4;
  }

  tr.locked td.edit {
    opacity: 1;
  }

  .hidden {
    visibility: hidden;
  }
`

export default function Admin() {
  const { isAdmin } = useContext(UserContext)
  if (isAdmin) {
    return (
      <Layout showHr={true} style={{ paddingBottom: '1rem' }}>
        <Container>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Container>
      </Layout>
    )
  } else {
    return <Layout showHr={true} style={{ paddingBottom: '1rem' }} />
  }
}
