import React, { memo, useState } from 'react'
import { Button, Icon, Container, Segment, Header } from 'semantic-ui-react'
import Actionbar from '../ActionbarView'
import { useBriefing } from '../../../hooks/useBriefing'

import Layout from '../../Layout'
import Code from './Code'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import { patchBriefing } from '../../../core/briefingCache'

import PublishToHybris from '../../../components/PublishToHybris'

const { isNewShop } = require('@douglas/baas-shared')

function index(props) {
  const { match } = props
  const briefingId = props.match.params.id
  const [briefing, loading] = useBriefing(briefingId)
  const [actionLoading, setActionLoading] = useState(false)

  if (loading || !briefing) {
    return <DelayedSpinner />
  }

  const onCloseBriefingClicked = () => {
    patchBriefing(briefingId, { status: 'closed' })
    props.history.push('/briefings/ready')
  }

  const onNeedsReworkClicked = async () => {
    setActionLoading(true)
    await patchBriefing(briefingId, { status: 'rework' })
    props.history.push('/briefings/rework')
    setActionLoading(false)
  }

  const Buttons = () => (
    <div style={{ marginTop: '2rem' }}>
      <Button onClick={onNeedsReworkClicked} disabled={actionLoading} icon labelPosition="left">
        <Icon name="close" />
        Needs rework
      </Button>
      <Button primary onClick={onCloseBriefingClicked} disabled={actionLoading} icon labelPosition="left">
        <Icon name="check" />
        Page is now live / close briefing
      </Button>
    </div>
  )

  return (
    <div style={{ position: 'relative' }}>
      <Actionbar active="code" match={match} briefing={briefing} />
      <Layout>
        <Container>
          <Segment raised style={{ padding: '2rem' }}>
            <Code briefingId={briefingId} briefing={briefing} />
            <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
              {briefing.status !== 'closed' && <Buttons />}
              {isNewShop(briefing.country) && briefing.country !== "EU" && <PublishToHybris briefing={briefing} />}
            </div>
            {briefing.status === 'closed' && (
              <Header as="h3">
                <Icon name="check" />
                <Header.Content>
                  This page is online. See {briefing.type} {briefing.identifier} on douglas {briefing.country}
                </Header.Content>
              </Header>
            )}
          </Segment>
        </Container>
      </Layout>
    </div>
  )
}

export default memo(index)
