import React from 'react'
import { Input } from '../../../../components/semantic/Form'

export default function Text(props) {
  if (props.validation && props.validation.pattern) {
    // Instantiating RegExp object, because react-hook-form relies on that for pattern validation.
    props.validation.pattern = new RegExp(props.validation.pattern)
  }
  return <Input {...props} />
}
