import React, { useRef } from 'react'
import { Menu, Sticky, Label, Popup, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import LargeContainer from '../components/LargeContainer'
import { BlackHr } from '../components/BlackHr'

export default function Layout({
  pathname,
  menuItems = false,
  showHr = false,
  additionalMenus = null,
  smallOffset = false,
  ...props
}) {
  const contextRef = useRef()

  if (menuItems) {
    return (
      <>
        {showHr && <BlackHr size="small" className="BlackHR" />}
        <LargeContainer>
          <div style={{ display: 'flex', alignItems: 'flex-start' }} ref={contextRef}>
            <div style={{ minWidth: '211px' }}>
              <Sticky offset={smallOffset ? 20 : 108} context={contextRef}>
                <Menu pointing secondary vertical>
                  {menuItems.map(e => (
                    <Menu.Item
                      key={e.path}
                      style={{ paddingLeft: 0 }}
                      active={pathname.includes(e.path)}
                      as={Link}
                      to={e.path}
                      id={
                        'sidemenu-' +
                        e.name
                          .replace(/ /gi, '-')
                          .replace(/\./gi, '')
                          .toLowerCase()
                      }
                    >
                      {e.info && (
                        <Popup
                          inverted
                          trigger={
                            <Label>
                              <Icon name="info" style={{ margin: 0 }} />
                            </Label>
                          }
                          content={e.info}
                        />
                      )}
                      {e.label && !e.new && <Label>{e.label}</Label>}
                      {e.label && e.new && (
                        <Label color={'teal'} style={{ color: 'rgba(0, 0, 0, .6)' }}>
                          {e.label}
                        </Label>
                      )}
                      {e.name}
                    </Menu.Item>
                  ))}
                </Menu>
                {additionalMenus && (
                  <Menu borderless vertical>
                    {additionalMenus}
                  </Menu>
                )}
              </Sticky>
            </div>
            <div style={{ paddingLeft: '3rem', flexGrow: '2' }}>{props.children}</div>
          </div>
        </LargeContainer>
      </>
    )
  } else {
    return (
      <>
        {showHr && <BlackHr size="small" />}
        <LargeContainer>{props.children}</LargeContainer>
      </>
    )
  }
}
