import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { ROLE_PROPS, ACCESS_LEVEL } from '@douglas/baas-shared'
import { useUserContext } from '../contexts/User'

export const Access = ({ role, feature, minLevel = ACCESS_LEVEL.own, redirectGuestToPreview = false, children }) => {
  const { role: userRole } = useUserContext()
  if (role && feature) {
    console.error("<Access> component warning: shouldn't be used with both 'feature' and 'role' props.")
  }

  // <Access> can be used with either restricting to a minimum role or to check access to features.
  const isAllowed = (role && userRole >= role) || (feature && ROLE_PROPS[userRole].features[feature] >= minLevel)

  if (!isAllowed && ROLE_PROPS[userRole].label === 'Guest' && redirectGuestToPreview) {
    //return <Redirect to="/login" />
    console.log(useLocation())
    const pathName = useLocation()
      .pathname.replace('/share', '')
      .replace('/briefings', '/incompletePreview')
    return <Redirect to={pathName} />
  }
  //baas.dglecom.net/briefings/63eb3d4a289f86002d792494/share
  return (
    <React.Fragment>
      {!isAllowed && (
        <React.Fragment>
          <h1>Access denied!</h1>
          {role && (
            <b>
              You are {ROLE_PROPS[userRole].label} but minimum {ROLE_PROPS[role].label} is required!
            </b>
          )}
          {feature && (
            <b>
              Your user is {ROLE_PROPS[userRole].label} which is not allowed to access the feature: {feature}
            </b>
          )}
        </React.Fragment>
      )}
      {isAllowed && children}
    </React.Fragment>
  )
}
